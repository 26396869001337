
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class VouchersDialog extends Serenity.EntityDialog<VouchersRow, any> {
        protected getFormKey() { return VouchersForm.formKey; }
        protected getIdProperty() { return VouchersRow.idProperty; }
        protected getLocalTextPrefix() { return VouchersRow.localTextPrefix; }
        protected getNameProperty() { return VouchersRow.nameProperty; }
        protected getService() { return VouchersService.baseUrl; }
        protected getDeletePermission() { return VouchersRow.deletePermission; }
        protected getInsertPermission() { return VouchersRow.insertPermission; }
        protected getUpdatePermission() { return VouchersRow.updatePermission; }

        protected form = new VouchersForm(this.idPrefix);

    }
}