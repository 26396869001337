namespace Point.Default {
    export interface PendingOrderListSettingForm {
        Title: Serenity.StringEditor;
        RefreshIntervalMiliseconds: Serenity.EnumEditor;
    }

    export class PendingOrderListSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.PendingOrderListSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!PendingOrderListSettingForm.init)  {
                PendingOrderListSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;

                Q.initFormType(PendingOrderListSettingForm, [
                    'Title', w0,
                    'RefreshIntervalMiliseconds', w1
                ]);
            }
        }
    }
}
