namespace Point.HRM {
    export interface EmployeeTrainingsForm {
        TrainingId: Serenity.LookupEditor;
        TrainingTitle: Serenity.StringEditor;
        InstitutionName: Serenity.StringEditor;
        CompletedOn: Serenity.DateEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeTrainingsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeTrainings';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeTrainingsForm.init)  {
                EmployeeTrainingsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;

                Q.initFormType(EmployeeTrainingsForm, [
                    'TrainingId', w0,
                    'TrainingTitle', w1,
                    'InstitutionName', w1,
                    'CompletedOn', w2,
                    'Notes', w1
                ]);
            }
        }
    }
}
