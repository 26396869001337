namespace Point.Accounts {
    export interface TaxGroupsForm {
        TaxGroupCode: Serenity.StringEditor;
        TaxGroupuName: Serenity.StringEditor;
        DetailList: TaxDetailsEditor;
    }

    export class TaxGroupsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.TaxGroups';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TaxGroupsForm.init)  {
                TaxGroupsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = TaxDetailsEditor;

                Q.initFormType(TaxGroupsForm, [
                    'TaxGroupCode', w0,
                    'TaxGroupuName', w0,
                    'DetailList', w1
                ]);
            }
        }
    }
}
