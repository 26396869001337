
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class TrainingsGrid extends Serenity.EntityGrid<TrainingsRow, any> {
        protected getColumnsKey() { return 'HRM.Trainings'; }
        protected getDialogType() { return TrainingsDialog; }
        protected getIdProperty() { return TrainingsRow.idProperty; }
        protected getInsertPermission() { return TrainingsRow.insertPermission; }
        protected getLocalTextPrefix() { return TrainingsRow.localTextPrefix; }
        protected getService() { return TrainingsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}