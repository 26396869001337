namespace Point.Default {
    import Jdw =  J.DashboardWidget;

    export class DashboardGrid_First extends Jdw.DashboardWidgetGrid {
        protected getCanViewPermissionKey() { return "DashboardWidgetSystem:UserDashboardView"; }
        protected getCanModifyPermissionKey() { return "DashboardWidgetSystem:UserDashboardModify"; }

        constructor(container: JQuery) {
            super(container, {
                // each dashboard grid must has a dashboard key, the key should be unique
                dashboardKey: "Default", // required
                // number widgets it will load on dashboard in the first loading
                // because in the first loading, there is no saved record in database
                // set value -1 if you want to load all widget
                // default it won't load any one
                numberOfWidgetsAtFirstLoad: -1, // optional
                groupNames: [undefined, "test"] // undefined is for no-group widget, optional too
            });
        }

        //public clear() {
        //    Q.confirm("Are you sure you want to clear widgets", () => {
        //        this.grid && this.grid.removeAll();
        //    });            
        //}

        public clear() {
            Q.confirm("Are you sure you want to clear widgets", () => {
                super.clear();
            });            
        }
    }
}