namespace Point.Web.Modules.RealEstate {
    export enum PaymentPlan {
        Monthly = 0,
        Quarterly = 1,
        HalfYearly = 2,
        Yearly = 3,
        LumSum = 4
    }
    Serenity.Decorators.registerEnumType(PaymentPlan, 'Point.Web.Modules.RealEstate.PaymentPlan', 'Point.RealEstae.PaymentPlan');
}
