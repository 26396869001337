namespace Point.BusinessPartners {
    export interface CustomersRow {
        CustomerId?: number;
        CustomerCode?: string;
        CustomerGroupId?: number;
        CustomerName?: string;
        TitleId?: number;
        ContactName?: string;
        MobileNo?: string;
        BranchId?: number;
        Address?: string;
        CountryId?: number;
        StateId?: number;
        CityId?: number;
        CurrencyId?: number;
        RegionId?: number;
        TerritoryId?: number;
        CreditLimit?: number;
        PostalCode?: string;
        Country?: string;
        PhoneNo?: string;
        Email?: string;
        Logo?: string;
        AllowLogin?: boolean;
        ShowInReport?: boolean;
        AllowTransactions?: boolean;
        Active?: boolean;
        Account?: string;
        CustomerGroupCustomerGroupName?: string;
        BranchBranchNo?: string;
        BranchBranchCode?: string;
        BranchBranchName?: string;
        BranchHeadOffice?: boolean;
        CountryName?: string;
        CountryActive?: boolean;
        StateName?: string;
        StateCountryId?: number;
        StateActive?: boolean;
        CityName?: string;
        CityCountryId?: number;
        CityStateId?: number;
        CityActive?: number;
        CurrencyCurrencyName?: string;
        CurrencyCurrencySymbol?: string;
        CurrencyActive?: boolean;
        RegionRegionName?: string;
        TerritoryTerritoryName?: string;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace CustomersRow {
        export const idProperty = 'CustomerId';
        export const nameProperty = 'CustomerName';
        export const localTextPrefix = 'BusinessPartners.Customers';
        export const lookupKey = 'BusinessPartners.Customers';

        export function getLookup(): Q.Lookup<CustomersRow> {
            return Q.getLookup<CustomersRow>('BusinessPartners.Customers');
        }
        export const deletePermission = 'BusinessPartners:Customers';
        export const insertPermission = 'BusinessPartners:Customers';
        export const readPermission = 'BusinessPartners:Customers';
        export const updatePermission = 'BusinessPartners:Customers';

        export declare const enum Fields {
            CustomerId = "CustomerId",
            CustomerCode = "CustomerCode",
            CustomerGroupId = "CustomerGroupId",
            CustomerName = "CustomerName",
            TitleId = "TitleId",
            ContactName = "ContactName",
            MobileNo = "MobileNo",
            BranchId = "BranchId",
            Address = "Address",
            CountryId = "CountryId",
            StateId = "StateId",
            CityId = "CityId",
            CurrencyId = "CurrencyId",
            RegionId = "RegionId",
            TerritoryId = "TerritoryId",
            CreditLimit = "CreditLimit",
            PostalCode = "PostalCode",
            Country = "Country",
            PhoneNo = "PhoneNo",
            Email = "Email",
            Logo = "Logo",
            AllowLogin = "AllowLogin",
            ShowInReport = "ShowInReport",
            AllowTransactions = "AllowTransactions",
            Active = "Active",
            Account = "Account",
            CustomerGroupCustomerGroupName = "CustomerGroupCustomerGroupName",
            BranchBranchNo = "BranchBranchNo",
            BranchBranchCode = "BranchBranchCode",
            BranchBranchName = "BranchBranchName",
            BranchHeadOffice = "BranchHeadOffice",
            CountryName = "CountryName",
            CountryActive = "CountryActive",
            StateName = "StateName",
            StateCountryId = "StateCountryId",
            StateActive = "StateActive",
            CityName = "CityName",
            CityCountryId = "CityCountryId",
            CityStateId = "CityStateId",
            CityActive = "CityActive",
            CurrencyCurrencyName = "CurrencyCurrencyName",
            CurrencyCurrencySymbol = "CurrencyCurrencySymbol",
            CurrencyActive = "CurrencyActive",
            RegionRegionName = "RegionRegionName",
            TerritoryTerritoryName = "TerritoryTerritoryName",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
