namespace Point.Accounts {
    export interface TaxDetailsRow {
        TaxDetailId?: number;
        TaxGroupId?: number;
        TaxDetailCode?: string;
        TaxDetailName?: string;
        TaxRate?: number;
        Customer?: boolean;
        Supplier?: boolean;
        Employee?: boolean;
    }

    export namespace TaxDetailsRow {
        export const idProperty = 'TaxDetailId';
        export const nameProperty = 'TaxDetailName';
        export const localTextPrefix = 'Accounts.TaxDetails';
        export const lookupKey = 'Accounts.TaxDetails';

        export function getLookup(): Q.Lookup<TaxDetailsRow> {
            return Q.getLookup<TaxDetailsRow>('Accounts.TaxDetails');
        }
        export const deletePermission = 'Accounts:TaxDetails';
        export const insertPermission = 'Accounts:TaxDetails';
        export const readPermission = 'Accounts:TaxDetails';
        export const updatePermission = 'Accounts:TaxDetails';

        export declare const enum Fields {
            TaxDetailId = "TaxDetailId",
            TaxGroupId = "TaxGroupId",
            TaxDetailCode = "TaxDetailCode",
            TaxDetailName = "TaxDetailName",
            TaxRate = "TaxRate",
            Customer = "Customer",
            Supplier = "Supplier",
            Employee = "Employee"
        }
    }
}
