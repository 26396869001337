namespace Point.BusinessPartners {
    export interface CustomerGroupsRow {
        CustomerGroupId?: number;
        CustomerGroupName?: string;
    }

    export namespace CustomerGroupsRow {
        export const idProperty = 'CustomerGroupId';
        export const nameProperty = 'CustomerGroupName';
        export const localTextPrefix = 'BusinessPartners.CustomerGroups';
        export const lookupKey = 'BusinessPartners.CustomerGroups';

        export function getLookup(): Q.Lookup<CustomerGroupsRow> {
            return Q.getLookup<CustomerGroupsRow>('BusinessPartners.CustomerGroups');
        }
        export const deletePermission = 'Business Partners:Customer Groups';
        export const insertPermission = 'Business Partners:Customer Groups';
        export const readPermission = 'Business Partners:Customer Groups';
        export const updatePermission = 'Business Partners:Customer Groups';

        export declare const enum Fields {
            CustomerGroupId = "CustomerGroupId",
            CustomerGroupName = "CustomerGroupName"
        }
    }
}
