namespace Point.RealEstate {
    export interface ProjectsRow {
        ProjectId?: number;
        ProjectName?: string;
        CountryId?: number;
        StateId?: number;
        CityId?: number;
        Active?: boolean;
        PlanList?: ProjectPlansRow[];
        FeatureList?: FeatureChargesRow[];
    }

    export namespace ProjectsRow {
        export const idProperty = 'ProjectId';
        export const nameProperty = 'ProjectName';
        export const localTextPrefix = 'RealEstate.Projects';
        export const lookupKey = 'RealEstate.Projects';

        export function getLookup(): Q.Lookup<ProjectsRow> {
            return Q.getLookup<ProjectsRow>('RealEstate.Projects');
        }
        export const deletePermission = 'RealEstate:Projects';
        export const insertPermission = 'RealEstate:Projects';
        export const readPermission = 'RealEstate:Projects';
        export const updatePermission = 'RealEstate:Projects';

        export declare const enum Fields {
            ProjectId = "ProjectId",
            ProjectName = "ProjectName",
            CountryId = "CountryId",
            StateId = "StateId",
            CityId = "CityId",
            Active = "Active",
            PlanList = "PlanList",
            FeatureList = "FeatureList"
        }
    }
}
