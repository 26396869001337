namespace Point.BusinessPartners {
    export interface TitlesForm {
        TitleName: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class TitlesForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.Titles';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TitlesForm.init)  {
                TitlesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(TitlesForm, [
                    'TitleName', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
