namespace Point.Meeting {
    export interface MeetingAgendaRelevantRow {
        AgendaRelevantId?: number;
        AgendaId?: number;
        ContactId?: number;
        AgendaMeetingId?: number;
        AgendaAgendaNumber?: number;
        AgendaTitle?: string;
        AgendaDescription?: string;
        AgendaAgendaTypeId?: number;
        AgendaRequestedByContactId?: number;
        AgendaImages?: string;
        AgendaAttachments?: string;
        ContactFirstName?: string;
        ContactLastName?: string;
        ContactEmail?: string;
    }

    export namespace MeetingAgendaRelevantRow {
        export const idProperty = 'AgendaRelevantId';
        export const localTextPrefix = 'Meeting.MeetingAgendaRelevant';
        export const deletePermission = 'Meeting:General';
        export const insertPermission = 'Meeting:General';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:General';

        export declare const enum Fields {
            AgendaRelevantId = "AgendaRelevantId",
            AgendaId = "AgendaId",
            ContactId = "ContactId",
            AgendaMeetingId = "AgendaMeetingId",
            AgendaAgendaNumber = "AgendaAgendaNumber",
            AgendaTitle = "AgendaTitle",
            AgendaDescription = "AgendaDescription",
            AgendaAgendaTypeId = "AgendaAgendaTypeId",
            AgendaRequestedByContactId = "AgendaRequestedByContactId",
            AgendaImages = "AgendaImages",
            AgendaAttachments = "AgendaAttachments",
            ContactFirstName = "ContactFirstName",
            ContactLastName = "ContactLastName",
            ContactEmail = "ContactEmail"
        }
    }
}
