namespace Point.Sales {
    export interface MasterDetailsRow {
        DetailID?: number;
        MasterID?: number;
        UnitPrice?: number;
    }

    export namespace MasterDetailsRow {
        export const idProperty = 'DetailID';
        export const localTextPrefix = 'Sales.MasterDetails';
        export const deletePermission = 'Sales:MasterDetails';
        export const insertPermission = 'Sales:MasterDetails';
        export const readPermission = 'Sales:MasterDetails';
        export const updatePermission = 'Sales:MasterDetails';

        export declare const enum Fields {
            DetailID = "DetailID",
            MasterID = "MasterID",
            UnitPrice = "UnitPrice"
        }
    }
}
