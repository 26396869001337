
namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class SaleInvoicesDialog extends Serenity.EntityDialog<SaleInvoicesRow, any> {
        protected getFormKey() { return SaleInvoicesForm.formKey; }
        protected getIdProperty() { return SaleInvoicesRow.idProperty; }
        protected getLocalTextPrefix() { return SaleInvoicesRow.localTextPrefix; }
        protected getNameProperty() { return SaleInvoicesRow.nameProperty; }
        protected getService() { return SaleInvoicesService.baseUrl; }
        protected getDeletePermission() { return SaleInvoicesRow.deletePermission; }
        protected getInsertPermission() { return SaleInvoicesRow.insertPermission; }
        protected getUpdatePermission() { return SaleInvoicesRow.updatePermission; }

        protected form = new SaleInvoicesForm(this.idPrefix);

        protected updateInterface() {
            super.updateInterface();

        }

        

        constructor() {
            super();

            this.form = new SaleInvoicesForm(this.idPrefix);

            this.form.CustomerId.change(e => {
                this.form.DetailList.cusutomerId = Q.toId(this.form.CustomerId.value);
                

            });

            var saleInvoiceDetailDialog = new SaleInvoiceDetailDialog({
                cusutomerId: Q.toId(this.form.CustomerId.value)
            });

            //   saleInvoiceDetailDialog.dialogOpen();
            
   
            (this.form.DetailList.view as any).onRowCountChanged.subscribe(() => {
                this.doCalculation();
                // Q.notifySuccess(total.toString());
            });


            (this.form.DetailList.view as any).onDataChanged.subscribe(() => {
                this.doCalculation();
                // Q.notifySuccess(total.toString());
            });

            this.form.Discount.change(e => {
                this.doCalculation();

            });


            this.form.Tax.change(e => {
                this.doCalculation();

            });
            //this.form.Debit.addValidationRule(this.uniqueName, e=> {

            //    if (this.form.Debit.value > 100) {

            //        return "Unit price must be less than 100";
            //    }

            //});

            //this.form.ExchangeRate.addValidationRule(this.uniqueName, e => {

            //    if (this.form.ExchangeRate.value == null ||
            //        this.form.ExchangeRate.value == 0
            //    ) {

            //        return "Exchange Rate must not be blank/zero!";
            //    }

            //});




            this.form.CustomerId.changeSelect2(e => {

               

                // this.form.CurrencyID.clearItems();
         

                var CustomerId = Q.toId(this.form.CustomerId.value);
                this.form.DetailList.cusutomerId= CustomerId;

                var Customer = Point.BusinessPartners.CustomersRow.getLookup().itemById[CustomerId];


                var curid = Q.toId(Customer.CurrencyId);
                this.form.CurrencyID.value = curid;

                var currency = Point.Accounts.CurrenciesRow.getLookup().itemById[curid];


                //this.form.ExchangeRate.element.hide();

                //this.form.ExchangeRate.element.css("font-weight", "bold");
                //this.form.ExchangeRate.element.css("color", "#d1d1d1");

                var Organizationn = Point.Administration.OrganizationsRow.getLookup().itemById[1];
                if (currency.CurrencyId == Organizationn.CurrencyId) {
                    this.form.ExchangeRate.value = 1;
                    this.form.ExchangeRate.element.attr("ReadOnly", "true");
                    this.form.ExchangeRate.element.css("background-color", "#F4F4F4");
                }
                else {
                    this.form.ExchangeRate.value = 1;

                    this.form.ExchangeRate.element.removeAttr("ReadOnly");
                    this.form.ExchangeRate.element.css("background-color", "#FFFFFF");
                }
                //   this.form.cu.value = curid;

             //   this.form.DetailList.cusutomerId = 2;
                //cusutomerId: Q.toId(this.form.CustomerId.value)

                //this.form.CurrencyName.value = account.CurrencyName;
                //this.form.AccountDisplay.value = account.AccountDisplay;

                //if (account.CurrencyId == 1) this.form.ExchangeRate.value = 1;


                // this.form.CurrencyID.addOption(curid, account.AccountTitle);

                // this.form.CurrencyID.props.value = "1";


                // this.form.CurrencyID.
                // this.form.PlanId.addOption("1", "ABC");



            });




        }



        private doCalculation() {
            var total = 0;

            var discount = 0;
            var tax = 0;
            for (var k of this.form.DetailList.getItems()) {
                // total += k.Total || 0;
                total += k.Total ;
                tax += k.Tax;
                discount += k.Discount;
            }


            this.form.SubTotal.value = (total + discount) - tax;
            this.form.Tax.value = tax;
            this.form.Discount.value = discount;
            this.form.Total.value = total;

          
          


            var paid = 0;
            if (this.form.Paid.value != null)
                paid = this.form.Paid.value;

            

           

            var net = this.form.Total.value;

            var balance = net - paid;
            this.form.Balance.value = balance;



            //this.form.GrandTotal.value = total;


            //var subDiscount = 0;
            //for (var k of this.form.LineItems.getItems()) {
            //    subDiscount += (k.Discount * k.Quantity * k.UnitPrice / 100) || 0;
            //}

            //this.form.TotalDiscount.value = this.form.Discount.value + subDiscount;

            //var vat = 0;
            //if (SmartERP.Administration.CompaniesRow.getLookup().items.length > 0) {
            //    vat = SmartERP.Administration.CompaniesRow.getLookup().items[0].VatPercentage;
            //}
            //this.form.Vat.value = (this.form.GrandTotal.value - this.form.Discount.value) * vat / 100;
            //this.form.Vat.element.closest('.field').find('.caption').text('Vat (' + vat + '%)');

            //this.form.NetTotal.value = this.form.GrandTotal.value - this.form.Discount.value + this.form.Vat.value + this.form.ShippingCost.value;

            //this.form.Due.value = this.form.NetTotal.value - this.form.PaidAmount.value;
            //if ((this.form.PaidAmount.value - this.form.NetTotal.value) > 0) {
            //    this.form.Change.value = this.form.PaidAmount.value - this.form.NetTotal.value;
            //    this.form.Due.value = 0;
            //} else {
            //    this.form.Change.value = 0;
            //}
        }

    }
}