namespace Point.BusinessPartners {
    export interface SupplierGroupsForm {
        SupplierGroupName: Serenity.StringEditor;
    }

    export class SupplierGroupsForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.SupplierGroups';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SupplierGroupsForm.init)  {
                SupplierGroupsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;

                Q.initFormType(SupplierGroupsForm, [
                    'SupplierGroupName', w0
                ]);
            }
        }
    }
}
