
namespace Point.Administration {

    @Serenity.Decorators.registerClass()
    export class OrganizationsGrid extends Serenity.EntityGrid<OrganizationsRow, any> {
        protected getColumnsKey() { return 'Organization.Organizations'; }
        protected getDialogType() { return OrganizationsDialog; }
        protected getIdProperty() { return OrganizationsRow.idProperty; }
        protected getInsertPermission() { return OrganizationsRow.insertPermission; }
        protected getLocalTextPrefix() { return OrganizationsRow.localTextPrefix; }
        protected getService() { return OrganizationsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}