namespace Point.BusinessPartners {
    export interface CustomersForm {
        CustomerCode: Serenity.StringEditor;
        CustomerName: Serenity.StringEditor;
        ContactName: Serenity.StringEditor;
        MobileNo: Serenity.StringEditor;
        BranchId: Serenity.LookupEditor;
        Active: Serenity.BooleanEditor;
        Logo: Serenity.ImageUploadEditor;
        AllowLogin: Serenity.BooleanEditor;
        Address: Serenity.StringEditor;
        CountryId: Serenity.LookupEditor;
        StateId: Serenity.LookupEditor;
        CityId: Serenity.LookupEditor;
        RegionId: Serenity.LookupEditor;
        TerritoryId: Serenity.LookupEditor;
        PostalCode: Serenity.StringEditor;
        PhoneNo: Serenity.StringEditor;
        Email: Serenity.EmailEditor;
        CurrencyId: Serenity.LookupEditor;
        CreditLimit: Serenity.IntegerEditor;
        Account: Serenity.StringEditor;
        ShowInReport: Serenity.BooleanEditor;
        AllowTransactions: Serenity.BooleanEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class CustomersForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.Customers';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!CustomersForm.init)  {
                CustomersForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.BooleanEditor;
                var w3 = s.ImageUploadEditor;
                var w4 = s.EmailEditor;
                var w5 = s.IntegerEditor;
                var w6 = s.DateEditor;

                Q.initFormType(CustomersForm, [
                    'CustomerCode', w0,
                    'CustomerName', w0,
                    'ContactName', w0,
                    'MobileNo', w0,
                    'BranchId', w1,
                    'Active', w2,
                    'Logo', w3,
                    'AllowLogin', w2,
                    'Address', w0,
                    'CountryId', w1,
                    'StateId', w1,
                    'CityId', w1,
                    'RegionId', w1,
                    'TerritoryId', w1,
                    'PostalCode', w0,
                    'PhoneNo', w0,
                    'Email', w4,
                    'CurrencyId', w1,
                    'CreditLimit', w5,
                    'Account', w0,
                    'ShowInReport', w2,
                    'AllowTransactions', w2,
                    'InsertName', w0,
                    'InsertDate', w6,
                    'UpdateName', w0,
                    'UpdateDate', w6
                ]);
            }
        }
    }
}
