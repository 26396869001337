
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class TrainingsDialog extends Serenity.EntityDialog<TrainingsRow, any> {
        protected getFormKey() { return TrainingsForm.formKey; }
        protected getIdProperty() { return TrainingsRow.idProperty; }
        protected getLocalTextPrefix() { return TrainingsRow.localTextPrefix; }
        protected getNameProperty() { return TrainingsRow.nameProperty; }
        protected getService() { return TrainingsService.baseUrl; }
        protected getDeletePermission() { return TrainingsRow.deletePermission; }
        protected getInsertPermission() { return TrainingsRow.insertPermission; }
        protected getUpdatePermission() { return TrainingsRow.updatePermission; }

        protected form = new TrainingsForm(this.idPrefix);

    }
}