namespace Point.Meeting {
    export interface MeetingRow {
        MeetingId?: number;
        MeetingName?: string;
        MeetingNumber?: string;
        MeetingGuid?: string;
        MeetingTypeId?: number;
        StartDate?: string;
        EndDate?: string;
        LocationId?: number;
        UnitId?: number;
        OrganizerContactId?: number;
        ReporterContactId?: number;
        MeetingTypeName?: string;
        LocationName?: string;
        UnitName?: string;
        UnitParentUnitId?: number;
        OrganizerContactFirstName?: string;
        OrganizerContactLastName?: string;
        OrganizerContactFullName?: string;
        OrganizerContactEmail?: string;
        ReporterContactFirstName?: string;
        ReporterContactLastName?: string;
        ReporterContactFullName?: string;
        ReporterContactEmail?: string;
        AttendeeList?: MeetingAttendeeRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace MeetingRow {
        export const idProperty = 'MeetingId';
        export const nameProperty = 'MeetingName';
        export const localTextPrefix = 'Meeting.Meeting';
        export const deletePermission = 'Meeting:General';
        export const insertPermission = 'Meeting:General';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:General';

        export declare const enum Fields {
            MeetingId = "MeetingId",
            MeetingName = "MeetingName",
            MeetingNumber = "MeetingNumber",
            MeetingGuid = "MeetingGuid",
            MeetingTypeId = "MeetingTypeId",
            StartDate = "StartDate",
            EndDate = "EndDate",
            LocationId = "LocationId",
            UnitId = "UnitId",
            OrganizerContactId = "OrganizerContactId",
            ReporterContactId = "ReporterContactId",
            MeetingTypeName = "MeetingTypeName",
            LocationName = "LocationName",
            UnitName = "UnitName",
            UnitParentUnitId = "UnitParentUnitId",
            OrganizerContactFirstName = "OrganizerContactFirstName",
            OrganizerContactLastName = "OrganizerContactLastName",
            OrganizerContactFullName = "OrganizerContactFullName",
            OrganizerContactEmail = "OrganizerContactEmail",
            ReporterContactFirstName = "ReporterContactFirstName",
            ReporterContactLastName = "ReporterContactLastName",
            ReporterContactFullName = "ReporterContactFullName",
            ReporterContactEmail = "ReporterContactEmail",
            AttendeeList = "AttendeeList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
