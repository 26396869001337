namespace Point.Accounts {
    export interface AccountsRow {
        AccountId?: number;
        AccountCode?: number;
        AccountTitle?: string;
        AccountDisplay?: string;
        ParentId?: number;
        HeadLevel?: number;
        HeadType?: string;
        CurrencyName?: string;
        AllowTransactions?: boolean;
        IsGl?: boolean;
        IsBudget?: boolean;
        IsDepreciation?: boolean;
        IsSystem?: boolean;
        DepreciationRate?: number;
        IsActive?: number;
        EmployeeId?: number;
        BankId?: number;
        CustomerId?: number;
        SupplierId?: number;
        CurrencyId?: number;
        OpeningBalance?: number;
        Balance?: number;
        ParentAccountCode?: number;
        ParentAccountTitle?: string;
        ParentParentId?: number;
        ParentHeadLevel?: number;
        ParentHeadType?: string;
        ParentAllowTransactions?: boolean;
        ParentIsGl?: boolean;
        ParentIsBudget?: boolean;
        ParentIsDepreciation?: boolean;
        ParentDepreciationRate?: number;
        ParentIsActive?: number;
        ParentEmployeeId?: number;
        ParentBankId?: number;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace AccountsRow {
        export const idProperty = 'AccountId';
        export const nameProperty = 'AccountDisplay';
        export const localTextPrefix = 'Accounts.Accounts';
        export const lookupKey = 'Accounts.Accounts';

        export function getLookup(): Q.Lookup<AccountsRow> {
            return Q.getLookup<AccountsRow>('Accounts.Accounts');
        }
        export const deletePermission = 'Accounts:Chart of Accounts';
        export const insertPermission = 'Accounts:Chart of Accounts';
        export const readPermission = 'Accounts:Chart of Accounts';
        export const updatePermission = 'Accounts:Chart of Accounts';

        export declare const enum Fields {
            AccountId = "AccountId",
            AccountCode = "AccountCode",
            AccountTitle = "AccountTitle",
            AccountDisplay = "AccountDisplay",
            ParentId = "ParentId",
            HeadLevel = "HeadLevel",
            HeadType = "HeadType",
            CurrencyName = "CurrencyName",
            AllowTransactions = "AllowTransactions",
            IsGl = "IsGl",
            IsBudget = "IsBudget",
            IsDepreciation = "IsDepreciation",
            IsSystem = "IsSystem",
            DepreciationRate = "DepreciationRate",
            IsActive = "IsActive",
            EmployeeId = "EmployeeId",
            BankId = "BankId",
            CustomerId = "CustomerId",
            SupplierId = "SupplierId",
            CurrencyId = "CurrencyId",
            OpeningBalance = "OpeningBalance",
            Balance = "Balance",
            ParentAccountCode = "ParentAccountCode",
            ParentAccountTitle = "ParentAccountTitle",
            ParentParentId = "ParentParentId",
            ParentHeadLevel = "ParentHeadLevel",
            ParentHeadType = "ParentHeadType",
            ParentAllowTransactions = "ParentAllowTransactions",
            ParentIsGl = "ParentIsGl",
            ParentIsBudget = "ParentIsBudget",
            ParentIsDepreciation = "ParentIsDepreciation",
            ParentDepreciationRate = "ParentDepreciationRate",
            ParentIsActive = "ParentIsActive",
            ParentEmployeeId = "ParentEmployeeId",
            ParentBankId = "ParentBankId",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
