namespace Point.Accounts {
    export interface AccountSettingsRow {
        SettingId?: number;
        CustommerControlAccountId?: number;
        SupplierControlAccountId?: number;
        BankControlAccountId?: number;
        EmployeeSalaryPayableControlAccountId?: number;
        EmployeeAdvanceAgainstSalaryAccountId?: number;
        EmployeeAdvanceAgainstExpenseAccountId?: number;
        ServiceTypeSaleControlAccountId?: number;
        ServiceTypeCostControlAccountId?: number;
        ProductSaleControlAccountId?: number;
        ProductCostControlAccountId?: number;
        CustommerControlAccountAccountCode?: number;
        CustommerControlAccountAccountTitle?: string;
        CustommerControlAccountAccountDisplay?: string;
        CustommerControlAccountParentId?: number;
        CustommerControlAccountCurrencyId?: number;
        CustommerControlAccountHeadLevel?: number;
        CustommerControlAccountHeadType?: string;
        CustommerControlAccountAllowTransactions?: boolean;
        CustommerControlAccountIsGl?: boolean;
        CustommerControlAccountIsBudget?: boolean;
        CustommerControlAccountIsDepreciation?: boolean;
        CustommerControlAccountDepreciationRate?: number;
        CustommerControlAccountIsActive?: number;
        CustommerControlAccountIsDeleted?: boolean;
        CustommerControlAccountCustomerId?: number;
        CustommerControlAccountSupplierId?: number;
        CustommerControlAccountEmployeeId?: number;
        CustommerControlAccountBankId?: number;
        CustommerControlAccountIsBank?: boolean;
        CustommerControlAccountIsCash?: boolean;
        CustommerControlAccountInsertUserId?: number;
        CustommerControlAccountInsertDate?: string;
        CustommerControlAccountUpdateUserId?: number;
        CustommerControlAccountUpdateDate?: string;
        CustommerControlAccountIsSystem?: boolean;
        CustommerControlAccountServiceTypeId?: number;
        CustommerControlAccountProductId?: number;
        SupplierControlAccountAccountCode?: number;
        SupplierControlAccountAccountTitle?: string;
        SupplierControlAccountAccountDisplay?: string;
        SupplierControlAccountParentId?: number;
        SupplierControlAccountCurrencyId?: number;
        SupplierControlAccountHeadLevel?: number;
        SupplierControlAccountHeadType?: string;
        SupplierControlAccountAllowTransactions?: boolean;
        SupplierControlAccountIsGl?: boolean;
        SupplierControlAccountIsBudget?: boolean;
        SupplierControlAccountIsDepreciation?: boolean;
        SupplierControlAccountDepreciationRate?: number;
        SupplierControlAccountIsActive?: number;
        SupplierControlAccountIsDeleted?: boolean;
        SupplierControlAccountCustomerId?: number;
        SupplierControlAccountSupplierId?: number;
        SupplierControlAccountEmployeeId?: number;
        SupplierControlAccountBankId?: number;
        SupplierControlAccountIsBank?: boolean;
        SupplierControlAccountIsCash?: boolean;
        SupplierControlAccountInsertUserId?: number;
        SupplierControlAccountInsertDate?: string;
        SupplierControlAccountUpdateUserId?: number;
        SupplierControlAccountUpdateDate?: string;
        SupplierControlAccountIsSystem?: boolean;
        SupplierControlAccountServiceTypeId?: number;
        SupplierControlAccountProductId?: number;
        BankControlAccountAccountCode?: number;
        BankControlAccountAccountTitle?: string;
        BankControlAccountAccountDisplay?: string;
        BankControlAccountParentId?: number;
        BankControlAccountCurrencyId?: number;
        BankControlAccountHeadLevel?: number;
        BankControlAccountHeadType?: string;
        BankControlAccountAllowTransactions?: boolean;
        BankControlAccountIsGl?: boolean;
        BankControlAccountIsBudget?: boolean;
        BankControlAccountIsDepreciation?: boolean;
        BankControlAccountDepreciationRate?: number;
        BankControlAccountIsActive?: number;
        BankControlAccountIsDeleted?: boolean;
        BankControlAccountCustomerId?: number;
        BankControlAccountSupplierId?: number;
        BankControlAccountEmployeeId?: number;
        BankControlAccountBankId?: number;
        BankControlAccountIsBank?: boolean;
        BankControlAccountIsCash?: boolean;
        BankControlAccountInsertUserId?: number;
        BankControlAccountInsertDate?: string;
        BankControlAccountUpdateUserId?: number;
        BankControlAccountUpdateDate?: string;
        BankControlAccountIsSystem?: boolean;
        BankControlAccountServiceTypeId?: number;
        BankControlAccountProductId?: number;
        EmployeeSalaryPayableControlAccountAccountCode?: number;
        EmployeeSalaryPayableControlAccountAccountTitle?: string;
        EmployeeSalaryPayableControlAccountAccountDisplay?: string;
        EmployeeSalaryPayableControlAccountParentId?: number;
        EmployeeSalaryPayableControlAccountCurrencyId?: number;
        EmployeeSalaryPayableControlAccountHeadLevel?: number;
        EmployeeSalaryPayableControlAccountHeadType?: string;
        EmployeeSalaryPayableControlAccountAllowTransactions?: boolean;
        EmployeeSalaryPayableControlAccountIsGl?: boolean;
        EmployeeSalaryPayableControlAccountIsBudget?: boolean;
        EmployeeSalaryPayableControlAccountIsDepreciation?: boolean;
        EmployeeSalaryPayableControlAccountDepreciationRate?: number;
        EmployeeSalaryPayableControlAccountIsActive?: number;
        EmployeeSalaryPayableControlAccountIsDeleted?: boolean;
        EmployeeSalaryPayableControlAccountCustomerId?: number;
        EmployeeSalaryPayableControlAccountSupplierId?: number;
        EmployeeSalaryPayableControlAccountEmployeeId?: number;
        EmployeeSalaryPayableControlAccountBankId?: number;
        EmployeeSalaryPayableControlAccountIsBank?: boolean;
        EmployeeSalaryPayableControlAccountIsCash?: boolean;
        EmployeeSalaryPayableControlAccountInsertUserId?: number;
        EmployeeSalaryPayableControlAccountInsertDate?: string;
        EmployeeSalaryPayableControlAccountUpdateUserId?: number;
        EmployeeSalaryPayableControlAccountUpdateDate?: string;
        EmployeeSalaryPayableControlAccountIsSystem?: boolean;
        EmployeeSalaryPayableControlAccountServiceTypeId?: number;
        EmployeeSalaryPayableControlAccountProductId?: number;
        EmployeeAdvanceAgainstSalaryAccountAccountCode?: number;
        EmployeeAdvanceAgainstSalaryAccountAccountTitle?: string;
        EmployeeAdvanceAgainstSalaryAccountAccountDisplay?: string;
        EmployeeAdvanceAgainstSalaryAccountParentId?: number;
        EmployeeAdvanceAgainstSalaryAccountCurrencyId?: number;
        EmployeeAdvanceAgainstSalaryAccountHeadLevel?: number;
        EmployeeAdvanceAgainstSalaryAccountHeadType?: string;
        EmployeeAdvanceAgainstSalaryAccountAllowTransactions?: boolean;
        EmployeeAdvanceAgainstSalaryAccountIsGl?: boolean;
        EmployeeAdvanceAgainstSalaryAccountIsBudget?: boolean;
        EmployeeAdvanceAgainstSalaryAccountIsDepreciation?: boolean;
        EmployeeAdvanceAgainstSalaryAccountDepreciationRate?: number;
        EmployeeAdvanceAgainstSalaryAccountIsActive?: number;
        EmployeeAdvanceAgainstSalaryAccountIsDeleted?: boolean;
        EmployeeAdvanceAgainstSalaryAccountCustomerId?: number;
        EmployeeAdvanceAgainstSalaryAccountSupplierId?: number;
        EmployeeAdvanceAgainstSalaryAccountEmployeeId?: number;
        EmployeeAdvanceAgainstSalaryAccountBankId?: number;
        EmployeeAdvanceAgainstSalaryAccountIsBank?: boolean;
        EmployeeAdvanceAgainstSalaryAccountIsCash?: boolean;
        EmployeeAdvanceAgainstSalaryAccountInsertUserId?: number;
        EmployeeAdvanceAgainstSalaryAccountInsertDate?: string;
        EmployeeAdvanceAgainstSalaryAccountUpdateUserId?: number;
        EmployeeAdvanceAgainstSalaryAccountUpdateDate?: string;
        EmployeeAdvanceAgainstSalaryAccountIsSystem?: boolean;
        EmployeeAdvanceAgainstSalaryAccountServiceTypeId?: number;
        EmployeeAdvanceAgainstSalaryAccountProductId?: number;
        EmployeeAdvanceAgainstExpenseAccountAccountCode?: number;
        EmployeeAdvanceAgainstExpenseAccountAccountTitle?: string;
        EmployeeAdvanceAgainstExpenseAccountAccountDisplay?: string;
        EmployeeAdvanceAgainstExpenseAccountParentId?: number;
        EmployeeAdvanceAgainstExpenseAccountCurrencyId?: number;
        EmployeeAdvanceAgainstExpenseAccountHeadLevel?: number;
        EmployeeAdvanceAgainstExpenseAccountHeadType?: string;
        EmployeeAdvanceAgainstExpenseAccountAllowTransactions?: boolean;
        EmployeeAdvanceAgainstExpenseAccountIsGl?: boolean;
        EmployeeAdvanceAgainstExpenseAccountIsBudget?: boolean;
        EmployeeAdvanceAgainstExpenseAccountIsDepreciation?: boolean;
        EmployeeAdvanceAgainstExpenseAccountDepreciationRate?: number;
        EmployeeAdvanceAgainstExpenseAccountIsActive?: number;
        EmployeeAdvanceAgainstExpenseAccountIsDeleted?: boolean;
        EmployeeAdvanceAgainstExpenseAccountCustomerId?: number;
        EmployeeAdvanceAgainstExpenseAccountSupplierId?: number;
        EmployeeAdvanceAgainstExpenseAccountEmployeeId?: number;
        EmployeeAdvanceAgainstExpenseAccountBankId?: number;
        EmployeeAdvanceAgainstExpenseAccountIsBank?: boolean;
        EmployeeAdvanceAgainstExpenseAccountIsCash?: boolean;
        EmployeeAdvanceAgainstExpenseAccountInsertUserId?: number;
        EmployeeAdvanceAgainstExpenseAccountInsertDate?: string;
        EmployeeAdvanceAgainstExpenseAccountUpdateUserId?: number;
        EmployeeAdvanceAgainstExpenseAccountUpdateDate?: string;
        EmployeeAdvanceAgainstExpenseAccountIsSystem?: boolean;
        EmployeeAdvanceAgainstExpenseAccountServiceTypeId?: number;
        EmployeeAdvanceAgainstExpenseAccountProductId?: number;
        ServiceTypeSaleControlAccountAccountCode?: number;
        ServiceTypeSaleControlAccountAccountTitle?: string;
        ServiceTypeSaleControlAccountAccountDisplay?: string;
        ServiceTypeSaleControlAccountParentId?: number;
        ServiceTypeSaleControlAccountCurrencyId?: number;
        ServiceTypeSaleControlAccountHeadLevel?: number;
        ServiceTypeSaleControlAccountHeadType?: string;
        ServiceTypeSaleControlAccountAllowTransactions?: boolean;
        ServiceTypeSaleControlAccountIsGl?: boolean;
        ServiceTypeSaleControlAccountIsBudget?: boolean;
        ServiceTypeSaleControlAccountIsDepreciation?: boolean;
        ServiceTypeSaleControlAccountDepreciationRate?: number;
        ServiceTypeSaleControlAccountIsActive?: number;
        ServiceTypeSaleControlAccountIsDeleted?: boolean;
        ServiceTypeSaleControlAccountCustomerId?: number;
        ServiceTypeSaleControlAccountSupplierId?: number;
        ServiceTypeSaleControlAccountEmployeeId?: number;
        ServiceTypeSaleControlAccountBankId?: number;
        ServiceTypeSaleControlAccountIsBank?: boolean;
        ServiceTypeSaleControlAccountIsCash?: boolean;
        ServiceTypeSaleControlAccountInsertUserId?: number;
        ServiceTypeSaleControlAccountInsertDate?: string;
        ServiceTypeSaleControlAccountUpdateUserId?: number;
        ServiceTypeSaleControlAccountUpdateDate?: string;
        ServiceTypeSaleControlAccountIsSystem?: boolean;
        ServiceTypeSaleControlAccountServiceTypeId?: number;
        ServiceTypeSaleControlAccountProductId?: number;
        ServiceTypeCostControlAccountAccountCode?: number;
        ServiceTypeCostControlAccountAccountTitle?: string;
        ServiceTypeCostControlAccountAccountDisplay?: string;
        ServiceTypeCostControlAccountParentId?: number;
        ServiceTypeCostControlAccountCurrencyId?: number;
        ServiceTypeCostControlAccountHeadLevel?: number;
        ServiceTypeCostControlAccountHeadType?: string;
        ServiceTypeCostControlAccountAllowTransactions?: boolean;
        ServiceTypeCostControlAccountIsGl?: boolean;
        ServiceTypeCostControlAccountIsBudget?: boolean;
        ServiceTypeCostControlAccountIsDepreciation?: boolean;
        ServiceTypeCostControlAccountDepreciationRate?: number;
        ServiceTypeCostControlAccountIsActive?: number;
        ServiceTypeCostControlAccountIsDeleted?: boolean;
        ServiceTypeCostControlAccountCustomerId?: number;
        ServiceTypeCostControlAccountSupplierId?: number;
        ServiceTypeCostControlAccountEmployeeId?: number;
        ServiceTypeCostControlAccountBankId?: number;
        ServiceTypeCostControlAccountIsBank?: boolean;
        ServiceTypeCostControlAccountIsCash?: boolean;
        ServiceTypeCostControlAccountInsertUserId?: number;
        ServiceTypeCostControlAccountInsertDate?: string;
        ServiceTypeCostControlAccountUpdateUserId?: number;
        ServiceTypeCostControlAccountUpdateDate?: string;
        ServiceTypeCostControlAccountIsSystem?: boolean;
        ServiceTypeCostControlAccountServiceTypeId?: number;
        ServiceTypeCostControlAccountProductId?: number;
        ProductSaleControlAccountAccountCode?: number;
        ProductSaleControlAccountAccountTitle?: string;
        ProductSaleControlAccountAccountDisplay?: string;
        ProductSaleControlAccountParentId?: number;
        ProductSaleControlAccountCurrencyId?: number;
        ProductSaleControlAccountHeadLevel?: number;
        ProductSaleControlAccountHeadType?: string;
        ProductSaleControlAccountAllowTransactions?: boolean;
        ProductSaleControlAccountIsGl?: boolean;
        ProductSaleControlAccountIsBudget?: boolean;
        ProductSaleControlAccountIsDepreciation?: boolean;
        ProductSaleControlAccountDepreciationRate?: number;
        ProductSaleControlAccountIsActive?: number;
        ProductSaleControlAccountIsDeleted?: boolean;
        ProductSaleControlAccountCustomerId?: number;
        ProductSaleControlAccountSupplierId?: number;
        ProductSaleControlAccountEmployeeId?: number;
        ProductSaleControlAccountBankId?: number;
        ProductSaleControlAccountIsBank?: boolean;
        ProductSaleControlAccountIsCash?: boolean;
        ProductSaleControlAccountInsertUserId?: number;
        ProductSaleControlAccountInsertDate?: string;
        ProductSaleControlAccountUpdateUserId?: number;
        ProductSaleControlAccountUpdateDate?: string;
        ProductSaleControlAccountIsSystem?: boolean;
        ProductSaleControlAccountServiceTypeId?: number;
        ProductSaleControlAccountProductId?: number;
        ProductCostControlAccountAccountCode?: number;
        ProductCostControlAccountAccountTitle?: string;
        ProductCostControlAccountAccountDisplay?: string;
        ProductCostControlAccountParentId?: number;
        ProductCostControlAccountCurrencyId?: number;
        ProductCostControlAccountHeadLevel?: number;
        ProductCostControlAccountHeadType?: string;
        ProductCostControlAccountAllowTransactions?: boolean;
        ProductCostControlAccountIsGl?: boolean;
        ProductCostControlAccountIsBudget?: boolean;
        ProductCostControlAccountIsDepreciation?: boolean;
        ProductCostControlAccountDepreciationRate?: number;
        ProductCostControlAccountIsActive?: number;
        ProductCostControlAccountIsDeleted?: boolean;
        ProductCostControlAccountCustomerId?: number;
        ProductCostControlAccountSupplierId?: number;
        ProductCostControlAccountEmployeeId?: number;
        ProductCostControlAccountBankId?: number;
        ProductCostControlAccountIsBank?: boolean;
        ProductCostControlAccountIsCash?: boolean;
        ProductCostControlAccountInsertUserId?: number;
        ProductCostControlAccountInsertDate?: string;
        ProductCostControlAccountUpdateUserId?: number;
        ProductCostControlAccountUpdateDate?: string;
        ProductCostControlAccountIsSystem?: boolean;
        ProductCostControlAccountServiceTypeId?: number;
        ProductCostControlAccountProductId?: number;
    }

    export namespace AccountSettingsRow {
        export const idProperty = 'SettingId';
        export const localTextPrefix = 'Accounts.AccountSettings';
        export const deletePermission = 'Accounts:Account Settings';
        export const insertPermission = 'Accounts:Account Settings';
        export const readPermission = 'Accounts:Account Settings';
        export const updatePermission = 'Accounts:Account Settings';

        export declare const enum Fields {
            SettingId = "SettingId",
            CustommerControlAccountId = "CustommerControlAccountId",
            SupplierControlAccountId = "SupplierControlAccountId",
            BankControlAccountId = "BankControlAccountId",
            EmployeeSalaryPayableControlAccountId = "EmployeeSalaryPayableControlAccountId",
            EmployeeAdvanceAgainstSalaryAccountId = "EmployeeAdvanceAgainstSalaryAccountId",
            EmployeeAdvanceAgainstExpenseAccountId = "EmployeeAdvanceAgainstExpenseAccountId",
            ServiceTypeSaleControlAccountId = "ServiceTypeSaleControlAccountId",
            ServiceTypeCostControlAccountId = "ServiceTypeCostControlAccountId",
            ProductSaleControlAccountId = "ProductSaleControlAccountId",
            ProductCostControlAccountId = "ProductCostControlAccountId",
            CustommerControlAccountAccountCode = "CustommerControlAccountAccountCode",
            CustommerControlAccountAccountTitle = "CustommerControlAccountAccountTitle",
            CustommerControlAccountAccountDisplay = "CustommerControlAccountAccountDisplay",
            CustommerControlAccountParentId = "CustommerControlAccountParentId",
            CustommerControlAccountCurrencyId = "CustommerControlAccountCurrencyId",
            CustommerControlAccountHeadLevel = "CustommerControlAccountHeadLevel",
            CustommerControlAccountHeadType = "CustommerControlAccountHeadType",
            CustommerControlAccountAllowTransactions = "CustommerControlAccountAllowTransactions",
            CustommerControlAccountIsGl = "CustommerControlAccountIsGl",
            CustommerControlAccountIsBudget = "CustommerControlAccountIsBudget",
            CustommerControlAccountIsDepreciation = "CustommerControlAccountIsDepreciation",
            CustommerControlAccountDepreciationRate = "CustommerControlAccountDepreciationRate",
            CustommerControlAccountIsActive = "CustommerControlAccountIsActive",
            CustommerControlAccountIsDeleted = "CustommerControlAccountIsDeleted",
            CustommerControlAccountCustomerId = "CustommerControlAccountCustomerId",
            CustommerControlAccountSupplierId = "CustommerControlAccountSupplierId",
            CustommerControlAccountEmployeeId = "CustommerControlAccountEmployeeId",
            CustommerControlAccountBankId = "CustommerControlAccountBankId",
            CustommerControlAccountIsBank = "CustommerControlAccountIsBank",
            CustommerControlAccountIsCash = "CustommerControlAccountIsCash",
            CustommerControlAccountInsertUserId = "CustommerControlAccountInsertUserId",
            CustommerControlAccountInsertDate = "CustommerControlAccountInsertDate",
            CustommerControlAccountUpdateUserId = "CustommerControlAccountUpdateUserId",
            CustommerControlAccountUpdateDate = "CustommerControlAccountUpdateDate",
            CustommerControlAccountIsSystem = "CustommerControlAccountIsSystem",
            CustommerControlAccountServiceTypeId = "CustommerControlAccountServiceTypeId",
            CustommerControlAccountProductId = "CustommerControlAccountProductId",
            SupplierControlAccountAccountCode = "SupplierControlAccountAccountCode",
            SupplierControlAccountAccountTitle = "SupplierControlAccountAccountTitle",
            SupplierControlAccountAccountDisplay = "SupplierControlAccountAccountDisplay",
            SupplierControlAccountParentId = "SupplierControlAccountParentId",
            SupplierControlAccountCurrencyId = "SupplierControlAccountCurrencyId",
            SupplierControlAccountHeadLevel = "SupplierControlAccountHeadLevel",
            SupplierControlAccountHeadType = "SupplierControlAccountHeadType",
            SupplierControlAccountAllowTransactions = "SupplierControlAccountAllowTransactions",
            SupplierControlAccountIsGl = "SupplierControlAccountIsGl",
            SupplierControlAccountIsBudget = "SupplierControlAccountIsBudget",
            SupplierControlAccountIsDepreciation = "SupplierControlAccountIsDepreciation",
            SupplierControlAccountDepreciationRate = "SupplierControlAccountDepreciationRate",
            SupplierControlAccountIsActive = "SupplierControlAccountIsActive",
            SupplierControlAccountIsDeleted = "SupplierControlAccountIsDeleted",
            SupplierControlAccountCustomerId = "SupplierControlAccountCustomerId",
            SupplierControlAccountSupplierId = "SupplierControlAccountSupplierId",
            SupplierControlAccountEmployeeId = "SupplierControlAccountEmployeeId",
            SupplierControlAccountBankId = "SupplierControlAccountBankId",
            SupplierControlAccountIsBank = "SupplierControlAccountIsBank",
            SupplierControlAccountIsCash = "SupplierControlAccountIsCash",
            SupplierControlAccountInsertUserId = "SupplierControlAccountInsertUserId",
            SupplierControlAccountInsertDate = "SupplierControlAccountInsertDate",
            SupplierControlAccountUpdateUserId = "SupplierControlAccountUpdateUserId",
            SupplierControlAccountUpdateDate = "SupplierControlAccountUpdateDate",
            SupplierControlAccountIsSystem = "SupplierControlAccountIsSystem",
            SupplierControlAccountServiceTypeId = "SupplierControlAccountServiceTypeId",
            SupplierControlAccountProductId = "SupplierControlAccountProductId",
            BankControlAccountAccountCode = "BankControlAccountAccountCode",
            BankControlAccountAccountTitle = "BankControlAccountAccountTitle",
            BankControlAccountAccountDisplay = "BankControlAccountAccountDisplay",
            BankControlAccountParentId = "BankControlAccountParentId",
            BankControlAccountCurrencyId = "BankControlAccountCurrencyId",
            BankControlAccountHeadLevel = "BankControlAccountHeadLevel",
            BankControlAccountHeadType = "BankControlAccountHeadType",
            BankControlAccountAllowTransactions = "BankControlAccountAllowTransactions",
            BankControlAccountIsGl = "BankControlAccountIsGl",
            BankControlAccountIsBudget = "BankControlAccountIsBudget",
            BankControlAccountIsDepreciation = "BankControlAccountIsDepreciation",
            BankControlAccountDepreciationRate = "BankControlAccountDepreciationRate",
            BankControlAccountIsActive = "BankControlAccountIsActive",
            BankControlAccountIsDeleted = "BankControlAccountIsDeleted",
            BankControlAccountCustomerId = "BankControlAccountCustomerId",
            BankControlAccountSupplierId = "BankControlAccountSupplierId",
            BankControlAccountEmployeeId = "BankControlAccountEmployeeId",
            BankControlAccountBankId = "BankControlAccountBankId",
            BankControlAccountIsBank = "BankControlAccountIsBank",
            BankControlAccountIsCash = "BankControlAccountIsCash",
            BankControlAccountInsertUserId = "BankControlAccountInsertUserId",
            BankControlAccountInsertDate = "BankControlAccountInsertDate",
            BankControlAccountUpdateUserId = "BankControlAccountUpdateUserId",
            BankControlAccountUpdateDate = "BankControlAccountUpdateDate",
            BankControlAccountIsSystem = "BankControlAccountIsSystem",
            BankControlAccountServiceTypeId = "BankControlAccountServiceTypeId",
            BankControlAccountProductId = "BankControlAccountProductId",
            EmployeeSalaryPayableControlAccountAccountCode = "EmployeeSalaryPayableControlAccountAccountCode",
            EmployeeSalaryPayableControlAccountAccountTitle = "EmployeeSalaryPayableControlAccountAccountTitle",
            EmployeeSalaryPayableControlAccountAccountDisplay = "EmployeeSalaryPayableControlAccountAccountDisplay",
            EmployeeSalaryPayableControlAccountParentId = "EmployeeSalaryPayableControlAccountParentId",
            EmployeeSalaryPayableControlAccountCurrencyId = "EmployeeSalaryPayableControlAccountCurrencyId",
            EmployeeSalaryPayableControlAccountHeadLevel = "EmployeeSalaryPayableControlAccountHeadLevel",
            EmployeeSalaryPayableControlAccountHeadType = "EmployeeSalaryPayableControlAccountHeadType",
            EmployeeSalaryPayableControlAccountAllowTransactions = "EmployeeSalaryPayableControlAccountAllowTransactions",
            EmployeeSalaryPayableControlAccountIsGl = "EmployeeSalaryPayableControlAccountIsGl",
            EmployeeSalaryPayableControlAccountIsBudget = "EmployeeSalaryPayableControlAccountIsBudget",
            EmployeeSalaryPayableControlAccountIsDepreciation = "EmployeeSalaryPayableControlAccountIsDepreciation",
            EmployeeSalaryPayableControlAccountDepreciationRate = "EmployeeSalaryPayableControlAccountDepreciationRate",
            EmployeeSalaryPayableControlAccountIsActive = "EmployeeSalaryPayableControlAccountIsActive",
            EmployeeSalaryPayableControlAccountIsDeleted = "EmployeeSalaryPayableControlAccountIsDeleted",
            EmployeeSalaryPayableControlAccountCustomerId = "EmployeeSalaryPayableControlAccountCustomerId",
            EmployeeSalaryPayableControlAccountSupplierId = "EmployeeSalaryPayableControlAccountSupplierId",
            EmployeeSalaryPayableControlAccountEmployeeId = "EmployeeSalaryPayableControlAccountEmployeeId",
            EmployeeSalaryPayableControlAccountBankId = "EmployeeSalaryPayableControlAccountBankId",
            EmployeeSalaryPayableControlAccountIsBank = "EmployeeSalaryPayableControlAccountIsBank",
            EmployeeSalaryPayableControlAccountIsCash = "EmployeeSalaryPayableControlAccountIsCash",
            EmployeeSalaryPayableControlAccountInsertUserId = "EmployeeSalaryPayableControlAccountInsertUserId",
            EmployeeSalaryPayableControlAccountInsertDate = "EmployeeSalaryPayableControlAccountInsertDate",
            EmployeeSalaryPayableControlAccountUpdateUserId = "EmployeeSalaryPayableControlAccountUpdateUserId",
            EmployeeSalaryPayableControlAccountUpdateDate = "EmployeeSalaryPayableControlAccountUpdateDate",
            EmployeeSalaryPayableControlAccountIsSystem = "EmployeeSalaryPayableControlAccountIsSystem",
            EmployeeSalaryPayableControlAccountServiceTypeId = "EmployeeSalaryPayableControlAccountServiceTypeId",
            EmployeeSalaryPayableControlAccountProductId = "EmployeeSalaryPayableControlAccountProductId",
            EmployeeAdvanceAgainstSalaryAccountAccountCode = "EmployeeAdvanceAgainstSalaryAccountAccountCode",
            EmployeeAdvanceAgainstSalaryAccountAccountTitle = "EmployeeAdvanceAgainstSalaryAccountAccountTitle",
            EmployeeAdvanceAgainstSalaryAccountAccountDisplay = "EmployeeAdvanceAgainstSalaryAccountAccountDisplay",
            EmployeeAdvanceAgainstSalaryAccountParentId = "EmployeeAdvanceAgainstSalaryAccountParentId",
            EmployeeAdvanceAgainstSalaryAccountCurrencyId = "EmployeeAdvanceAgainstSalaryAccountCurrencyId",
            EmployeeAdvanceAgainstSalaryAccountHeadLevel = "EmployeeAdvanceAgainstSalaryAccountHeadLevel",
            EmployeeAdvanceAgainstSalaryAccountHeadType = "EmployeeAdvanceAgainstSalaryAccountHeadType",
            EmployeeAdvanceAgainstSalaryAccountAllowTransactions = "EmployeeAdvanceAgainstSalaryAccountAllowTransactions",
            EmployeeAdvanceAgainstSalaryAccountIsGl = "EmployeeAdvanceAgainstSalaryAccountIsGl",
            EmployeeAdvanceAgainstSalaryAccountIsBudget = "EmployeeAdvanceAgainstSalaryAccountIsBudget",
            EmployeeAdvanceAgainstSalaryAccountIsDepreciation = "EmployeeAdvanceAgainstSalaryAccountIsDepreciation",
            EmployeeAdvanceAgainstSalaryAccountDepreciationRate = "EmployeeAdvanceAgainstSalaryAccountDepreciationRate",
            EmployeeAdvanceAgainstSalaryAccountIsActive = "EmployeeAdvanceAgainstSalaryAccountIsActive",
            EmployeeAdvanceAgainstSalaryAccountIsDeleted = "EmployeeAdvanceAgainstSalaryAccountIsDeleted",
            EmployeeAdvanceAgainstSalaryAccountCustomerId = "EmployeeAdvanceAgainstSalaryAccountCustomerId",
            EmployeeAdvanceAgainstSalaryAccountSupplierId = "EmployeeAdvanceAgainstSalaryAccountSupplierId",
            EmployeeAdvanceAgainstSalaryAccountEmployeeId = "EmployeeAdvanceAgainstSalaryAccountEmployeeId",
            EmployeeAdvanceAgainstSalaryAccountBankId = "EmployeeAdvanceAgainstSalaryAccountBankId",
            EmployeeAdvanceAgainstSalaryAccountIsBank = "EmployeeAdvanceAgainstSalaryAccountIsBank",
            EmployeeAdvanceAgainstSalaryAccountIsCash = "EmployeeAdvanceAgainstSalaryAccountIsCash",
            EmployeeAdvanceAgainstSalaryAccountInsertUserId = "EmployeeAdvanceAgainstSalaryAccountInsertUserId",
            EmployeeAdvanceAgainstSalaryAccountInsertDate = "EmployeeAdvanceAgainstSalaryAccountInsertDate",
            EmployeeAdvanceAgainstSalaryAccountUpdateUserId = "EmployeeAdvanceAgainstSalaryAccountUpdateUserId",
            EmployeeAdvanceAgainstSalaryAccountUpdateDate = "EmployeeAdvanceAgainstSalaryAccountUpdateDate",
            EmployeeAdvanceAgainstSalaryAccountIsSystem = "EmployeeAdvanceAgainstSalaryAccountIsSystem",
            EmployeeAdvanceAgainstSalaryAccountServiceTypeId = "EmployeeAdvanceAgainstSalaryAccountServiceTypeId",
            EmployeeAdvanceAgainstSalaryAccountProductId = "EmployeeAdvanceAgainstSalaryAccountProductId",
            EmployeeAdvanceAgainstExpenseAccountAccountCode = "EmployeeAdvanceAgainstExpenseAccountAccountCode",
            EmployeeAdvanceAgainstExpenseAccountAccountTitle = "EmployeeAdvanceAgainstExpenseAccountAccountTitle",
            EmployeeAdvanceAgainstExpenseAccountAccountDisplay = "EmployeeAdvanceAgainstExpenseAccountAccountDisplay",
            EmployeeAdvanceAgainstExpenseAccountParentId = "EmployeeAdvanceAgainstExpenseAccountParentId",
            EmployeeAdvanceAgainstExpenseAccountCurrencyId = "EmployeeAdvanceAgainstExpenseAccountCurrencyId",
            EmployeeAdvanceAgainstExpenseAccountHeadLevel = "EmployeeAdvanceAgainstExpenseAccountHeadLevel",
            EmployeeAdvanceAgainstExpenseAccountHeadType = "EmployeeAdvanceAgainstExpenseAccountHeadType",
            EmployeeAdvanceAgainstExpenseAccountAllowTransactions = "EmployeeAdvanceAgainstExpenseAccountAllowTransactions",
            EmployeeAdvanceAgainstExpenseAccountIsGl = "EmployeeAdvanceAgainstExpenseAccountIsGl",
            EmployeeAdvanceAgainstExpenseAccountIsBudget = "EmployeeAdvanceAgainstExpenseAccountIsBudget",
            EmployeeAdvanceAgainstExpenseAccountIsDepreciation = "EmployeeAdvanceAgainstExpenseAccountIsDepreciation",
            EmployeeAdvanceAgainstExpenseAccountDepreciationRate = "EmployeeAdvanceAgainstExpenseAccountDepreciationRate",
            EmployeeAdvanceAgainstExpenseAccountIsActive = "EmployeeAdvanceAgainstExpenseAccountIsActive",
            EmployeeAdvanceAgainstExpenseAccountIsDeleted = "EmployeeAdvanceAgainstExpenseAccountIsDeleted",
            EmployeeAdvanceAgainstExpenseAccountCustomerId = "EmployeeAdvanceAgainstExpenseAccountCustomerId",
            EmployeeAdvanceAgainstExpenseAccountSupplierId = "EmployeeAdvanceAgainstExpenseAccountSupplierId",
            EmployeeAdvanceAgainstExpenseAccountEmployeeId = "EmployeeAdvanceAgainstExpenseAccountEmployeeId",
            EmployeeAdvanceAgainstExpenseAccountBankId = "EmployeeAdvanceAgainstExpenseAccountBankId",
            EmployeeAdvanceAgainstExpenseAccountIsBank = "EmployeeAdvanceAgainstExpenseAccountIsBank",
            EmployeeAdvanceAgainstExpenseAccountIsCash = "EmployeeAdvanceAgainstExpenseAccountIsCash",
            EmployeeAdvanceAgainstExpenseAccountInsertUserId = "EmployeeAdvanceAgainstExpenseAccountInsertUserId",
            EmployeeAdvanceAgainstExpenseAccountInsertDate = "EmployeeAdvanceAgainstExpenseAccountInsertDate",
            EmployeeAdvanceAgainstExpenseAccountUpdateUserId = "EmployeeAdvanceAgainstExpenseAccountUpdateUserId",
            EmployeeAdvanceAgainstExpenseAccountUpdateDate = "EmployeeAdvanceAgainstExpenseAccountUpdateDate",
            EmployeeAdvanceAgainstExpenseAccountIsSystem = "EmployeeAdvanceAgainstExpenseAccountIsSystem",
            EmployeeAdvanceAgainstExpenseAccountServiceTypeId = "EmployeeAdvanceAgainstExpenseAccountServiceTypeId",
            EmployeeAdvanceAgainstExpenseAccountProductId = "EmployeeAdvanceAgainstExpenseAccountProductId",
            ServiceTypeSaleControlAccountAccountCode = "ServiceTypeSaleControlAccountAccountCode",
            ServiceTypeSaleControlAccountAccountTitle = "ServiceTypeSaleControlAccountAccountTitle",
            ServiceTypeSaleControlAccountAccountDisplay = "ServiceTypeSaleControlAccountAccountDisplay",
            ServiceTypeSaleControlAccountParentId = "ServiceTypeSaleControlAccountParentId",
            ServiceTypeSaleControlAccountCurrencyId = "ServiceTypeSaleControlAccountCurrencyId",
            ServiceTypeSaleControlAccountHeadLevel = "ServiceTypeSaleControlAccountHeadLevel",
            ServiceTypeSaleControlAccountHeadType = "ServiceTypeSaleControlAccountHeadType",
            ServiceTypeSaleControlAccountAllowTransactions = "ServiceTypeSaleControlAccountAllowTransactions",
            ServiceTypeSaleControlAccountIsGl = "ServiceTypeSaleControlAccountIsGl",
            ServiceTypeSaleControlAccountIsBudget = "ServiceTypeSaleControlAccountIsBudget",
            ServiceTypeSaleControlAccountIsDepreciation = "ServiceTypeSaleControlAccountIsDepreciation",
            ServiceTypeSaleControlAccountDepreciationRate = "ServiceTypeSaleControlAccountDepreciationRate",
            ServiceTypeSaleControlAccountIsActive = "ServiceTypeSaleControlAccountIsActive",
            ServiceTypeSaleControlAccountIsDeleted = "ServiceTypeSaleControlAccountIsDeleted",
            ServiceTypeSaleControlAccountCustomerId = "ServiceTypeSaleControlAccountCustomerId",
            ServiceTypeSaleControlAccountSupplierId = "ServiceTypeSaleControlAccountSupplierId",
            ServiceTypeSaleControlAccountEmployeeId = "ServiceTypeSaleControlAccountEmployeeId",
            ServiceTypeSaleControlAccountBankId = "ServiceTypeSaleControlAccountBankId",
            ServiceTypeSaleControlAccountIsBank = "ServiceTypeSaleControlAccountIsBank",
            ServiceTypeSaleControlAccountIsCash = "ServiceTypeSaleControlAccountIsCash",
            ServiceTypeSaleControlAccountInsertUserId = "ServiceTypeSaleControlAccountInsertUserId",
            ServiceTypeSaleControlAccountInsertDate = "ServiceTypeSaleControlAccountInsertDate",
            ServiceTypeSaleControlAccountUpdateUserId = "ServiceTypeSaleControlAccountUpdateUserId",
            ServiceTypeSaleControlAccountUpdateDate = "ServiceTypeSaleControlAccountUpdateDate",
            ServiceTypeSaleControlAccountIsSystem = "ServiceTypeSaleControlAccountIsSystem",
            ServiceTypeSaleControlAccountServiceTypeId = "ServiceTypeSaleControlAccountServiceTypeId",
            ServiceTypeSaleControlAccountProductId = "ServiceTypeSaleControlAccountProductId",
            ServiceTypeCostControlAccountAccountCode = "ServiceTypeCostControlAccountAccountCode",
            ServiceTypeCostControlAccountAccountTitle = "ServiceTypeCostControlAccountAccountTitle",
            ServiceTypeCostControlAccountAccountDisplay = "ServiceTypeCostControlAccountAccountDisplay",
            ServiceTypeCostControlAccountParentId = "ServiceTypeCostControlAccountParentId",
            ServiceTypeCostControlAccountCurrencyId = "ServiceTypeCostControlAccountCurrencyId",
            ServiceTypeCostControlAccountHeadLevel = "ServiceTypeCostControlAccountHeadLevel",
            ServiceTypeCostControlAccountHeadType = "ServiceTypeCostControlAccountHeadType",
            ServiceTypeCostControlAccountAllowTransactions = "ServiceTypeCostControlAccountAllowTransactions",
            ServiceTypeCostControlAccountIsGl = "ServiceTypeCostControlAccountIsGl",
            ServiceTypeCostControlAccountIsBudget = "ServiceTypeCostControlAccountIsBudget",
            ServiceTypeCostControlAccountIsDepreciation = "ServiceTypeCostControlAccountIsDepreciation",
            ServiceTypeCostControlAccountDepreciationRate = "ServiceTypeCostControlAccountDepreciationRate",
            ServiceTypeCostControlAccountIsActive = "ServiceTypeCostControlAccountIsActive",
            ServiceTypeCostControlAccountIsDeleted = "ServiceTypeCostControlAccountIsDeleted",
            ServiceTypeCostControlAccountCustomerId = "ServiceTypeCostControlAccountCustomerId",
            ServiceTypeCostControlAccountSupplierId = "ServiceTypeCostControlAccountSupplierId",
            ServiceTypeCostControlAccountEmployeeId = "ServiceTypeCostControlAccountEmployeeId",
            ServiceTypeCostControlAccountBankId = "ServiceTypeCostControlAccountBankId",
            ServiceTypeCostControlAccountIsBank = "ServiceTypeCostControlAccountIsBank",
            ServiceTypeCostControlAccountIsCash = "ServiceTypeCostControlAccountIsCash",
            ServiceTypeCostControlAccountInsertUserId = "ServiceTypeCostControlAccountInsertUserId",
            ServiceTypeCostControlAccountInsertDate = "ServiceTypeCostControlAccountInsertDate",
            ServiceTypeCostControlAccountUpdateUserId = "ServiceTypeCostControlAccountUpdateUserId",
            ServiceTypeCostControlAccountUpdateDate = "ServiceTypeCostControlAccountUpdateDate",
            ServiceTypeCostControlAccountIsSystem = "ServiceTypeCostControlAccountIsSystem",
            ServiceTypeCostControlAccountServiceTypeId = "ServiceTypeCostControlAccountServiceTypeId",
            ServiceTypeCostControlAccountProductId = "ServiceTypeCostControlAccountProductId",
            ProductSaleControlAccountAccountCode = "ProductSaleControlAccountAccountCode",
            ProductSaleControlAccountAccountTitle = "ProductSaleControlAccountAccountTitle",
            ProductSaleControlAccountAccountDisplay = "ProductSaleControlAccountAccountDisplay",
            ProductSaleControlAccountParentId = "ProductSaleControlAccountParentId",
            ProductSaleControlAccountCurrencyId = "ProductSaleControlAccountCurrencyId",
            ProductSaleControlAccountHeadLevel = "ProductSaleControlAccountHeadLevel",
            ProductSaleControlAccountHeadType = "ProductSaleControlAccountHeadType",
            ProductSaleControlAccountAllowTransactions = "ProductSaleControlAccountAllowTransactions",
            ProductSaleControlAccountIsGl = "ProductSaleControlAccountIsGl",
            ProductSaleControlAccountIsBudget = "ProductSaleControlAccountIsBudget",
            ProductSaleControlAccountIsDepreciation = "ProductSaleControlAccountIsDepreciation",
            ProductSaleControlAccountDepreciationRate = "ProductSaleControlAccountDepreciationRate",
            ProductSaleControlAccountIsActive = "ProductSaleControlAccountIsActive",
            ProductSaleControlAccountIsDeleted = "ProductSaleControlAccountIsDeleted",
            ProductSaleControlAccountCustomerId = "ProductSaleControlAccountCustomerId",
            ProductSaleControlAccountSupplierId = "ProductSaleControlAccountSupplierId",
            ProductSaleControlAccountEmployeeId = "ProductSaleControlAccountEmployeeId",
            ProductSaleControlAccountBankId = "ProductSaleControlAccountBankId",
            ProductSaleControlAccountIsBank = "ProductSaleControlAccountIsBank",
            ProductSaleControlAccountIsCash = "ProductSaleControlAccountIsCash",
            ProductSaleControlAccountInsertUserId = "ProductSaleControlAccountInsertUserId",
            ProductSaleControlAccountInsertDate = "ProductSaleControlAccountInsertDate",
            ProductSaleControlAccountUpdateUserId = "ProductSaleControlAccountUpdateUserId",
            ProductSaleControlAccountUpdateDate = "ProductSaleControlAccountUpdateDate",
            ProductSaleControlAccountIsSystem = "ProductSaleControlAccountIsSystem",
            ProductSaleControlAccountServiceTypeId = "ProductSaleControlAccountServiceTypeId",
            ProductSaleControlAccountProductId = "ProductSaleControlAccountProductId",
            ProductCostControlAccountAccountCode = "ProductCostControlAccountAccountCode",
            ProductCostControlAccountAccountTitle = "ProductCostControlAccountAccountTitle",
            ProductCostControlAccountAccountDisplay = "ProductCostControlAccountAccountDisplay",
            ProductCostControlAccountParentId = "ProductCostControlAccountParentId",
            ProductCostControlAccountCurrencyId = "ProductCostControlAccountCurrencyId",
            ProductCostControlAccountHeadLevel = "ProductCostControlAccountHeadLevel",
            ProductCostControlAccountHeadType = "ProductCostControlAccountHeadType",
            ProductCostControlAccountAllowTransactions = "ProductCostControlAccountAllowTransactions",
            ProductCostControlAccountIsGl = "ProductCostControlAccountIsGl",
            ProductCostControlAccountIsBudget = "ProductCostControlAccountIsBudget",
            ProductCostControlAccountIsDepreciation = "ProductCostControlAccountIsDepreciation",
            ProductCostControlAccountDepreciationRate = "ProductCostControlAccountDepreciationRate",
            ProductCostControlAccountIsActive = "ProductCostControlAccountIsActive",
            ProductCostControlAccountIsDeleted = "ProductCostControlAccountIsDeleted",
            ProductCostControlAccountCustomerId = "ProductCostControlAccountCustomerId",
            ProductCostControlAccountSupplierId = "ProductCostControlAccountSupplierId",
            ProductCostControlAccountEmployeeId = "ProductCostControlAccountEmployeeId",
            ProductCostControlAccountBankId = "ProductCostControlAccountBankId",
            ProductCostControlAccountIsBank = "ProductCostControlAccountIsBank",
            ProductCostControlAccountIsCash = "ProductCostControlAccountIsCash",
            ProductCostControlAccountInsertUserId = "ProductCostControlAccountInsertUserId",
            ProductCostControlAccountInsertDate = "ProductCostControlAccountInsertDate",
            ProductCostControlAccountUpdateUserId = "ProductCostControlAccountUpdateUserId",
            ProductCostControlAccountUpdateDate = "ProductCostControlAccountUpdateDate",
            ProductCostControlAccountIsSystem = "ProductCostControlAccountIsSystem",
            ProductCostControlAccountServiceTypeId = "ProductCostControlAccountServiceTypeId",
            ProductCostControlAccountProductId = "ProductCostControlAccountProductId"
        }
    }
}
