/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Services {

    @Serenity.Decorators.registerClass()
    export class ServiceSupplierDialog extends Common.GridEditorDialog<ServiceSuppliersRow> {
        protected getFormKey() { return ServiceSuppliersForm.formKey; }
        protected getLocalTextPrefix() { return ServiceSuppliersRow.localTextPrefix; }

        protected form: ServiceSuppliersForm;

        constructor() {
            super();

            this.form = new ServiceSuppliersForm(this.idPrefix);

          
            this.form.SupplierID.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();

                var supplierID = Q.toId(this.form.SupplierID.value);

                var supplier = Point.BusinessPartners.SuppliersRow.getLookup().itemById[supplierID];

                this.form.SupplierName.value = supplier.SupplierName;

                var curid = Q.toId(supplier.CurrencyId);
                this.form.CurrencyID.value = curid;

            

                var currency = Point.Accounts.CurrenciesRow.getLookup().itemById[curid];
                this.form.CurrencyName.value = currency.CurrencyName;

                //this.form.ExchangeRate.element.hide();

                //this.form.ExchangeRate.element.css("font-weight", "bold");
                //this.form.ExchangeRate.element.css("color", "#d1d1d1");

                var Organizationn = Point.Administration.OrganizationsRow.getLookup().itemById[1];
                if (currency.CurrencyId == Organizationn.CurrencyId) {
                    this.form.ExchangeRate.value = 1;
                    this.form.ExchangeRate.element.attr("ReadOnly", "true");
                    this.form.ExchangeRate.element.css("background-color", "#F4F4F4");
                }
                else {
                    this.form.ExchangeRate.value = 1;

                    this.form.ExchangeRate.element.removeAttr("ReadOnly");
                    this.form.ExchangeRate.element.css("background-color", "#FFFFFF");
                }


                //this.form.ExchangeRate.element.hide();

                //this.form.ExchangeRate.element.css("font-weight", "bold");
                //this.form.ExchangeRate.element.css("color", "#d1d1d1");

                //if (curid == 1) {
                //    this.form.ExchangeRate.value = 1;
                //    this.form.ExchangeRate.element.attr("ReadOnly", "true");
                //    this.form.ExchangeRate.element.css("background-color", "#F4F4F4");
                //}
                //else {
                //    this.form.ExchangeRate.value = 44;
                //    this.form.ExchangeRate.element.removeAttr("ReadOnly");
                //    this.form.ExchangeRate.element.css("background-color", "#FFFFFF");
                //}
                //   this.form.cu.value = curid;


                //this.form.CurrencyName.value = account.CurrencyName;
                //this.form.AccountDisplay.value = account.AccountDisplay;

                //if (account.CurrencyId == 1) this.form.ExchangeRate.value = 1;


                // this.form.CurrencyID.addOption(curid, account.AccountTitle);

                // this.form.CurrencyID.props.value = "1";


                // this.form.CurrencyID.
                // this.form.PlanId.addOption("1", "ABC");



            });




        }
    }
}