namespace Point.Products {
    export interface UnitsRow {
        UnitId?: number;
        UnitCode?: string;
        UnitName?: string;
        Active?: boolean;
    }

    export namespace UnitsRow {
        export const idProperty = 'UnitId';
        export const nameProperty = 'UnitCode';
        export const localTextPrefix = 'Products.Units';
        export const lookupKey = 'Products.Units';

        export function getLookup(): Q.Lookup<UnitsRow> {
            return Q.getLookup<UnitsRow>('Products.Units');
        }
        export const deletePermission = 'Products:Units';
        export const insertPermission = 'Products:Units';
        export const readPermission = 'Products:Units';
        export const updatePermission = 'Products:Units';

        export declare const enum Fields {
            UnitId = "UnitId",
            UnitCode = "UnitCode",
            UnitName = "UnitName",
            Active = "Active"
        }
    }
}
