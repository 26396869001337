namespace Point.HRM {
    export interface SalarySlipsForm {
        VoucherNumber: Serenity.StringEditor;
        EmployeeId: Serenity.LookupEditor;
        SalaryMonth: Serenity.DateEditor;
        PerDaySalary: Serenity.DecimalEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        PaidDays: Serenity.DecimalEditor;
        UnPaidDays: Serenity.DecimalEditor;
        CustomEntry: Serenity.BooleanEditor;
        BasicSalary: Serenity.DecimalEditor;
        HouseRent: Serenity.DecimalEditor;
        Utilities: Serenity.DecimalEditor;
        Medical: Serenity.DecimalEditor;
        Transportation: Serenity.DecimalEditor;
        MedicalReimbursement: Serenity.DecimalEditor;
        ExpensesReimbursement: Serenity.DecimalEditor;
        OtherEarning1Title: Serenity.StringEditor;
        OtherEarning1Amount: Serenity.DecimalEditor;
        OtherEarning2Title: Serenity.StringEditor;
        OtherEarning2Amount: Serenity.DecimalEditor;
        LeaveDeduction: Serenity.DecimalEditor;
        TaxDeduction: Serenity.DecimalEditor;
        LoanDeduction: Serenity.DecimalEditor;
        Fine: Serenity.DecimalEditor;
        ProvidentFund: Serenity.DecimalEditor;
        AdvanceSalary: Serenity.DecimalEditor;
        EOBI: Serenity.DecimalEditor;
        OtherDeduction1Title: Serenity.StringEditor;
        OtherDeduction1Amount: Serenity.DecimalEditor;
        OtherDeduction2Title: Serenity.StringEditor;
        OtherDeduction2Amount: Serenity.DecimalEditor;
        NetSalary: Serenity.DecimalEditor;
        ExpenseAccount: Serenity.LookupEditor;
        PaymentAccount: Serenity.LookupEditor;
        AccruedDate: Serenity.DateEditor;
        PaymentDate: Serenity.DateEditor;
        ExchangeRate: Serenity.DecimalEditor;
        Status: Serenity.EnumEditor;
        Notes: Serenity.StringEditor;
        Verified: Serenity.BooleanEditor;
        VoucherLock: Serenity.BooleanEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class SalarySlipsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.SalarySlips';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SalarySlipsForm.init)  {
                SalarySlipsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.DateEditor;
                var w3 = s.DecimalEditor;
                var w4 = s.BooleanEditor;
                var w5 = s.EnumEditor;

                Q.initFormType(SalarySlipsForm, [
                    'VoucherNumber', w0,
                    'EmployeeId', w1,
                    'SalaryMonth', w2,
                    'PerDaySalary', w3,
                    'StartDate', w2,
                    'EndDate', w2,
                    'PaidDays', w3,
                    'UnPaidDays', w3,
                    'CustomEntry', w4,
                    'BasicSalary', w3,
                    'HouseRent', w3,
                    'Utilities', w3,
                    'Medical', w3,
                    'Transportation', w3,
                    'MedicalReimbursement', w3,
                    'ExpensesReimbursement', w3,
                    'OtherEarning1Title', w0,
                    'OtherEarning1Amount', w3,
                    'OtherEarning2Title', w0,
                    'OtherEarning2Amount', w3,
                    'LeaveDeduction', w3,
                    'TaxDeduction', w3,
                    'LoanDeduction', w3,
                    'Fine', w3,
                    'ProvidentFund', w3,
                    'AdvanceSalary', w3,
                    'EOBI', w3,
                    'OtherDeduction1Title', w0,
                    'OtherDeduction1Amount', w3,
                    'OtherDeduction2Title', w0,
                    'OtherDeduction2Amount', w3,
                    'NetSalary', w3,
                    'ExpenseAccount', w1,
                    'PaymentAccount', w1,
                    'AccruedDate', w2,
                    'PaymentDate', w2,
                    'ExchangeRate', w3,
                    'Status', w5,
                    'Notes', w0,
                    'Verified', w4,
                    'VoucherLock', w4,
                    'InsertName', w0,
                    'InsertDate', w2,
                    'UpdateName', w0,
                    'UpdateDate', w2
                ]);
            }
        }
    }
}
