namespace Point.Sales {
    export namespace SaleReturnDetailService {
        export const baseUrl = '~/Sales/SaleReturns/SaleReturnDetail';

        export declare function Retrieve(request: Serenity.RetrieveRequest, onSuccess?: (response: Serenity.RetrieveResponse<SaleReturnDetailsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function List(request: Serenity.ListRequest, onSuccess?: (response: Serenity.ListResponse<SaleReturnDetailsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;

        export declare const enum Methods {
            Retrieve = "~/Sales/SaleReturns/SaleReturnDetail/Retrieve",
            List = "~/Sales/SaleReturns/SaleReturnDetail/List"
        }

        [
            'Retrieve', 
            'List'
        ].forEach(x => {
            (<any>SaleReturnDetailService)[x] = function (r, s, o) {
                return Q.serviceRequest(baseUrl + '/' + x, r, s, o);
            };
        });
    }
}
