namespace Point.RealEstate {
    export interface SubTypesForm {
        SubTypeName: Serenity.StringEditor;
        TypeId: Serenity.LookupEditor;
        Active: Serenity.BooleanEditor;
    }

    export class SubTypesForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.SubTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SubTypesForm.init)  {
                SubTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(SubTypesForm, [
                    'SubTypeName', w0,
                    'TypeId', w1,
                    'Active', w2
                ]);
            }
        }
    }
}
