
namespace Point.Accounts {

    @Serenity.Decorators.registerEditor()
    export class AccountsEditor extends Serenity.LookupEditorBase<Serenity.LookupEditorOptions, AccountsRow> {

        constructor(hidden: JQuery) {
            super(hidden);
        }

      //  protected getLookupKey() {
          //  return AccountsRow.lookupKey;
      //  }

        protected getItemText(item: AccountsRow, lookup: Q.Lookup<AccountsRow>) {
            var visited = {};
            var text = item.AccountTitle;
            while (item.ParentId != null && !visited[item.ParentId]) {
                item = lookup.itemById[item.ParentId];
                if (!item)
                    break;
                visited[item.AccountId] = true;
                text = item.AccountTitle + " >> " + text;
            }

            return text;
        }
    }
}