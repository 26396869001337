namespace Point.HRM {
    export interface EmployeeComplaintsForm {
        ComplaintTypeId: Serenity.LookupEditor;
        ComplaintType: Serenity.StringEditor;
        ComplaintDate: Serenity.DateEditor;
        ComplaintDetail: Serenity.StringEditor;
        ActionTaken: Serenity.StringEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeComplaintsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeComplaints';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeComplaintsForm.init)  {
                EmployeeComplaintsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;

                Q.initFormType(EmployeeComplaintsForm, [
                    'ComplaintTypeId', w0,
                    'ComplaintType', w1,
                    'ComplaintDate', w2,
                    'ComplaintDetail', w1,
                    'ActionTaken', w1,
                    'Notes', w1
                ]);
            }
        }
    }
}
