namespace Point.HRM {
    export interface EmployeeQualificationsRow {
        EmployeeQualificationID?: number;
        EmployeeID?: number;
        Notes?: string;
        InstitutionName?: string;
        GradeDivision?: string;
        CompletedOn?: string;
        QualificationId?: number;
        QualificationTitle?: string;
    }

    export namespace EmployeeQualificationsRow {
        export const idProperty = 'EmployeeQualificationID';
        export const localTextPrefix = 'HRM.EmployeeQualifications';
        export const deletePermission = 'HRM:EmployeeQualifications';
        export const insertPermission = 'HRM:EmployeeQualifications';
        export const readPermission = 'HRM:EmployeeQualifications';
        export const updatePermission = 'HRM:EmployeeQualifications';

        export declare const enum Fields {
            EmployeeQualificationID = "EmployeeQualificationID",
            EmployeeID = "EmployeeID",
            Notes = "Notes",
            InstitutionName = "InstitutionName",
            GradeDivision = "GradeDivision",
            CompletedOn = "CompletedOn",
            QualificationId = "QualificationId",
            QualificationTitle = "QualificationTitle"
        }
    }
}
