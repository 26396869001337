namespace Point.Products {
    export interface BrandsForm {
        BrandCode: Serenity.StringEditor;
        BrandName: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class BrandsForm extends Serenity.PrefixedContext {
        static formKey = 'Products.Brands';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!BrandsForm.init)  {
                BrandsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(BrandsForm, [
                    'BrandCode', w0,
                    'BrandName', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
