namespace Point.Accounts {
    export interface VoucherDetailsForm {
        AccountID: Serenity.LookupEditor;
        AccountDisplay: Serenity.StringEditor;
        CurrencyID: Serenity.IntegerEditor;
        CurrencyName: Serenity.StringEditor;
        ExchangeRate: Serenity.DecimalEditor;
        Particulars: Serenity.StringEditor;
        ChequeNo: Serenity.StringEditor;
        RefNo: Serenity.StringEditor;
        Debit: Serenity.DecimalEditor;
        Credit: Serenity.DecimalEditor;
        CategoryID: Serenity.LookupEditor;
    }

    export class VoucherDetailsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.VoucherDetail';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!VoucherDetailsForm.init)  {
                VoucherDetailsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.IntegerEditor;
                var w3 = s.DecimalEditor;

                Q.initFormType(VoucherDetailsForm, [
                    'AccountID', w0,
                    'AccountDisplay', w1,
                    'CurrencyID', w2,
                    'CurrencyName', w1,
                    'ExchangeRate', w3,
                    'Particulars', w1,
                    'ChequeNo', w1,
                    'RefNo', w1,
                    'Debit', w3,
                    'Credit', w3,
                    'CategoryID', w0
                ]);
            }
        }
    }
}
