
namespace Point.Organization {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class ContactDialog extends Serenity.EntityDialog<ContactRow, any> {
        protected getFormKey() { return ContactForm.formKey; }
        protected getIdProperty() { return ContactRow.idProperty; }
        protected getLocalTextPrefix() { return ContactRow.localTextPrefix; }
        protected getNameProperty() { return ContactRow.nameProperty; }
        protected getService() { return ContactService.baseUrl; }
        protected getDeletePermission() { return ContactRow.deletePermission; }
        protected getInsertPermission() { return ContactRow.insertPermission; }
        protected getUpdatePermission() { return ContactRow.updatePermission; }

        protected form = new ContactForm(this.idPrefix);

        getToolbarButtons() {
            var buttons = super.getToolbarButtons();

           
            buttons.push(
                <Serenity.ToolButton>{
                    title: "Convert to Customer",
                    hint: "Convert this Contact to  Customer",
                    cssClass: 'approve-button',
                    onClick: () => this.openMyApprovalForm()
                }

            );
            return buttons;
        }


        private openMyApprovalForm() {
            if (this.isNew()) {
                Q.notifyInfo("You need to save the form first.")
            }
            else if (this.isEditMode()) {

                var dlg = new Point.BusinessPartners.CustomersDialog();


                //this.form.Address.value = "abc";
                // this.save();


                // var customer = this.getSaveEntity([OrderWizardSteps.CustomerDetails]) as Point.BusinessPartners.CustomersRow;

                var customer: Point.BusinessPartners.CustomersRow = {
                  
                    CustomerName: this.form.CompanyName.value,
                    ContactName: this.form.FirstName.value + ' ' + this.form.LastName.value,
                    TitleId: Number(this.form.TitleId.value),
                    Email: this.form.Email.value,
                    Address: this.form.Address.value,
                    CountryId: Number(this.form.CountryId.value),
                    StateId: Number(this.form.StateId.value),
                    CityId: Number(this.form.CityId.value),
                    PostalCode: this.form.PostalCode.value,
                    PhoneNo: this.form.PhoneNo.value,

                    BranchId: 2,
                    CustomerGroupId: 2,
                    CurrencyId:2
                    
                };
                
                
               


                dlg.loadEntityAndOpenDialog(customer, true);


               // dlg.customerCode = "abc";
                
        
                //var newId;
                //Point.BusinessPartners.CustomersService.Create({
                //    Entity: {

                //        CustomerCode: 'ABC',
                //        CustomerName: this.form.CompanyName.value,
                //        Address: this.form.Address.value,
                //        PhoneNo: this.form.PhoneNo.value,
                //        CustomerGroupId: 2,
                        
                //        BranchId: 1,
                //        CurrencyId:2
                //    }
                    
                //}, resp => {
                //        newId = resp.EntityId;
                //        if (newId != undefined) {
                //            var customer = Point.BusinessPartners.CustomersRow.getLookup().itemById[newId];

                //            dlg.loadEntityAndOpenDialog(customer, true);
                //        }

                //            //Q.notifyInfo("New Customer added: " + resp.EntityId);
                //        });




            

               
             
            }
        }
    }
}