namespace Point.HRM {
    export interface EmployeesRow {
        EmployeeId?: number;
        TitleId?: number;
        FirstName?: string;
        LastName?: string;
        BirthDate?: string;
        HireDate?: string;
        Address?: string;
        CountryId?: number;
        BranchId?: number;
        DesignationId?: number;
        StateId?: number;
        CityId?: number;
        PostalCode?: string;
        HomePhone?: string;
        MobilePhone?: string;
        Email?: string;
        Picture?: string;
        Notes?: string;
        ReportsTo?: number;
        Payroll?: boolean;
        TitleTitleName?: string;
        TitleActive?: boolean;
        CountryName?: string;
        BranchName?: string;
        DesignationName?: string;
        CountryActive?: boolean;
        StateName?: string;
        StateCountryId?: number;
        StateActive?: boolean;
        CityName?: string;
        CityCountryId?: number;
        CityStateId?: number;
        CityActive?: number;
        ReportsToUsername?: string;
        ReportsToDisplayName?: string;
        ReportsToEmail?: string;
        ReportsToSource?: string;
        ReportsToLastDirectoryUpdate?: string;
        ReportsToUserImage?: string;
        ReportsToInsertDate?: string;
        ReportsToInsertUserId?: number;
        ReportsToUpdateDate?: string;
        ReportsToUpdateUserId?: number;
        ReportsToIsActive?: number;
        ReportsToMobilePhoneNumber?: string;
        ReportsToMobilePhoneVerified?: boolean;
        ReportsToTwoFactorAuth?: number;
        SalaryPayable?: string;
        AdvAgainstSalary?: string;
        AdvAgainstExpense?: string;
        SalaryList?: EmployeeSalariesRow[];
        LeavesCreditList?: EmployeeLeaveCreditsRow[];
        QualificationList?: EmployeeQualificationsRow[];
        ExperienceList?: EmployeeExperiencesRow[];
        TrainingList?: EmployeeTrainingsRow[];
        ComplaintList?: EmployeeComplaintsRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace EmployeesRow {
        export const idProperty = 'EmployeeId';
        export const nameProperty = 'FirstName';
        export const localTextPrefix = 'HRM.Employees';
        export const lookupKey = 'HRM.Employees';

        export function getLookup(): Q.Lookup<EmployeesRow> {
            return Q.getLookup<EmployeesRow>('HRM.Employees');
        }
        export const deletePermission = 'HRM:Employees';
        export const insertPermission = 'HRM:Employees';
        export const readPermission = 'HRM:Employees';
        export const updatePermission = 'HRM:Employees';

        export declare const enum Fields {
            EmployeeId = "EmployeeId",
            TitleId = "TitleId",
            FirstName = "FirstName",
            LastName = "LastName",
            BirthDate = "BirthDate",
            HireDate = "HireDate",
            Address = "Address",
            CountryId = "CountryId",
            BranchId = "BranchId",
            DesignationId = "DesignationId",
            StateId = "StateId",
            CityId = "CityId",
            PostalCode = "PostalCode",
            HomePhone = "HomePhone",
            MobilePhone = "MobilePhone",
            Email = "Email",
            Picture = "Picture",
            Notes = "Notes",
            ReportsTo = "ReportsTo",
            Payroll = "Payroll",
            TitleTitleName = "TitleTitleName",
            TitleActive = "TitleActive",
            CountryName = "CountryName",
            BranchName = "BranchName",
            DesignationName = "DesignationName",
            CountryActive = "CountryActive",
            StateName = "StateName",
            StateCountryId = "StateCountryId",
            StateActive = "StateActive",
            CityName = "CityName",
            CityCountryId = "CityCountryId",
            CityStateId = "CityStateId",
            CityActive = "CityActive",
            ReportsToUsername = "ReportsToUsername",
            ReportsToDisplayName = "ReportsToDisplayName",
            ReportsToEmail = "ReportsToEmail",
            ReportsToSource = "ReportsToSource",
            ReportsToLastDirectoryUpdate = "ReportsToLastDirectoryUpdate",
            ReportsToUserImage = "ReportsToUserImage",
            ReportsToInsertDate = "ReportsToInsertDate",
            ReportsToInsertUserId = "ReportsToInsertUserId",
            ReportsToUpdateDate = "ReportsToUpdateDate",
            ReportsToUpdateUserId = "ReportsToUpdateUserId",
            ReportsToIsActive = "ReportsToIsActive",
            ReportsToMobilePhoneNumber = "ReportsToMobilePhoneNumber",
            ReportsToMobilePhoneVerified = "ReportsToMobilePhoneVerified",
            ReportsToTwoFactorAuth = "ReportsToTwoFactorAuth",
            SalaryPayable = "SalaryPayable",
            AdvAgainstSalary = "AdvAgainstSalary",
            AdvAgainstExpense = "AdvAgainstExpense",
            SalaryList = "SalaryList",
            LeavesCreditList = "LeavesCreditList",
            QualificationList = "QualificationList",
            ExperienceList = "ExperienceList",
            TrainingList = "TrainingList",
            ComplaintList = "ComplaintList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
