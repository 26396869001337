/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeTrainingDialog extends Common.GridEditorDialog<EmployeeTrainingsRow> {
        protected getFormKey() { return EmployeeTrainingsForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeTrainingsRow.localTextPrefix; }

        protected form: EmployeeTrainingsForm;

        constructor() {
            super();

            this.form = new EmployeeTrainingsForm(this.idPrefix);

            this.form.TrainingId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var TrainingId = Q.toId(this.form.TrainingId.value);

                var Training = Point.HRM.TrainingsRow.getLookup().itemById[TrainingId];


                this.form.TrainingTitle.value = Training.TrainingTitle;




            });
         
            


        }


    
    }
}