
namespace Point.Products {

    @Serenity.Decorators.registerClass()
    export class ProductTransferGrid extends Serenity.EntityGrid<ProductTransferRow, any> {
        protected getColumnsKey() { return 'Products.ProductTransfer'; }
        protected getDialogType() { return ProductTransferDialog; }
        protected getIdProperty() { return ProductTransferRow.idProperty; }
        protected getInsertPermission() { return ProductTransferRow.insertPermission; }
        protected getLocalTextPrefix() { return ProductTransferRow.localTextPrefix; }
        protected getService() { return ProductTransferService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}