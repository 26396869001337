namespace Point.Sales {
    export interface MasterDetailsForm {
        UnitPrice: Serenity.DecimalEditor;
    }

    export class MasterDetailsForm extends Serenity.PrefixedContext {
        static formKey = 'Sales.MasterDetail';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MasterDetailsForm.init)  {
                MasterDetailsForm.init = true;

                var s = Serenity;
                var w0 = s.DecimalEditor;

                Q.initFormType(MasterDetailsForm, [
                    'UnitPrice', w0
                ]);
            }
        }
    }
}
