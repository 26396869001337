namespace Point.Meeting {
    export enum DecisionResolutionStatus {
        Unknown = 1,
        InProgress = 2,
        Overdue = 3,
        Postponed = 4,
        Unresolved = 5,
        Resolved = 6
    }
    Serenity.Decorators.registerEnumType(DecisionResolutionStatus, 'Point.Meeting.DecisionResolutionStatus');
}
