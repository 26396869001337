
namespace Point.BusinessPartners {

    @Serenity.Decorators.registerClass()
    export class BanksGrid extends Serenity.EntityGrid<BanksRow, any> {
        protected getColumnsKey() { return 'BusinessPartners.Banks'; }
        protected getDialogType() { return BanksDialog; }
        protected getIdProperty() { return BanksRow.idProperty; }
        protected getInsertPermission() { return BanksRow.insertPermission; }
        protected getLocalTextPrefix() { return BanksRow.localTextPrefix; }
        protected getService() { return BanksService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }


        protected getColumns() {
            var columns = super.getColumns();


            columns.splice(0, 0, {
                field: 'View Details',
                name: '',
                format: ctx => '<a class="inline-action view-details" title="View details"><i class="fa fa-pencil-square-o text-blue" aria-hidden="true"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });


            return columns;
        }


        protected onClick(e: JQueryEventObject, row: number, cell: number) {
            super.onClick(e, row, cell);

            if (e.isDefaultPrevented())
                return;

            var item = this.itemAt(row);
            var target = $(e.target);

            // if user clicks "i" element, e.g. icon
            if (target.parent().hasClass('inline-action'))
                target = target.parent();

            if (target.hasClass('inline-action')) {
                e.preventDefault();


                if (target.hasClass('view-details')) {
                    this.editItem(item.BankId);

                }
            }
        }

    }
}