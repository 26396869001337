/// <reference path="../../Common/Helpers/GridEditorBase.ts" />

namespace Point.RealEstate {

    @Serenity.Decorators.registerClass()
    export class BookingPlansEditor extends Common.GridEditorBase<BookingPlansRow> {
        protected getColumnsKey() { return "RealEstate.BookingPlans"; }
        protected getDialogType() { return BookingPlanDialog; }
        protected getLocalTextPrefix() { return BookingPlansRow.localTextPrefix; }

        constructor(container: JQuery) {
            super(container);
        }

        //validateEntity(row, id) {
        //    row.ProductID = Q.toId(row.ProductID);

        //    var sameProduct = Q.tryFirst(this.view.getItems(), x => x.ProductID === row.ProductID);
        //    if (sameProduct && this.id(sameProduct) !== id) {
        //        Q.alert('This product is already in Booking Plans!');
        //        return false;
        //    }

        //   // row.ProductName = ProductRow.getLookup().itemById[row.ProductID].ProductName;
        //   // row.LineTotal = (row.Quantity || 0) * (row.AmountDue || 0) - (row.Discount || 0);
        //    return true;
        //}


        protected getColumns() {
            var columns = super.getColumns();


            columns.splice(1, 0, {
                field: 'View Invoice',
                name: '',
                format: ctx => '<a class="inline-action preview-invoice" title="preview">' +
                    '<i class="fa fa-eye text-red"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });



            columns.splice(2, 0, {
                field: 'Print Invoice',
                name: '',
                format: ctx => '<a class="inline-action print-invoice" title="invoice">' +
                    '<i class="fa fa-file-pdf-o text-red"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });


            return columns;
        }


        protected onClick(e: JQueryEventObject, row: number, cell: number) {
            super.onClick(e, row, cell);

            if (e.isDefaultPrevented())
                return;

            var item = this.itemAt(row);
            var target = $(e.target);

            // if user clicks "i" element, e.g. icon
            if (target.parent().hasClass('inline-action'))
                target = target.parent();

            if (target.hasClass('inline-action')) {
                e.preventDefault();

                if (target.hasClass('print-invoice')) {
                    Point.Common.ReportHelper.execute({
                        reportKey: 'RealEstate.BookingChallan',
                        //reportKey: 'Reports.TestReportIndex',
                        download: false,
                        extension: 'pdf',
                        params: {
                            BookingPlanID: item.BookingPlanID
                        }
                    });
                }

                if (target.hasClass('preview-invoice')) {
                    Point.Common.ReportHelper.execute({
                        reportKey: 'RealEstate.BookingChallan',
                        //reportKey: 'Reports.TestReportIndex',
                        download: false,
                        extension: 'html',
                        params: {
                            BookingPlanID: item.BookingPlanID
                        }
                    });
                }
            }
        }
    }
}