namespace Point.Administration {
    export interface OrganizationsForm {
        OrganizationName: Serenity.StringEditor;
        Email: Serenity.StringEditor;
        Address: Serenity.StringEditor;
        Phone: Serenity.StringEditor;
        CurrencyId: Serenity.LookupEditor;
        Logo: Serenity.ImageUploadEditor;
        Active: Serenity.BooleanEditor;
    }

    export class OrganizationsForm extends Serenity.PrefixedContext {
        static formKey = 'Administration.Organizations';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!OrganizationsForm.init)  {
                OrganizationsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.ImageUploadEditor;
                var w3 = s.BooleanEditor;

                Q.initFormType(OrganizationsForm, [
                    'OrganizationName', w0,
                    'Email', w0,
                    'Address', w0,
                    'Phone', w0,
                    'CurrencyId', w1,
                    'Logo', w2,
                    'Active', w3
                ]);
            }
        }
    }
}
