namespace Point.Default {
    import Jdw = J.DashboardWidget;


    export class CashInBankSettingParams {
        Title?: string;
        RefreshIntervalMiliseconds?: number;
        BoxColorClass?: number;

    }

    export class CashInBank extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<CashInBankSettingParams>> {
        protected getContentSettingDialogType() { return CashInBankSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:CashInBankView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:CashInBankModify"; }

        constructor(opt: Jdw.BaseDashboardWidgetOptions<CashInBankSettingParams>) {
            super(opt);
        }

        protected buildWidgetData(widgetContentParams: CashInBankSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams); // optional 

            this.refreshIntervalMiliseconds = Q.toId(widgetContentParams.RefreshIntervalMiliseconds || 60000);

            let boxcolor = "bg-green";
            if (widgetContentParams.BoxColorClass == 2) boxcolor = "bg-green";
            if (widgetContentParams.BoxColorClass == 3) boxcolor = "bg-yellow";
            if (widgetContentParams.BoxColorClass == 4) boxcolor = "bg-red";


            return Point.Accounts.AccountsService.GetCashInBank({
                // ContainsText:"Today"
            }, response => {

                    this.byId("total").text(response);
                    this.byId("box").attr("class", "small-box " + boxcolor);

            });


        }

        protected canShowMaximizeToolbarButton() {
            return true;
        }

        protected canShowSettingButton() {
            return true;
        }

        protected getWidgetTitle() {
            return "Cash In Bank";
        }

        
    }
}