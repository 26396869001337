/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeExperienceDialog extends Common.GridEditorDialog<EmployeeExperiencesRow> {
        protected getFormKey() { return EmployeeExperiencesForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeExperiencesRow.localTextPrefix; }

        protected form: EmployeeExperiencesForm;

        constructor() {
            super();

            this.form = new EmployeeExperiencesForm(this.idPrefix);



            this.form.DesignationId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var DesignationId = Q.toId(this.form.DesignationId.value);

                var Designation = Point.HRM.DesignationsRow.getLookup().itemById[DesignationId];


                this.form.DesignationName.value = Designation.DesignationName;
              



            });

            


        }


    
    }
}