namespace Point.BusinessPartners {
    export interface TitlesRow {
        TitleId?: number;
        TitleName?: string;
        Active?: boolean;
    }

    export namespace TitlesRow {
        export const idProperty = 'TitleId';
        export const nameProperty = 'TitleName';
        export const localTextPrefix = 'BusinessPartners.Titles';
        export const lookupKey = 'BusinessPartners.Titles';

        export function getLookup(): Q.Lookup<TitlesRow> {
            return Q.getLookup<TitlesRow>('BusinessPartners.Titles');
        }
        export const deletePermission = 'BusinessPartners:Titles';
        export const insertPermission = 'BusinessPartners:Titles';
        export const readPermission = 'BusinessPartners:Titles';
        export const updatePermission = 'BusinessPartners:Titles';

        export declare const enum Fields {
            TitleId = "TitleId",
            TitleName = "TitleName",
            Active = "Active"
        }
    }
}
