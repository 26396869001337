namespace Point.HRM {
    export interface WorkingHoursForm {
        BranchId: Serenity.LookupEditor;
        Title: Serenity.StringEditor;
        StartDatet: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        StartTime: Serenity.TimeEditor;
        EndTime: Serenity.TimeEditor;
        SaturdayOff: Serenity.BooleanEditor;
    }

    export class WorkingHoursForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.WorkingHours';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!WorkingHoursForm.init)  {
                WorkingHoursForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;
                var w3 = s.TimeEditor;
                var w4 = s.BooleanEditor;

                Q.initFormType(WorkingHoursForm, [
                    'BranchId', w0,
                    'Title', w1,
                    'StartDatet', w2,
                    'EndDate', w2,
                    'StartTime', w3,
                    'EndTime', w3,
                    'SaturdayOff', w4
                ]);
            }
        }
    }
}
