namespace Point.Accounts {
    export interface VouchersRow {
        VoucherId?: number;
        VoucherTypeId?: number;
        VoucherNumber?: string;
        VoucherDate?: string;
        VoucherDescription?: string;
        Notes?: string;
        BranchId?: number;
        DepartmentId?: number;
        CurrencyId?: number;
        Verified?: boolean;
        VerifiedBy?: number;
        VerifiedDate?: string;
        Status?: number;
        Deleted?: boolean;
        ExGainLoss?: number;
        AdjustmentDate?: string;
        Locked?: boolean;
        DetailList?: VoucherDetailsRow[];
        BranchName?: string;
        DepartmentName?: string;
        CurrencyName?: string;
        VoucherTypeName?: string;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace VouchersRow {
        export const idProperty = 'VoucherId';
        export const nameProperty = 'VoucherNumber';
        export const localTextPrefix = 'Accounts.Vouchers';
        export const deletePermission = 'Accounts:Vouchers';
        export const insertPermission = 'Accounts:Vouchers';
        export const readPermission = 'Accounts:Vouchers';
        export const updatePermission = 'Accounts:Vouchers';

        export declare const enum Fields {
            VoucherId = "VoucherId",
            VoucherTypeId = "VoucherTypeId",
            VoucherNumber = "VoucherNumber",
            VoucherDate = "VoucherDate",
            VoucherDescription = "VoucherDescription",
            Notes = "Notes",
            BranchId = "BranchId",
            DepartmentId = "DepartmentId",
            CurrencyId = "CurrencyId",
            Verified = "Verified",
            VerifiedBy = "VerifiedBy",
            VerifiedDate = "VerifiedDate",
            Status = "Status",
            Deleted = "Deleted",
            ExGainLoss = "ExGainLoss",
            AdjustmentDate = "AdjustmentDate",
            Locked = "Locked",
            DetailList = "DetailList",
            BranchName = "BranchName",
            DepartmentName = "DepartmentName",
            CurrencyName = "CurrencyName",
            VoucherTypeName = "VoucherTypeName",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
