
namespace Point.Purchases {

    @Serenity.Decorators.registerClass()
    export class PurchaseInvoicesGrid extends Serenity.EntityGrid<PurchaseInvoicesRow, any> {
        protected getColumnsKey() { return 'Purchases.PurchaseInvoices'; }
        protected getDialogType() { return PurchaseInvoicesDialog; }
        protected getIdProperty() { return PurchaseInvoicesRow.idProperty; }
        protected getInsertPermission() { return PurchaseInvoicesRow.insertPermission; }
        protected getLocalTextPrefix() { return PurchaseInvoicesRow.localTextPrefix; }
        protected getService() { return PurchaseInvoicesService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}