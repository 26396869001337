
namespace Point.RealEstate {

    @Serenity.Decorators.registerClass()
    export class TypesGrid extends Serenity.EntityGrid<TypesRow, any> {
        protected getColumnsKey() { return 'RealEstate.Types'; }
        protected getDialogType() { return TypesDialog; }
        protected getIdProperty() { return TypesRow.idProperty; }
        protected getInsertPermission() { return TypesRow.insertPermission; }
        protected getLocalTextPrefix() { return TypesRow.localTextPrefix; }
        protected getService() { return TypesService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}