/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.RealEstate {

    @Serenity.Decorators.registerClass()
    export class ProjectPlanDialog extends Common.GridEditorDialog<ProjectPlansRow> {
        protected getFormKey() { return ProjectPlansForm.formKey; }
        protected getLocalTextPrefix() { return ProjectPlansRow.localTextPrefix; }

        protected form: ProjectPlansForm;

        constructor() {
            super();

            this.form = new ProjectPlansForm(this.idPrefix);

          
            this.form.Membership.addValidationRule(this.uniqueName, e=> {

                if (this.form.Membership.value > 100) {
       
                    return "Unit price must be less than 100";
                }
            
            });

        }
    }
}