
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class QualificationsDialog extends Serenity.EntityDialog<QualificationsRow, any> {
        protected getFormKey() { return QualificationsForm.formKey; }
        protected getIdProperty() { return QualificationsRow.idProperty; }
        protected getLocalTextPrefix() { return QualificationsRow.localTextPrefix; }
        protected getNameProperty() { return QualificationsRow.nameProperty; }
        protected getService() { return QualificationsService.baseUrl; }
        protected getDeletePermission() { return QualificationsRow.deletePermission; }
        protected getInsertPermission() { return QualificationsRow.insertPermission; }
        protected getUpdatePermission() { return QualificationsRow.updatePermission; }

        protected form = new QualificationsForm(this.idPrefix);

    }
}