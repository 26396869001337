namespace Point.Services {
    export interface ServiceDetailsRow {
        ServiceDetailID?: number;
        ServiceID?: number;
        UnitPrice?: number;
        Qty?: number;
        ServiceTypeName?: string;
        Discount?: number;
        Total?: number;
        ServiceTypeID?: number;
        Description?: string;
        RefNo?: string;
    }

    export namespace ServiceDetailsRow {
        export const idProperty = 'ServiceDetailID';
        export const localTextPrefix = 'Services.ServiceDetails';
        export const deletePermission = 'Services:ServiceDetails';
        export const insertPermission = 'Services:ServiceDetails';
        export const readPermission = 'Services:ServiceDetails';
        export const updatePermission = 'Services:ServiceDetails';

        export declare const enum Fields {
            ServiceDetailID = "ServiceDetailID",
            ServiceID = "ServiceID",
            UnitPrice = "UnitPrice",
            Qty = "Qty",
            ServiceTypeName = "ServiceTypeName",
            Discount = "Discount",
            Total = "Total",
            ServiceTypeID = "ServiceTypeID",
            Description = "Description",
            RefNo = "RefNo"
        }
    }
}
