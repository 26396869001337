namespace Point.Accounts {
    export interface AccountTypesRow {
        AccountTypeId?: number;
        AccountTypeName?: string;
        AccountNatureId?: number;
        Active?: boolean;
    }

    export namespace AccountTypesRow {
        export const idProperty = 'AccountTypeId';
        export const nameProperty = 'AccountTypeName';
        export const localTextPrefix = 'Accounts.AccountTypes';
        export const deletePermission = 'Accounts:Account Types';
        export const insertPermission = 'Accounts:Account Types';
        export const readPermission = 'Accounts:Account Types';
        export const updatePermission = 'Accounts:Account Types';

        export declare const enum Fields {
            AccountTypeId = "AccountTypeId",
            AccountTypeName = "AccountTypeName",
            AccountNatureId = "AccountNatureId",
            Active = "Active"
        }
    }
}
