namespace Point.Default {
    export enum SoundFile {
        Beeppressingbuttons = 1,
        ClockAlarmElectronicBeep = 2,
        Connectionestablishedsoundeffect = 3,
        Errorsound = 4,
        Glisteningsoundeffect = 5,
        smstone = 6,
        Ticketmachinesound = 7,
        Usbconnectionsoundeffect = 8,
        Warningbeepingsound = 9
    }
    Serenity.Decorators.registerEnumType(SoundFile, 'Point.Default.SoundFile');
}
