namespace Point.HRM {
    export interface EmployeesForm {
        TitleId: Serenity.LookupEditor;
        FirstName: Serenity.StringEditor;
        LastName: Serenity.StringEditor;
        BirthDate: Serenity.DateEditor;
        HireDate: Serenity.DateEditor;
        ReportsTo: Serenity.LookupEditor;
        BranchId: Serenity.LookupEditor;
        DesignationId: Serenity.LookupEditor;
        Payroll: Serenity.BooleanEditor;
        SalaryPayable: Serenity.StringEditor;
        AdvAgainstSalary: Serenity.StringEditor;
        AdvAgainstExpense: Serenity.StringEditor;
        Picture: Serenity.ImageUploadEditor;
        Address: Serenity.StringEditor;
        CountryId: Serenity.LookupEditor;
        StateId: Serenity.LookupEditor;
        CityId: Serenity.LookupEditor;
        PostalCode: Serenity.StringEditor;
        HomePhone: Serenity.StringEditor;
        MobilePhone: Serenity.StringEditor;
        Email: Serenity.StringEditor;
        Notes: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
        SalaryList: EmployeeSalariesEditor;
        LeavesCreditList: EmployeeLeaveCreditsEditor;
        QualificationList: EmployeeQualificationsEditor;
        ExperienceList: EmployeeExperiencesEditor;
        TrainingList: EmployeeTrainingsEditor;
        ComplaintList: EmployeeComplaintsEditor;
    }

    export class EmployeesForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.Employees';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeesForm.init)  {
                EmployeesForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;
                var w3 = s.BooleanEditor;
                var w4 = s.ImageUploadEditor;
                var w5 = EmployeeSalariesEditor;
                var w6 = EmployeeLeaveCreditsEditor;
                var w7 = EmployeeQualificationsEditor;
                var w8 = EmployeeExperiencesEditor;
                var w9 = EmployeeTrainingsEditor;
                var w10 = EmployeeComplaintsEditor;

                Q.initFormType(EmployeesForm, [
                    'TitleId', w0,
                    'FirstName', w1,
                    'LastName', w1,
                    'BirthDate', w2,
                    'HireDate', w2,
                    'ReportsTo', w0,
                    'BranchId', w0,
                    'DesignationId', w0,
                    'Payroll', w3,
                    'SalaryPayable', w1,
                    'AdvAgainstSalary', w1,
                    'AdvAgainstExpense', w1,
                    'Picture', w4,
                    'Address', w1,
                    'CountryId', w0,
                    'StateId', w0,
                    'CityId', w0,
                    'PostalCode', w1,
                    'HomePhone', w1,
                    'MobilePhone', w1,
                    'Email', w1,
                    'Notes', w1,
                    'InsertName', w1,
                    'InsertDate', w2,
                    'UpdateName', w1,
                    'UpdateDate', w2,
                    'SalaryList', w5,
                    'LeavesCreditList', w6,
                    'QualificationList', w7,
                    'ExperienceList', w8,
                    'TrainingList', w9,
                    'ComplaintList', w10
                ]);
            }
        }
    }
}
