namespace Point.Meeting {
    export interface MeetingAgendaRelevantForm {
        AgendaId: Serenity.IntegerEditor;
        ContactId: Serenity.IntegerEditor;
    }

    export class MeetingAgendaRelevantForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.MeetingAgendaRelevant';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingAgendaRelevantForm.init)  {
                MeetingAgendaRelevantForm.init = true;

                var s = Serenity;
                var w0 = s.IntegerEditor;

                Q.initFormType(MeetingAgendaRelevantForm, [
                    'AgendaId', w0,
                    'ContactId', w0
                ]);
            }
        }
    }
}
