namespace Point.Meeting {
    export interface MeetingTypeForm {
        Name: Serenity.StringEditor;
    }

    export class MeetingTypeForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.MeetingType';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingTypeForm.init)  {
                MeetingTypeForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;

                Q.initFormType(MeetingTypeForm, [
                    'Name', w0
                ]);
            }
        }
    }
}
