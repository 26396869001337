
namespace Point.CMS {

    @Serenity.Decorators.registerClass()
    export class PagesDialog extends Serenity.EntityDialog<PagesRow, any> {
        protected getFormKey() { return PagesForm.formKey; }
        protected getIdProperty() { return PagesRow.idProperty; }
        protected getLocalTextPrefix() { return PagesRow.localTextPrefix; }
        protected getNameProperty() { return PagesRow.nameProperty; }
        protected getService() { return PagesService.baseUrl; }
        protected getDeletePermission() { return PagesRow.deletePermission; }
        protected getInsertPermission() { return PagesRow.insertPermission; }
        protected getUpdatePermission() { return PagesRow.updatePermission; }

        protected form = new PagesForm(this.idPrefix);

    }
}