namespace Point.Default {
    export interface AccountWatchSettingForm {
        Title: Serenity.StringEditor;
        TitleIconClass: Serenity.StringEditor;
        AccountId: Serenity.LookupEditor;
    }

    export class AccountWatchSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.AccountWatchSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!AccountWatchSettingForm.init)  {
                AccountWatchSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;

                Q.initFormType(AccountWatchSettingForm, [
                    'Title', w0,
                    'TitleIconClass', w0,
                    'AccountId', w1
                ]);
            }
        }
    }
}
