
namespace Point.Services {

    @Serenity.Decorators.registerClass()
    export class ServiceTypesDialog extends Serenity.EntityDialog<ServiceTypesRow, any> {
        protected getFormKey() { return ServiceTypesForm.formKey; }
        protected getIdProperty() { return ServiceTypesRow.idProperty; }
        protected getLocalTextPrefix() { return ServiceTypesRow.localTextPrefix; }
        protected getNameProperty() { return ServiceTypesRow.nameProperty; }
        protected getService() { return ServiceTypesService.baseUrl; }
        protected getDeletePermission() { return ServiceTypesRow.deletePermission; }
        protected getInsertPermission() { return ServiceTypesRow.insertPermission; }
        protected getUpdatePermission() { return ServiceTypesRow.updatePermission; }

        protected form = new ServiceTypesForm(this.idPrefix);

    }
}