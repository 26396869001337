namespace Point.HRM {
    export interface EmployeeLeavesForm {
        EmployeeId: Serenity.LookupEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        LeaveType: Serenity.EnumEditor;
        LeaveDuration: Serenity.EnumEditor;
        UnInformed: Serenity.BooleanEditor;
        LeaveApplication: Serenity.ImageUploadEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeLeavesForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeLeaves';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeLeavesForm.init)  {
                EmployeeLeavesForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.DateEditor;
                var w2 = s.EnumEditor;
                var w3 = s.BooleanEditor;
                var w4 = s.ImageUploadEditor;
                var w5 = s.StringEditor;

                Q.initFormType(EmployeeLeavesForm, [
                    'EmployeeId', w0,
                    'StartDate', w1,
                    'EndDate', w1,
                    'LeaveType', w2,
                    'LeaveDuration', w2,
                    'UnInformed', w3,
                    'LeaveApplication', w4,
                    'Notes', w5
                ]);
            }
        }
    }
}
