namespace Point.Accounts {
    export interface VouchersForm {
        VoucherNumber: Serenity.StringEditor;
        VoucherTypeId: Serenity.LookupEditor;
        VoucherDate: Serenity.DateEditor;
        AdjustmentDate: Serenity.DateEditor;
        BranchId: Serenity.LookupEditor;
        DepartmentId: Serenity.LookupEditor;
        CurrencyId: Serenity.LookupEditor;
        ExGainLoss: Serenity.DecimalEditor;
        VoucherDescription: Serenity.StringEditor;
        DetailList: VoucherDetailsEditor;
        Verified: Serenity.BooleanEditor;
        VerifiedBy: Serenity.IntegerEditor;
        VerifiedDate: Serenity.DateEditor;
        Locked: Serenity.BooleanEditor;
        Status: Serenity.EnumEditor;
        Deleted: Serenity.BooleanEditor;
        Notes: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class VouchersForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.Vouchers';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!VouchersForm.init)  {
                VouchersForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.DateEditor;
                var w3 = s.DecimalEditor;
                var w4 = VoucherDetailsEditor;
                var w5 = s.BooleanEditor;
                var w6 = s.IntegerEditor;
                var w7 = s.EnumEditor;

                Q.initFormType(VouchersForm, [
                    'VoucherNumber', w0,
                    'VoucherTypeId', w1,
                    'VoucherDate', w2,
                    'AdjustmentDate', w2,
                    'BranchId', w1,
                    'DepartmentId', w1,
                    'CurrencyId', w1,
                    'ExGainLoss', w3,
                    'VoucherDescription', w0,
                    'DetailList', w4,
                    'Verified', w5,
                    'VerifiedBy', w6,
                    'VerifiedDate', w2,
                    'Locked', w5,
                    'Status', w7,
                    'Deleted', w5,
                    'Notes', w0,
                    'InsertName', w0,
                    'InsertDate', w2,
                    'UpdateName', w0,
                    'UpdateDate', w2
                ]);
            }
        }
    }
}
