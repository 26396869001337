namespace Point.HRM {
    export interface EmployeeExperiencesForm {
        CompanyName: Serenity.StringEditor;
        DesignationId: Serenity.LookupEditor;
        DesignationName: Serenity.StringEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        ResignReason: Serenity.StringEditor;
        ContactDetails: Serenity.StringEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeExperiencesForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeExperiences';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeExperiencesForm.init)  {
                EmployeeExperiencesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.DateEditor;

                Q.initFormType(EmployeeExperiencesForm, [
                    'CompanyName', w0,
                    'DesignationId', w1,
                    'DesignationName', w0,
                    'StartDate', w2,
                    'EndDate', w2,
                    'ResignReason', w0,
                    'ContactDetails', w0,
                    'Notes', w0
                ]);
            }
        }
    }
}
