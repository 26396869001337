namespace Point.RealEstate {
    export interface FeatureChargesForm {
        FeatureName: Serenity.StringEditor;
        Charges: Serenity.IntegerEditor;
    }

    export class FeatureChargesForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.FeatureCharges';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!FeatureChargesForm.init)  {
                FeatureChargesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.IntegerEditor;

                Q.initFormType(FeatureChargesForm, [
                    'FeatureName', w0,
                    'Charges', w1
                ]);
            }
        }
    }
}
