namespace Point.BusinessPartners {
    export interface CountriesForm {
        Name: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class CountriesForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.Countries';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!CountriesForm.init)  {
                CountriesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(CountriesForm, [
                    'Name', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
