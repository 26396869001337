/// <reference path="../../Common/Helpers/GridEditorBase.ts" />

namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class SaleInvoiceDetailsEditor extends Common.GridEditorBase<SaleInvoiceDetailsRow> {
        protected getColumnsKey() { return "Sales.SaleInvoiceDetail"; }
        protected getDialogType() { return SaleInvoiceDetailDialog; }
        protected getLocalTextPrefix() { return SaleInvoiceDetailsRow.localTextPrefix; }

        public cusutomerId: number;

        constructor(container: JQuery) {
            super(container);


         
             
        }

      

        protected initEntityDialog(itemType: string, dialog: Serenity.Widget<any>) {
            super.initEntityDialog(itemType, dialog);

            // passing category ID from grid editor to detail dialog
            (dialog as SaleInvoiceDetailDialog).cusutomerId = this.cusutomerId;
        }

     
        //validateEntity(row, id) {
        //    row.ProductID = Q.toId(row.ProductID);

        //    var sameProduct = Q.tryFirst(this.view.getItems(), x => x.ProductID === row.ProductID);
        //    if (sameProduct && this.id(sameProduct) !== id) {
        //        Q.alert('This product is already in SaleInvoice Details!');
        //        return false;
        //    }

        //   // row.ProductName = ProductRow.getLookup().itemById[row.ProductID].ProductName;
        //   // row.LineTotal = (row.Quantity || 0) * (row.UnitPrice || 0) - (row.Discount || 0);
        //    return true;
        //}
    }
}