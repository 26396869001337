namespace Point.HRM {
    export interface EmployeeAttendanceRow {
        EmployeeAttendanceId?: number;
        EmployeeId?: number;
        Checkin?: string;
        Checkout?: string;
        Notes?: string;
        EmployeeTitleId?: number;
        EmployeeFirstName?: string;
        EmployeeLastName?: string;
        EmployeeBirthDate?: string;
        EmployeeHireDate?: string;
        EmployeeAddress?: string;
        EmployeeCountryId?: number;
        EmployeeStateId?: number;
        EmployeeCityId?: number;
        EmployeePostalCode?: string;
        EmployeeHomePhone?: string;
        EmployeeMobilePhone?: string;
        EmployeeEmail?: string;
        EmployeePicture?: string;
        EmployeeNotes?: string;
        EmployeeReportsTo?: number;
        EmployeeCreatedBy?: number;
        EmployeeCreatedOn?: string;
        EmployeeModifiedBy?: number;
        EmployeeModifiedOn?: string;
        EmployeePayroll?: boolean;
    }

    export namespace EmployeeAttendanceRow {
        export const idProperty = 'EmployeeAttendanceId';
        export const nameProperty = 'Notes';
        export const localTextPrefix = 'HRM.EmployeeAttendance';
        export const deletePermission = 'Human Resources:Employee Attendance';
        export const insertPermission = 'Human Resources:Employee Attendance';
        export const readPermission = 'Human Resources:Employee Attendance';
        export const updatePermission = 'Human Resources:Employee Attendance';

        export declare const enum Fields {
            EmployeeAttendanceId = "EmployeeAttendanceId",
            EmployeeId = "EmployeeId",
            Checkin = "Checkin",
            Checkout = "Checkout",
            Notes = "Notes",
            EmployeeTitleId = "EmployeeTitleId",
            EmployeeFirstName = "EmployeeFirstName",
            EmployeeLastName = "EmployeeLastName",
            EmployeeBirthDate = "EmployeeBirthDate",
            EmployeeHireDate = "EmployeeHireDate",
            EmployeeAddress = "EmployeeAddress",
            EmployeeCountryId = "EmployeeCountryId",
            EmployeeStateId = "EmployeeStateId",
            EmployeeCityId = "EmployeeCityId",
            EmployeePostalCode = "EmployeePostalCode",
            EmployeeHomePhone = "EmployeeHomePhone",
            EmployeeMobilePhone = "EmployeeMobilePhone",
            EmployeeEmail = "EmployeeEmail",
            EmployeePicture = "EmployeePicture",
            EmployeeNotes = "EmployeeNotes",
            EmployeeReportsTo = "EmployeeReportsTo",
            EmployeeCreatedBy = "EmployeeCreatedBy",
            EmployeeCreatedOn = "EmployeeCreatedOn",
            EmployeeModifiedBy = "EmployeeModifiedBy",
            EmployeeModifiedOn = "EmployeeModifiedOn",
            EmployeePayroll = "EmployeePayroll"
        }
    }
}
