namespace Point.Meeting {
    export interface MeetingTypeRow {
        MeetingTypeId?: number;
        Name?: string;
    }

    export namespace MeetingTypeRow {
        export const idProperty = 'MeetingTypeId';
        export const nameProperty = 'Name';
        export const localTextPrefix = 'Meeting.MeetingType';
        export const lookupKey = 'Meeting.MeetingType';

        export function getLookup(): Q.Lookup<MeetingTypeRow> {
            return Q.getLookup<MeetingTypeRow>('Meeting.MeetingType');
        }
        export const deletePermission = 'Meeting:Management';
        export const insertPermission = 'Meeting:Management';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:Management';

        export declare const enum Fields {
            MeetingTypeId = "MeetingTypeId",
            Name = "Name"
        }
    }
}
