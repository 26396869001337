
namespace Point.Purchases {

    @Serenity.Decorators.registerClass()
    export class PurchaseOrdersGrid extends Serenity.EntityGrid<PurchaseOrdersRow, any> {
        protected getColumnsKey() { return 'Purchases.PurchaseOrders'; }
        protected getDialogType() { return PurchaseOrdersDialog; }
        protected getIdProperty() { return PurchaseOrdersRow.idProperty; }
        protected getInsertPermission() { return PurchaseOrdersRow.insertPermission; }
        protected getLocalTextPrefix() { return PurchaseOrdersRow.localTextPrefix; }
        protected getService() { return PurchaseOrdersService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}