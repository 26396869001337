namespace Point.Default {
    import Jdw = J.DashboardWidget;

    export class AccountWatchSettingParams {
        Title: string;
        TitleIconClass: string;
        AccountId: number;
     
    }

    export class AccountWatch extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<AccountWatchSettingParams>> {
        protected getContentSettingDialogType() { return AccountWatchSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:AccountWatchView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:AccountWatchModify"; }

        constructor(opt: Jdw.BaseDashboardWidgetOptions<AccountWatchSettingParams>) {
            super(opt);
            
            //this.refreshIntervalMiliseconds = 1000;

            //setTimeout(() => this.canShowRefreshLoadingIndicator = () => true, 5000);
            //setTimeout(() => this.canShowRefreshLoadingIndicator = () => false, 15000);            
        }

        protected buildWidgetData(widgetContentParams: AccountWatchSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams);

            this.updateTitlebar(widgetContentParams);


            let accountid = widgetContentParams.AccountId;

            if (accountid == undefined) return;

          
            return Point.Accounts.AccountsService.GetAccountStatus({
                ContainsText: accountid.toString()
                // ContainsText:"Today"
            }, response => {

                    this.byId("AccountCode").text(response[0].AccountCode);
                    this.byId("AccountTitle").text(response[0].AccountTitle);
                    this.byId("Invoices").text(response[0].Invoices);
                    this.byId("Receipts").text(response[0].Receipts);
                    this.byId("Balance").text(response[0].Balance);
                    


               // this.byId("total").text(response);
            });
        }

        protected updateTitlebar(widgetContentParams: AccountWatchSettingParams) {
            let widgetName = "";
            if (Q.trimToNull(widgetContentParams.Title) == null) {
                widgetName = Q.coalesce(this.getWidgetTitle(), "&nbsp;");
            }
            else {
                widgetName = widgetContentParams.Title;
            }

            this.byId("title").text($("<div />").html(widgetName).text());
            this.byId("titleIcon").attr('class', `${widgetContentParams.TitleIconClass}`);
        }

        protected embedWidgetToDialog(dlg: Jdw.BaseWidgetViewInDialog<Jdw.BaseWidgetViewInDialogOptions>, wg: Jdw.BaseDashboardWidget<any>) {
            dlg.element.append(wg.element.find(".box-body"));
            dlg.dialogOpen();
        }

        protected canShowLoadingOverlayWhenRefresh() {
            return false;
        }

        protected canShowRefreshLoadingIndicator() {
            return false;
        }

        protected getWidgetTitle() {
            return "Account Watch";
        }
    }
}