namespace Point.Administration {
    export interface OrganizationsRow {
        OrganizationId?: number;
        OrganizationName?: string;
        Email?: string;
        Address?: string;
        Phone?: string;
        CurrencyId?: number;
        Logo?: string;
        Active?: boolean;
    }

    export namespace OrganizationsRow {
        export const idProperty = 'OrganizationId';
        export const nameProperty = 'OrganizationName';
        export const localTextPrefix = 'Organization.Organizations';
        export const lookupKey = 'Organization.Organizations';

        export function getLookup(): Q.Lookup<OrganizationsRow> {
            return Q.getLookup<OrganizationsRow>('Organization.Organizations');
        }
        export const deletePermission = 'Administration:Organization';
        export const insertPermission = 'Administration:Organization';
        export const readPermission = 'Administration:Organization';
        export const updatePermission = 'Administration:Organization';

        export declare const enum Fields {
            OrganizationId = "OrganizationId",
            OrganizationName = "OrganizationName",
            Email = "Email",
            Address = "Address",
            Phone = "Phone",
            CurrencyId = "CurrencyId",
            Logo = "Logo",
            Active = "Active"
        }
    }
}
