
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class WorkingHoursGrid extends Serenity.EntityGrid<WorkingHoursRow, any> {
        protected getColumnsKey() { return 'HRM.WorkingHours'; }
        protected getDialogType() { return WorkingHoursDialog; }
        protected getIdProperty() { return WorkingHoursRow.idProperty; }
        protected getInsertPermission() { return WorkingHoursRow.insertPermission; }
        protected getLocalTextPrefix() { return WorkingHoursRow.localTextPrefix; }
        protected getService() { return WorkingHoursService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}