namespace Point.Administration {
    export interface BranchRow {
        BranchId?: number;
        BranchNo?: string;
        BranchCode?: string;
        BranchName?: string;
        HeadOffice?: boolean;
    }

    export namespace BranchRow {
        export const idProperty = 'BranchId';
        export const nameProperty = 'BranchName';
        export const localTextPrefix = 'Administration.Branch';
        export const lookupKey = 'Administration.Branch';

        export function getLookup(): Q.Lookup<BranchRow> {
            return Q.getLookup<BranchRow>('Administration.Branch');
        }
        export const deletePermission = 'Organizatiton:Branch';
        export const insertPermission = 'Organizatiton:Branch';
        export const readPermission = 'Organizatiton:Branch';
        export const updatePermission = 'Organizatiton:Branch';

        export declare const enum Fields {
            BranchId = "BranchId",
            BranchNo = "BranchNo",
            BranchCode = "BranchCode",
            BranchName = "BranchName",
            HeadOffice = "HeadOffice"
        }
    }
}
