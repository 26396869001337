namespace Point.HRM {
    export interface EmployeeComplaintsRow {
        EmployeeComplaintID?: number;
        EmployeeID?: number;
        ComplaintDate?: string;
        ComplaintTypeId?: number;
        ComplaintType?: string;
        ComplaintDetail?: string;
        ActionTaken?: string;
        Notes?: string;
    }

    export namespace EmployeeComplaintsRow {
        export const idProperty = 'EmployeeComplaintID';
        export const localTextPrefix = 'HRM.EmployeeComplaints';
        export const deletePermission = 'HRM:EmployeeComplaints';
        export const insertPermission = 'HRM:EmployeeComplaints';
        export const readPermission = 'HRM:EmployeeComplaints';
        export const updatePermission = 'HRM:EmployeeComplaints';

        export declare const enum Fields {
            EmployeeComplaintID = "EmployeeComplaintID",
            EmployeeID = "EmployeeID",
            ComplaintDate = "ComplaintDate",
            ComplaintTypeId = "ComplaintTypeId",
            ComplaintType = "ComplaintType",
            ComplaintDetail = "ComplaintDetail",
            ActionTaken = "ActionTaken",
            Notes = "Notes"
        }
    }
}
