namespace Point.Products {
    export interface WarehousesForm {
        WarehouseCode: Serenity.StringEditor;
        WarehouseName: Serenity.StringEditor;
        Status: Serenity.EnumEditor;
        Address: Serenity.StringEditor;
        CountryId: Serenity.LookupEditor;
        StateId: Serenity.LookupEditor;
        CityId: Serenity.LookupEditor;
        RegionId: Serenity.LookupEditor;
        TerritoryId: Serenity.LookupEditor;
        PostalCode: Serenity.StringEditor;
        PhoneNo: Serenity.StringEditor;
        MobileNo: Serenity.StringEditor;
        Email: Serenity.StringEditor;
        CreatedBy: Serenity.IntegerEditor;
        CreatedOn: Serenity.DateEditor;
        ModifiedBy: Serenity.IntegerEditor;
        ModifiedOn: Serenity.DateEditor;
    }

    export class WarehousesForm extends Serenity.PrefixedContext {
        static formKey = 'Products.Warehouses';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!WarehousesForm.init)  {
                WarehousesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;
                var w2 = s.LookupEditor;
                var w3 = s.IntegerEditor;
                var w4 = s.DateEditor;

                Q.initFormType(WarehousesForm, [
                    'WarehouseCode', w0,
                    'WarehouseName', w0,
                    'Status', w1,
                    'Address', w0,
                    'CountryId', w2,
                    'StateId', w2,
                    'CityId', w2,
                    'RegionId', w2,
                    'TerritoryId', w2,
                    'PostalCode', w0,
                    'PhoneNo', w0,
                    'MobileNo', w0,
                    'Email', w0,
                    'CreatedBy', w3,
                    'CreatedOn', w4,
                    'ModifiedBy', w3,
                    'ModifiedOn', w4
                ]);
            }
        }
    }
}
