namespace Point.Accounts {
    export interface TaxGroupsRow {
        TaxGroupId?: number;
        TaxGroupCode?: string;
        TaxGroupuName?: string;
        DetailList?: TaxDetailsRow[];
    }

    export namespace TaxGroupsRow {
        export const idProperty = 'TaxGroupId';
        export const nameProperty = 'TaxGroupCode';
        export const localTextPrefix = 'Accounts.TaxGroups';
        export const lookupKey = 'Accounts.TaxGroups';

        export function getLookup(): Q.Lookup<TaxGroupsRow> {
            return Q.getLookup<TaxGroupsRow>('Accounts.TaxGroups');
        }
        export const deletePermission = 'Accounts:TaxGroups';
        export const insertPermission = 'Accounts:TaxGroups';
        export const readPermission = 'Accounts:TaxGroups';
        export const updatePermission = 'Accounts:TaxGroups';

        export declare const enum Fields {
            TaxGroupId = "TaxGroupId",
            TaxGroupCode = "TaxGroupCode",
            TaxGroupuName = "TaxGroupuName",
            DetailList = "DetailList"
        }
    }
}
