namespace Point.Accounts {
    export interface TaxDetailsForm {
        TaxDetailCode: Serenity.StringEditor;
        TaxDetailName: Serenity.StringEditor;
        TaxRate: Serenity.DecimalEditor;
        Customer: Serenity.BooleanEditor;
        Supplier: Serenity.BooleanEditor;
        Employee: Serenity.BooleanEditor;
    }

    export class TaxDetailsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.TaxDetail';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TaxDetailsForm.init)  {
                TaxDetailsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DecimalEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(TaxDetailsForm, [
                    'TaxDetailCode', w0,
                    'TaxDetailName', w0,
                    'TaxRate', w1,
                    'Customer', w2,
                    'Supplier', w2,
                    'Employee', w2
                ]);
            }
        }
    }
}
