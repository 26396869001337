namespace Point.Accounts {
    export interface AccountSettingsForm {
        CustommerControlAccountId: Serenity.LookupEditor;
        EmployeeSalaryPayableControlAccountId: Serenity.LookupEditor;
        SupplierControlAccountId: Serenity.LookupEditor;
        EmployeeAdvanceAgainstSalaryAccountId: Serenity.LookupEditor;
        BankControlAccountId: Serenity.LookupEditor;
        EmployeeAdvanceAgainstExpenseAccountId: Serenity.LookupEditor;
        ServiceTypeSaleControlAccountId: Serenity.LookupEditor;
        ServiceTypeCostControlAccountId: Serenity.LookupEditor;
        ProductSaleControlAccountId: Serenity.LookupEditor;
        ProductCostControlAccountId: Serenity.LookupEditor;
    }

    export class AccountSettingsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.AccountSettings';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!AccountSettingsForm.init)  {
                AccountSettingsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;

                Q.initFormType(AccountSettingsForm, [
                    'CustommerControlAccountId', w0,
                    'EmployeeSalaryPayableControlAccountId', w0,
                    'SupplierControlAccountId', w0,
                    'EmployeeAdvanceAgainstSalaryAccountId', w0,
                    'BankControlAccountId', w0,
                    'EmployeeAdvanceAgainstExpenseAccountId', w0,
                    'ServiceTypeSaleControlAccountId', w0,
                    'ServiceTypeCostControlAccountId', w0,
                    'ProductSaleControlAccountId', w0,
                    'ProductCostControlAccountId', w0
                ]);
            }
        }
    }
}
