/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class SaleQuotationDetailDialog extends Common.GridEditorDialog<SaleQuotationDetailsRow> {
        protected getFormKey() { return SaleQuotationDetailsForm.formKey; }
        protected getLocalTextPrefix() { return SaleQuotationDetailsRow.localTextPrefix; }

        protected form: SaleQuotationDetailsForm;

        constructor() {
            super();

            this.form = new SaleQuotationDetailsForm(this.idPrefix);


            //this.form.UnitPrice.addValidationRule(this.uniqueName, e=> {

            //    if (this.form.UnitPrice.value > 100) {

            //        return "Unit price must be less than 100";
            //    }

            //});


            this.form.UnitPrice.change(e => {

                this.doCalculation();


            });


            this.form.Discount.change(e => {

                this.doCalculation();


            });



            this.form.Qty.change(e => {

                this.doCalculation();


            });

            this.form.DiscountPers.change(e => {

                //var unitprice = 0;
                //if (this.form.UnitPrice.value != null)
                //    unitprice = this.form.UnitPrice.value;

                this.doCalculation();

            });


            this.form.TaxDetailId.changeSelect2(e => {


               

                this.doCalculation();

               
                

            });


            this.form.ProductID.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();

                

                var ProductID = Q.toId(this.form.ProductID.value);

                var Product = Point.Products.ProductsRow.getLookup().itemById[ProductID];

                this.form.Qty.value = 1;
                this.form.UnitPrice.value = Product.CostPrice;
                this.form.UnitCode.value = Product.UnitCode;
                this.form.ProductName.value = Product.ProductName;

                this.doCalculation();

               //// var curid = Q.toId(account.CurrencyId);

               //// this.form.CurrencyID.value = curid;


            


            });
        }

       
        private doCalculation() {

            var unitprice = 0;
            if (this.form.UnitPrice.value != null)
                unitprice = this.form.UnitPrice.value;

            var qty = 1;
            if (this.form.Qty.value != null)
                qty = this.form.Qty.value;


            var subtotal = unitprice * qty;

            var discountAmount = 0;
            var discountPers = 0;
            if (this.form.DiscountPers.value != null) {
                discountPers = this.form.DiscountPers.value;
                var discountAmount = (subtotal * discountPers) / 100;
                this.form.Discount.value = discountAmount;
            }


            var taxAmount = 0;
            var TaxID = Q.toId(this.form.TaxDetailId.value);
          
            var TaxDetail = Point.Accounts.TaxDetailsRow.getLookup().itemById[TaxID];
            if (TaxDetail != null) {
                var TaxPers = TaxDetail.TaxRate;
           


                taxAmount = ((subtotal - discountAmount) * TaxPers) / 100;

                this.form.Tax.value = taxAmount;
                this.form.TaxDetailName.value = TaxDetail.TaxDetailName;
     

            }


            this.form.Total.value = (subtotal - discountAmount) + taxAmount ;


            
        }
    }
}