namespace Point.Products {
    export interface ProductsRow {
        ProductId?: number;
        ProductCode?: string;
        ProductName?: string;
        Barcode?: string;
        SupplierId?: number;
        ProductCategoryId?: number;
        QuantityPerUnit?: string;
        RegularPrice?: number;
        CostPrice?: number;
        SalePrice?: number;
        UnitsInStock?: number;
        UnitsOnOrder?: number;
        ReorderLevel?: number;
        Discontinued?: boolean;
        ProductImage?: string;
        SaleAccount?: string;
        CostAccount?: string;
        BrandId?: number;
        UnitId?: number;
        UnitCode?: string;
        WarehouseId?: number;
        StockType?: number;
        TaxGroupId?: number;
        SupplierCompanyName?: string;
        SupplierContactName?: string;
        SupplierAddress?: string;
        SupplierPostalCode?: string;
        SupplierPhoneNo?: string;
        SupplierLogo?: string;
        ProductCategoryProductCategoryName?: string;
        ProductCategoryDescription?: string;
        ProductCategoryPicture?: string;
        ProductCategoryActive?: boolean;
        CostPriceList?: ProductCostPricesRow[];
        SalePriceList?: ProductSalePricesRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace ProductsRow {
        export const idProperty = 'ProductId';
        export const nameProperty = 'ProductName';
        export const localTextPrefix = 'Products.Products';
        export const lookupKey = 'Products.Products';

        export function getLookup(): Q.Lookup<ProductsRow> {
            return Q.getLookup<ProductsRow>('Products.Products');
        }
        export const deletePermission = 'Products:Products';
        export const insertPermission = 'Products:Products';
        export const readPermission = 'Products:Products';
        export const updatePermission = 'Products:Products';

        export declare const enum Fields {
            ProductId = "ProductId",
            ProductCode = "ProductCode",
            ProductName = "ProductName",
            Barcode = "Barcode",
            SupplierId = "SupplierId",
            ProductCategoryId = "ProductCategoryId",
            QuantityPerUnit = "QuantityPerUnit",
            RegularPrice = "RegularPrice",
            CostPrice = "CostPrice",
            SalePrice = "SalePrice",
            UnitsInStock = "UnitsInStock",
            UnitsOnOrder = "UnitsOnOrder",
            ReorderLevel = "ReorderLevel",
            Discontinued = "Discontinued",
            ProductImage = "ProductImage",
            SaleAccount = "SaleAccount",
            CostAccount = "CostAccount",
            BrandId = "BrandId",
            UnitId = "UnitId",
            UnitCode = "UnitCode",
            WarehouseId = "WarehouseId",
            StockType = "StockType",
            TaxGroupId = "TaxGroupId",
            SupplierCompanyName = "SupplierCompanyName",
            SupplierContactName = "SupplierContactName",
            SupplierAddress = "SupplierAddress",
            SupplierPostalCode = "SupplierPostalCode",
            SupplierPhoneNo = "SupplierPhoneNo",
            SupplierLogo = "SupplierLogo",
            ProductCategoryProductCategoryName = "ProductCategoryProductCategoryName",
            ProductCategoryDescription = "ProductCategoryDescription",
            ProductCategoryPicture = "ProductCategoryPicture",
            ProductCategoryActive = "ProductCategoryActive",
            CostPriceList = "CostPriceList",
            SalePriceList = "SalePriceList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
