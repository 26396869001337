namespace Point.Products {
    export interface ProductTransferRow {
        TransferId?: number;
        TransferNo?: string;
        TransferDate?: string;
        WarehouseFromId?: number;
        WarehouseFromName?: string;
        WarehouseToId?: number;
        WarehouseToName?: string;
        ProductId?: number;
        ProductName?: string;
        Qty?: number;
        Status?: number;
        Verified?: boolean;
        VerifiedBy?: number;
        VerifiedOn?: string;
        Locked?: boolean;
        Notes?: string;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace ProductTransferRow {
        export const idProperty = 'TransferId';
        export const nameProperty = 'TransferNo';
        export const localTextPrefix = 'Products.ProductTransfer';
        export const deletePermission = 'Products:Product Transfer';
        export const insertPermission = 'Products:Product Transfer';
        export const readPermission = 'Products:Product Transfer';
        export const updatePermission = 'Products:Product Transfer';

        export declare const enum Fields {
            TransferId = "TransferId",
            TransferNo = "TransferNo",
            TransferDate = "TransferDate",
            WarehouseFromId = "WarehouseFromId",
            WarehouseFromName = "WarehouseFromName",
            WarehouseToId = "WarehouseToId",
            WarehouseToName = "WarehouseToName",
            ProductId = "ProductId",
            ProductName = "ProductName",
            Qty = "Qty",
            Status = "Status",
            Verified = "Verified",
            VerifiedBy = "VerifiedBy",
            VerifiedOn = "VerifiedOn",
            Locked = "Locked",
            Notes = "Notes",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
