namespace Point.Meeting {
    export interface MeetingLocationRow {
        LocationId?: number;
        Name?: string;
        Address?: string;
        Latitude?: number;
        Longitude?: number;
    }

    export namespace MeetingLocationRow {
        export const idProperty = 'LocationId';
        export const nameProperty = 'Name';
        export const localTextPrefix = 'Meeting.MeetingLocation';
        export const lookupKey = 'Meeting.MeetingLocation';

        export function getLookup(): Q.Lookup<MeetingLocationRow> {
            return Q.getLookup<MeetingLocationRow>('Meeting.MeetingLocation');
        }
        export const deletePermission = 'Meeting:Management';
        export const insertPermission = 'Meeting:Management';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:Management';

        export declare const enum Fields {
            LocationId = "LocationId",
            Name = "Name",
            Address = "Address",
            Latitude = "Latitude",
            Longitude = "Longitude"
        }
    }
}
