namespace Point.Services {
    export interface ServiceTypesForm {
        ServiceTypeName: Serenity.StringEditor;
        UnitPrice: Serenity.DecimalEditor;
        Tax: Serenity.DecimalEditor;
        SaleAccount: Serenity.StringEditor;
        CostAccount: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class ServiceTypesForm extends Serenity.PrefixedContext {
        static formKey = 'Services.ServiceTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ServiceTypesForm.init)  {
                ServiceTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DecimalEditor;
                var w2 = s.DateEditor;

                Q.initFormType(ServiceTypesForm, [
                    'ServiceTypeName', w0,
                    'UnitPrice', w1,
                    'Tax', w1,
                    'SaleAccount', w0,
                    'CostAccount', w0,
                    'InsertName', w0,
                    'InsertDate', w2,
                    'UpdateName', w0,
                    'UpdateDate', w2
                ]);
            }
        }
    }
}
