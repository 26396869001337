namespace Point.Purchases {
    export interface PurchaseInvoiceDetailsRow {
        PurchaseInvoiceDetailID?: number;
        PurchaseInvoiceID?: number;
        UnitPrice?: number;
        UnitCode?: string;
        Qty?: number;
        ProductName?: string;
        DiscountPers?: number;
        Discount?: number;
        TaxDetailId?: number;
        TaxDetailName?: string;
        Tax?: number;
        Total?: number;
        ProductID?: number;
        WarehouseId?: number;
    }

    export namespace PurchaseInvoiceDetailsRow {
        export const idProperty = 'PurchaseInvoiceDetailID';
        export const localTextPrefix = 'Purchases.PurchaseInvoiceDetails';
        export const deletePermission = 'Purchases:PurchaseInvoiceDetails';
        export const insertPermission = 'Purchases:PurchaseInvoiceDetails';
        export const readPermission = 'Purchases:PurchaseInvoiceDetails';
        export const updatePermission = 'Purchases:PurchaseInvoiceDetails';

        export declare const enum Fields {
            PurchaseInvoiceDetailID = "PurchaseInvoiceDetailID",
            PurchaseInvoiceID = "PurchaseInvoiceID",
            UnitPrice = "UnitPrice",
            UnitCode = "UnitCode",
            Qty = "Qty",
            ProductName = "ProductName",
            DiscountPers = "DiscountPers",
            Discount = "Discount",
            TaxDetailId = "TaxDetailId",
            TaxDetailName = "TaxDetailName",
            Tax = "Tax",
            Total = "Total",
            ProductID = "ProductID",
            WarehouseId = "WarehouseId"
        }
    }
}
