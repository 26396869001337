namespace Point.Products {
    export interface ProductsForm {
        ProductCode: Serenity.StringEditor;
        ProductName: Serenity.StringEditor;
        Barcode: Serenity.StringEditor;
        BrandId: Serenity.LookupEditor;
        SupplierId: Serenity.LookupEditor;
        ProductCategoryId: Serenity.LookupEditor;
        ProductImage: Serenity.ImageUploadEditor;
        StockType: Serenity.EnumEditor;
        RegularPrice: Serenity.DecimalEditor;
        SalePrice: Serenity.DecimalEditor;
        UnitId: Serenity.LookupEditor;
        QuantityPerUnit: Serenity.StringEditor;
        ReorderLevel: Serenity.IntegerEditor;
        WarehouseId: Serenity.LookupEditor;
        TaxGroupId: Serenity.LookupEditor;
        CostPrice: Serenity.DecimalEditor;
        UnitsInStock: Serenity.IntegerEditor;
        UnitsOnOrder: Serenity.IntegerEditor;
        Discontinued: Serenity.BooleanEditor;
        SaleAccount: Serenity.StringEditor;
        CostAccount: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
        SalePriceList: ProductSalePricesEditor;
        CostPriceList: ProductCostPricesEditor;
    }

    export class ProductsForm extends Serenity.PrefixedContext {
        static formKey = 'Products.Products';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ProductsForm.init)  {
                ProductsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.ImageUploadEditor;
                var w3 = s.EnumEditor;
                var w4 = s.DecimalEditor;
                var w5 = s.IntegerEditor;
                var w6 = s.BooleanEditor;
                var w7 = s.DateEditor;
                var w8 = ProductSalePricesEditor;
                var w9 = ProductCostPricesEditor;

                Q.initFormType(ProductsForm, [
                    'ProductCode', w0,
                    'ProductName', w0,
                    'Barcode', w0,
                    'BrandId', w1,
                    'SupplierId', w1,
                    'ProductCategoryId', w1,
                    'ProductImage', w2,
                    'StockType', w3,
                    'RegularPrice', w4,
                    'SalePrice', w4,
                    'UnitId', w1,
                    'QuantityPerUnit', w0,
                    'ReorderLevel', w5,
                    'WarehouseId', w1,
                    'TaxGroupId', w1,
                    'CostPrice', w4,
                    'UnitsInStock', w5,
                    'UnitsOnOrder', w5,
                    'Discontinued', w6,
                    'SaleAccount', w0,
                    'CostAccount', w0,
                    'InsertName', w0,
                    'InsertDate', w7,
                    'UpdateName', w0,
                    'UpdateDate', w7,
                    'SalePriceList', w8,
                    'CostPriceList', w9
                ]);
            }
        }
    }
}
