namespace Point.CMS {
    export interface PagesRow {
        PageId?: number;
        PageName?: string;
        ParentId?: number;
        Keywords?: string;
        Description?: string;
        PageOrder?: number;
        Active?: boolean;
    }

    export namespace PagesRow {
        export const idProperty = 'PageId';
        export const nameProperty = 'PageName';
        export const localTextPrefix = 'CMS.Pages';
        export const deletePermission = 'CMS:Pages';
        export const insertPermission = 'CMS:Pages';
        export const readPermission = 'CMS:Pages';
        export const updatePermission = 'CMS:Pages';

        export declare const enum Fields {
            PageId = "PageId",
            PageName = "PageName",
            ParentId = "ParentId",
            Keywords = "Keywords",
            Description = "Description",
            PageOrder = "PageOrder",
            Active = "Active"
        }
    }
}
