namespace Point.HRM {
    export interface HolidaysForm {
        Title: Serenity.StringEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
    }

    export class HolidaysForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.Holidays';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!HolidaysForm.init)  {
                HolidaysForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;

                Q.initFormType(HolidaysForm, [
                    'Title', w0,
                    'StartDate', w1,
                    'EndDate', w1
                ]);
            }
        }
    }
}
