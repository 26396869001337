
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class AccountSettingsDialog extends Serenity.EntityDialog<AccountSettingsRow, any> {
        protected getFormKey() { return AccountSettingsForm.formKey; }
        protected getIdProperty() { return AccountSettingsRow.idProperty; }
        protected getLocalTextPrefix() { return AccountSettingsRow.localTextPrefix; }
        protected getService() { return AccountSettingsService.baseUrl; }
        protected getDeletePermission() { return AccountSettingsRow.deletePermission; }
        protected getInsertPermission() { return AccountSettingsRow.insertPermission; }
        protected getUpdatePermission() { return AccountSettingsRow.updatePermission; }

        protected form = new AccountSettingsForm(this.idPrefix);

        protected updateInterface() {
            super.updateInterface();

            //   this.form.Phone.value = "12112";
            this.deleteButton.hide();
            this.applyChangesButton.hide();
        }
        protected onSaveSuccess(response) {
            this.showSaveSuccessMessage(response);
        }
    }
}