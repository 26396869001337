
namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class MastersDialog extends Serenity.EntityDialog<MastersRow, any> {
        protected getFormKey() { return MastersForm.formKey; }
        protected getIdProperty() { return MastersRow.idProperty; }
        protected getLocalTextPrefix() { return MastersRow.localTextPrefix; }
        protected getService() { return MastersService.baseUrl; }
        protected getDeletePermission() { return MastersRow.deletePermission; }
        protected getInsertPermission() { return MastersRow.insertPermission; }
        protected getUpdatePermission() { return MastersRow.updatePermission; }

        protected form = new MastersForm(this.idPrefix);

    }
}