namespace Point.HRM {
    export interface EmployeeLeaveCreditsRow {
        EmployeeLeaveCreditID?: number;
        EmployeeID?: number;
        TransactionDate?: string;
        LeaveType?: number;
        Debit?: number;
        Credit?: number;
        Notes?: string;
        EmployeeLeaveID?: number;
    }

    export namespace EmployeeLeaveCreditsRow {
        export const idProperty = 'EmployeeLeaveCreditID';
        export const localTextPrefix = 'HRM.EmployeeLeaveCredits';
        export const deletePermission = 'HRM:EmployeeLeaveCredits';
        export const insertPermission = 'HRM:EmployeeLeaveCredits';
        export const readPermission = 'HRM:EmployeeLeaveCredits';
        export const updatePermission = 'HRM:EmployeeLeaveCredits';

        export declare const enum Fields {
            EmployeeLeaveCreditID = "EmployeeLeaveCreditID",
            EmployeeID = "EmployeeID",
            TransactionDate = "TransactionDate",
            LeaveType = "LeaveType",
            Debit = "Debit",
            Credit = "Credit",
            Notes = "Notes",
            EmployeeLeaveID = "EmployeeLeaveID"
        }
    }
}
