
namespace Point.Administration {

    @Serenity.Decorators.registerClass()
    export class OrganizationsDialog extends Serenity.EntityDialog<OrganizationsRow, any> {
        protected getFormKey() { return OrganizationsForm.formKey; }
        protected getIdProperty() { return OrganizationsRow.idProperty; }
        protected getLocalTextPrefix() { return OrganizationsRow.localTextPrefix; }
        protected getNameProperty() { return OrganizationsRow.nameProperty; }
        protected getService() { return OrganizationsService.baseUrl; }
        protected getDeletePermission() { return OrganizationsRow.deletePermission; }
        protected getInsertPermission() { return OrganizationsRow.insertPermission; }
        protected getUpdatePermission() { return OrganizationsRow.updatePermission; }

        protected form = new OrganizationsForm(this.idPrefix);

        protected updateInterface() {
            super.updateInterface();

            //   this.form.Phone.value = "12112";
            this.deleteButton.hide();
            this.applyChangesButton.hide();
        }

        protected onSaveSuccess(response) {
            this.showSaveSuccessMessage(response);
        }

    }
}