namespace Point.Default {
    export namespace DashboardWidgetSystemPermissionKeys {

        namespace DashboardWidgetSystem {
            export const UserDashboardView = "DashboardWidgetSystem:UserDashboardView";
            export const UserDashboardModify = "DashboardWidgetSystem:UserDashboardModify";
            export const ShareDashboard_View = "DashboardWidgetSystem:ShareDashboard_View";
            export const ShareDashboard_Modify = "DashboardWidgetSystem:ShareDashboard_Modify";
            export const PendingOrders = "DashboardWidgetSystem:PendingOrdersView";
            export const PendingOrdersModify = "DashboardWidgetSystem:PendingOrdersModify";
            export const PendingOrderList = "DashboardWidgetSystem:PendingOrderListView";
            export const PendingOrderListModify = "DashboardWidgetSystem:PendingOrderListModify";
            export const QuickButtons = "DashboardWidgetSystem:QuickButtons";
            export const QuickButtonsModify = "DashboardWidgetSystem:QuickButtonsModify";
            export const AccountWatch = "DashboardWidgetSystem:AccountWatch ";
            export const AccountWatchModify = "DashboardWidgetSystem:AccountWatchModify";
            export const CashInHand = "DashboardWidgetSystem:CashInHand";
            export const CashInHandModify = "DashboardWidgetSystem:CashInHandModify";
            export const CashInBank = "DashboardWidgetSystem:CashInBank";
            export const CashInBankModify = "DashboardWidgetSystem:CashInBankModify";
            export const NewContacts = "DashboardWidgetSystem:NewContacts";
            export const NewContactsModify = "DashboardWidgetSystem:NewContactsModify";
            export const SaleGraph = "DashboardWidgetSystem:SaleGraph";
            export const SaleGraphModify = "DashboardWidgetSystem:SaleGraphModify";
        }
    }
}
