namespace Point.BusinessPartners {
    export interface CountriesRow {
        CountryId?: number;
        Name?: string;
        Active?: boolean;
    }

    export namespace CountriesRow {
        export const idProperty = 'CountryId';
        export const nameProperty = 'Name';
        export const localTextPrefix = 'BusinessPartners.Countries';
        export const lookupKey = 'BusinessPartners.Countries';

        export function getLookup(): Q.Lookup<CountriesRow> {
            return Q.getLookup<CountriesRow>('BusinessPartners.Countries');
        }
        export const deletePermission = 'Business Partners:Countries';
        export const insertPermission = 'Business Partners:Countries';
        export const readPermission = 'Business Partners:Countries';
        export const updatePermission = 'Business Partners:Countries';

        export declare const enum Fields {
            CountryId = "CountryId",
            Name = "Name",
            Active = "Active"
        }
    }
}
