namespace Point.RealEstate {
    export interface BookingPlansRow {
        BookingPlanID?: number;
        BookingID?: number;
        InstallmentNo?: number;
        SNO?: number;
        DueDate?: string;
        AmountDue?: number;
        ReceiptDate?: string;
        AmountReceived?: number;
        Attachment?: string;
        Title?: string;
    }

    export namespace BookingPlansRow {
        export const idProperty = 'BookingPlanID';
        export const localTextPrefix = 'RealEstate.BookingPlans';
        export const deletePermission = 'RealEstate:BookingPlans';
        export const insertPermission = 'RealEstate:BookingPlans';
        export const readPermission = 'RealEstate:BookingPlans';
        export const updatePermission = 'RealEstate:BookingPlans';

        export declare const enum Fields {
            BookingPlanID = "BookingPlanID",
            BookingID = "BookingID",
            InstallmentNo = "InstallmentNo",
            SNO = "SNO",
            DueDate = "DueDate",
            AmountDue = "AmountDue",
            ReceiptDate = "ReceiptDate",
            AmountReceived = "AmountReceived",
            Attachment = "Attachment",
            Title = "Title"
        }
    }
}
