namespace Point.Default {
    import Jdw = J.DashboardWidget;


    export class PendingOrderListSettingParams {
        Title?: string;
        RefreshIntervalMiliseconds?: number;
    }

    export class PendingOrderList extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<PendingOrderListSettingParams>> {
        protected getContentSettingDialogType() { return PendingOrderListSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:PendingOrderListView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:PendingOrderListModify"; }

        constructor(opt: Jdw.BaseDashboardWidgetOptions<PendingOrderListSettingParams>) {
            super(opt);
        }

        protected buildWidgetData(widgetContentParams: PendingOrderListSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams); // optional 

            this.refreshIntervalMiliseconds = Q.toId(widgetContentParams.RefreshIntervalMiliseconds || 60000);

            this.byId("moreInfoLink").attr("href", "/Northwind/Order");


            //var Product = Point.Products.ProductsRow.getLookup().itemById[8];
            //let id = Product.RegularPrice;

            this.byId("title").text(widgetContentParams.Title);

            return Point.Sales.SaleOrdersService.GetOrders({
               
            }, response => {

                    for (var i = 0; i < response.length; i++) {

                        this.byId("id" + i.toString()).text(response[i].OrderNo.toString());
                        this.byId("contact" + i.toString()).text(response[i].CustomerName.toString());

                      //  this.byId("status" + i.toString()).text(response[i].Status.toString());
                        
                        //this.byId("status" + i.toString()).attr("class", "label label-info");




                        this.byId("id" + i.toString()).attr("href", "Sales/SaleOrders#edit/" + response[i].SaleOrderId.toString());




                        if (response[i].DueDate != undefined)
                            this.byId("duedate" + i.toString()).text(response[i].DueDate.toString());




                    }

            });

           


           //return $.get(`https://jsonplaceholder.typicode.com/todos/${id}`, (data) => {


           //     this.byId("id").text(data.id);
           //    // this.byId("title").text(`Pending Order List`);
           // }).then(() => { });
        }

        protected canShowMaximizeToolbarButton() {
            return true;
        }

        protected canShowSettingButton() {
            return true;
        }

        protected getWidgetTitle() {
            return "Pending Order List";
        }

        
    }
}