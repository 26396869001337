namespace Point.Sales {
    export interface SaleOrderDetailsRow {
        SaleOrderDetailID?: number;
        SaleOrderID?: number;
        UnitPrice?: number;
        UnitCode?: string;
        Qty?: number;
        ProductName?: string;
        DiscountPers?: number;
        Discount?: number;
        TaxDetailId?: number;
        TaxDetailName?: string;
        Tax?: number;
        Total?: number;
        ProductID?: number;
        WarehouseId?: number;
    }

    export namespace SaleOrderDetailsRow {
        export const idProperty = 'SaleOrderDetailID';
        export const localTextPrefix = 'Sales.SaleOrderDetails';
        export const deletePermission = 'Sales:SaleOrderDetails';
        export const insertPermission = 'Sales:SaleOrderDetails';
        export const readPermission = 'Sales:SaleOrderDetails';
        export const updatePermission = 'Sales:SaleOrderDetails';

        export declare const enum Fields {
            SaleOrderDetailID = "SaleOrderDetailID",
            SaleOrderID = "SaleOrderID",
            UnitPrice = "UnitPrice",
            UnitCode = "UnitCode",
            Qty = "Qty",
            ProductName = "ProductName",
            DiscountPers = "DiscountPers",
            Discount = "Discount",
            TaxDetailId = "TaxDetailId",
            TaxDetailName = "TaxDetailName",
            Tax = "Tax",
            Total = "Total",
            ProductID = "ProductID",
            WarehouseId = "WarehouseId"
        }
    }
}
