
namespace Point.Accounts {


    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class AccountsDialog extends Serenity.EntityDialog<AccountsRow, any> {
        protected getFormKey() { return AccountsForm.formKey; }
        protected getIdProperty() { return AccountsRow.idProperty; }
        protected getLocalTextPrefix() { return AccountsRow.localTextPrefix; }
        protected getNameProperty() { return AccountsRow.nameProperty; }
        protected getService() { return AccountsService.baseUrl; }
        protected getDeletePermission() { return AccountsRow.deletePermission; }
        protected getInsertPermission() { return AccountsRow.insertPermission; }
        protected getUpdatePermission() { return AccountsRow.updatePermission; }

        protected form = new AccountsForm(this.idPrefix);
        constructor() {
            super();
            this.form = new AccountsForm(this.idPrefix);


            this.form.AccountTitle.element.attr("tabindex", 1);
            this.setTabIndexSelect2(this.form.ParentId.element, 2);
            this.form.IsGl.element.attr("tabindex", 3);
            this.form.AllowTransactions.element.attr("tabindex", 4);
            //https://github.com/volkanceylan/Serenity/issues/3935
            this.form.ParentId.changeSelect2(e => {

                this.UpdateAccountfields();
            });

            this.form.AccountTitle.change(e => {

                this.UpdateAccountfields();

            });
        }
        private UpdateAccountfields() {

            var i = 1;

            if (this.isNew) {
                var parentHead = Q.toId(this.form.ParentId.value);
                this.form.HeadType.value = 'A'

                var accountsRows = Point.Accounts.AccountsRow.getLookup().items;

               
                if (parentHead != null && this.form.AccountCode.value==null) {
                    //alert(JSON.stringify(SmartERP.Accounts.AccountsRow.getLookup().itemById[parentHead]));
                    var parentRow = Point.Accounts.AccountsRow.getLookup().itemById[parentHead];

                    if (parentRow.HeadLevel == 4) {

                        alert("You can add upto 4 level chart of Accounts!");
                        return;
                    }


                    this.form.HeadLevel.value = parentRow.HeadLevel + 1;
                    var headCode = parentRow.AccountCode;
                    if (parentRow.HeadLevel <= 2)
                        headCode = headCode * 100;
                    else
                        headCode = headCode * 10000
                    
                    //this.form.PHeadName.value = parentRow.AccountTitle;

                    for (var i = 0; i < accountsRows.length; i++) {
                       // if (accountsRows[i].HeadLevel == parentRow.HeadLevel + 1) {
                        if (accountsRows[i].ParentId!=null)
                            if (accountsRows[i].ParentId.toString() == parentRow.AccountId.toString()) {
                                if (accountsRows[i].AccountCode > headCode) {

                                    headCode = accountsRows[i].AccountCode;
                                }
                            }
                       // }
                    }
                    headCode++;
                    this.form.AccountCode.value = headCode
                }
                // For top level 1
                //else {
                //    this.form.HeadLevel.value = 1;
                //    //this.form.HeadCode.value = '1';
                //    var headCode = 0;
                //   // this.form.PHeadName.value = 'COA';
                //    for (var i = 0; i < accountsRows.length; i++) {
                //        if (accountsRows[i].HeadLevel == 1) {
                //            if (accountsRows[i].AccountCode > headCode) {
                //                headCode = accountsRows[i].AccountCode;
                //            }
                //        }
                //    }
                //    headCode++;
                //    this.form.AccountCode.value = headCode

                //}
            }


        }
        private setTabIndexSelect2(element: JQuery, tabIndex: number) {
            element.prev("div.select2-container").find("input").attr("tabindex", tabIndex);
        }
        public dialogOpen(asPanel?: boolean): void {
            super.dialogOpen(asPanel)

            this.form.AccountTitle.element.focus();
        }

    }
}