namespace Point.TTS {
    export interface TaskTypesRow {
        TaskTypeId?: number;
        TaskType?: string;
        DefaultUserId?: number;
        Active?: boolean;
        WebEnabled?: boolean;
        DefaultUserUsername?: string;
        DefaultUserDisplayName?: string;
        DefaultUserEmail?: string;
        DefaultUserSource?: string;
        DefaultUserLastDirectoryUpdate?: string;
        DefaultUserUserImage?: string;
        DefaultUserInsertDate?: string;
        DefaultUserInsertUserId?: number;
        DefaultUserUpdateDate?: string;
        DefaultUserUpdateUserId?: number;
        DefaultUserIsActive?: number;
        DefaultUserMobilePhoneNumber?: string;
        DefaultUserMobilePhoneVerified?: boolean;
        DefaultUserTwoFactorAuth?: number;
    }

    export namespace TaskTypesRow {
        export const idProperty = 'TaskTypeId';
        export const nameProperty = 'TaskType';
        export const localTextPrefix = 'TTS.TaskTypes';
        export const lookupKey = 'TTS.TaskTypes';

        export function getLookup(): Q.Lookup<TaskTypesRow> {
            return Q.getLookup<TaskTypesRow>('TTS.TaskTypes');
        }
        export const deletePermission = 'TTS:Task Types';
        export const insertPermission = 'TTS:Task Types';
        export const readPermission = 'TTS:Task Types';
        export const updatePermission = 'TTS:Task Types';

        export declare const enum Fields {
            TaskTypeId = "TaskTypeId",
            TaskType = "TaskType",
            DefaultUserId = "DefaultUserId",
            Active = "Active",
            WebEnabled = "WebEnabled",
            DefaultUserUsername = "DefaultUserUsername",
            DefaultUserDisplayName = "DefaultUserDisplayName",
            DefaultUserEmail = "DefaultUserEmail",
            DefaultUserSource = "DefaultUserSource",
            DefaultUserLastDirectoryUpdate = "DefaultUserLastDirectoryUpdate",
            DefaultUserUserImage = "DefaultUserUserImage",
            DefaultUserInsertDate = "DefaultUserInsertDate",
            DefaultUserInsertUserId = "DefaultUserInsertUserId",
            DefaultUserUpdateDate = "DefaultUserUpdateDate",
            DefaultUserUpdateUserId = "DefaultUserUpdateUserId",
            DefaultUserIsActive = "DefaultUserIsActive",
            DefaultUserMobilePhoneNumber = "DefaultUserMobilePhoneNumber",
            DefaultUserMobilePhoneVerified = "DefaultUserMobilePhoneVerified",
            DefaultUserTwoFactorAuth = "DefaultUserTwoFactorAuth"
        }
    }
}
