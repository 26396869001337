
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class TaxGroupsDialog extends Serenity.EntityDialog<TaxGroupsRow, any> {
        protected getFormKey() { return TaxGroupsForm.formKey; }
        protected getIdProperty() { return TaxGroupsRow.idProperty; }
        protected getLocalTextPrefix() { return TaxGroupsRow.localTextPrefix; }
        protected getNameProperty() { return TaxGroupsRow.nameProperty; }
        protected getService() { return TaxGroupsService.baseUrl; }
        protected getDeletePermission() { return TaxGroupsRow.deletePermission; }
        protected getInsertPermission() { return TaxGroupsRow.insertPermission; }
        protected getUpdatePermission() { return TaxGroupsRow.updatePermission; }

        protected form = new TaxGroupsForm(this.idPrefix);

    }
}