
namespace Point.BusinessPartners {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.filterable()
    export class CustomersGrid extends Serenity.EntityGrid<Point.BusinessPartners.CustomersRow, any> {
        protected getColumnsKey() { return 'BusinessPartners.Customers'; }
        protected getDialogType() { return CustomersDialog; }
        protected getIdProperty() { return CustomersRow.idProperty; }
        protected getInsertPermission() { return CustomersRow.insertPermission; }
        protected getLocalTextPrefix() { return CustomersRow.localTextPrefix; }
        protected getService() { return CustomersService.baseUrl; }

        
        constructor(container: JQuery) {
            super(container);
        //    Point.Common.EnableFullSizeGrid();
        }

        

        protected getColumns() {
            var columns = super.getColumns();

            //columns.unshift({
            //    field: 'Delete Row',
            //    name: '',
            //    format: ctx => '<a class="inline-action delete-row" title="delete">' +
            //        '<i class="fa fa-trash-o text-red"></i></a>',
            //    width: 24,
            //    minWidth: 24,
            //    maxWidth: 24
            //});

            columns.splice(0, 0, {
                field: 'View Details',
                name: '',
                format: ctx => ' <a class="inline-action view-details" title="View details"><i class="fa fa-pencil-square-o text-blue" aria-hidden="true"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });

            //columns.splice(2, 0, {
            //    field: 'New Order',
            //    name: '',
            //    format: ctx => '<a class="inline-action new-order" title="new order"></a>',
            //    width: 24,
            //    minWidth: 24,
            //    maxWidth: 24
            //});

            return columns;
        }

        protected onClick(e: JQueryEventObject, row: number, cell: number) {
            super.onClick(e, row, cell);

            if (e.isDefaultPrevented())
                return;

            var item = this.itemAt(row);
            var target = $(e.target);

            // if user clicks "i" element, e.g. icon
            if (target.parent().hasClass('inline-action'))
                target = target.parent();

            if (target.hasClass('inline-action')) {
                e.preventDefault();

                //if (target.hasClass('delete-row')) {
                //    Q.confirm('Delete record?', () => {
                //        Northwind.CustomerService.Delete({
                //            EntityId: item.ID,
                //        }, response => {
                //            this.refresh();
                //        });
                //    });
                //}
                //else

                if (target.hasClass('view-details')) {
                    this.editItem(item.CustomerId);

                }
                //else if (target.hasClass('new-order')) {
                //    var dlg = new Northwind.OrderDialog();
                //    this.initDialog(dlg);
                //    dlg.loadEntityAndOpenDialog(<Northwind.OrderRow>{
                //        CustomerID: item.CustomerID
                //    });
                //}
            }
        }
       
        // For Auto Size
        //protected createSlickGrid() {
        //    this.slickGrid = super.createSlickGrid();

        //    new Serenity.AutoColumnWidthMixin({
        //        grid: this
        //    });

        //    return this.slickGrid;
        //}


        // For Page Size
        ////  * We change number of rows for demonstration purposes, its not normally needed 
        //protected getViewOptions() {
        //    var opt = super.getViewOptions();
        //    opt.rowsPerPage = 2;
        //    return opt;
        //}
            
        protected createToolbarExtensions() {
            super.createToolbarExtensions();

            var self = this;

            // Faviour View
            new Serenity.FavoriteViewsMixin({
                grid: this
            });


            // Excel like column filtering
            new Serenity.HeaderFiltersMixin({
                grid: this
            });


          
        }

        protected getPersistanceStorage(): Serenity.SettingStorage {
            return new Common.UserPreferenceStorage();
        }
    }
   
}