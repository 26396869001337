namespace Point.Accounts {
    export interface CurrencyTypesRow {
        CurrencyTypeId?: number;
        CurrencyTypeName?: string;
    }

    export namespace CurrencyTypesRow {
        export const idProperty = 'CurrencyTypeId';
        export const nameProperty = 'CurrencyTypeName';
        export const localTextPrefix = 'Accounts.CurrencyTypes';
        export const lookupKey = 'Accounts.CurrencyTypes';

        export function getLookup(): Q.Lookup<CurrencyTypesRow> {
            return Q.getLookup<CurrencyTypesRow>('Accounts.CurrencyTypes');
        }
        export const deletePermission = 'Accounts:CurrencyTypes';
        export const insertPermission = 'Accounts:CurrencyTypes';
        export const readPermission = 'Accounts:CurrencyTypes';
        export const updatePermission = 'Accounts:CurrencyTypes';

        export declare const enum Fields {
            CurrencyTypeId = "CurrencyTypeId",
            CurrencyTypeName = "CurrencyTypeName"
        }
    }
}
