/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeComplaintDialog extends Common.GridEditorDialog<EmployeeComplaintsRow> {
        protected getFormKey() { return EmployeeComplaintsForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeComplaintsRow.localTextPrefix; }

        protected form: EmployeeComplaintsForm;

        constructor() {
            super();

            this.form = new EmployeeComplaintsForm(this.idPrefix);

            this.form.ComplaintTypeId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var ComplaintTypeId = Q.toId(this.form.ComplaintTypeId.value);

                var ComplaintType = Point.HRM.ComplaintTypesRow.getLookup().itemById[ComplaintTypeId];


                this.form.ComplaintType.value = ComplaintType.ComplaintType




            });
         
            


        }


    
    }
}