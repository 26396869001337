namespace Point.BusinessPartners {
    export interface BanksForm {
        BankName: Serenity.StringEditor;
        Address: Serenity.StringEditor;
        Branch: Serenity.StringEditor;
        ContactName: Serenity.StringEditor;
        AccountNo: Serenity.StringEditor;
        PhoneNo: Serenity.StringEditor;
        AccountTitle: Serenity.StringEditor;
        CurrencyId: Serenity.LookupEditor;
        Account: Serenity.StringEditor;
        AllowTransactions: Serenity.BooleanEditor;
        ShowInReport: Serenity.BooleanEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class BanksForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.Banks';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!BanksForm.init)  {
                BanksForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.BooleanEditor;
                var w3 = s.DateEditor;

                Q.initFormType(BanksForm, [
                    'BankName', w0,
                    'Address', w0,
                    'Branch', w0,
                    'ContactName', w0,
                    'AccountNo', w0,
                    'PhoneNo', w0,
                    'AccountTitle', w0,
                    'CurrencyId', w1,
                    'Account', w0,
                    'AllowTransactions', w2,
                    'ShowInReport', w2,
                    'InsertName', w0,
                    'InsertDate', w3,
                    'UpdateName', w0,
                    'UpdateDate', w3
                ]);
            }
        }
    }
}
