namespace Point.BusinessPartners {
    export interface SupplierGroupsRow {
        SupplierGroupId?: number;
        SupplierGroupName?: string;
    }

    export namespace SupplierGroupsRow {
        export const idProperty = 'SupplierGroupId';
        export const nameProperty = 'SupplierGroupName';
        export const localTextPrefix = 'BusinessPartners.SupplierGroups';
        export const lookupKey = 'BusinessPartners.SupplierGroups';

        export function getLookup(): Q.Lookup<SupplierGroupsRow> {
            return Q.getLookup<SupplierGroupsRow>('BusinessPartners.SupplierGroups');
        }
        export const deletePermission = 'Business Partners:Supplier Groups';
        export const insertPermission = 'Business Partners:Supplier Groups';
        export const readPermission = 'Business Partners:Supplier Groups';
        export const updatePermission = 'Business Partners:Supplier Groups';

        export declare const enum Fields {
            SupplierGroupId = "SupplierGroupId",
            SupplierGroupName = "SupplierGroupName"
        }
    }
}
