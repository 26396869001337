namespace Point.Products {
    export interface ProductTransferForm {
        TransferNo: Serenity.StringEditor;
        TransferDate: Serenity.DateEditor;
        WarehouseFromId: Serenity.LookupEditor;
        WarehouseToId: Serenity.LookupEditor;
        ProductId: Serenity.LookupEditor;
        Qty: Serenity.DecimalEditor;
        Notes: Serenity.StringEditor;
        Status: Serenity.EnumEditor;
        Locked: Serenity.BooleanEditor;
        Verified: Serenity.BooleanEditor;
        VerifiedBy: Serenity.IntegerEditor;
        VerifiedOn: Serenity.DateEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class ProductTransferForm extends Serenity.PrefixedContext {
        static formKey = 'Products.ProductTransfer';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ProductTransferForm.init)  {
                ProductTransferForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;
                var w2 = s.LookupEditor;
                var w3 = s.DecimalEditor;
                var w4 = s.EnumEditor;
                var w5 = s.BooleanEditor;
                var w6 = s.IntegerEditor;

                Q.initFormType(ProductTransferForm, [
                    'TransferNo', w0,
                    'TransferDate', w1,
                    'WarehouseFromId', w2,
                    'WarehouseToId', w2,
                    'ProductId', w2,
                    'Qty', w3,
                    'Notes', w0,
                    'Status', w4,
                    'Locked', w5,
                    'Verified', w5,
                    'VerifiedBy', w6,
                    'VerifiedOn', w1,
                    'InsertName', w0,
                    'InsertDate', w1,
                    'UpdateName', w0,
                    'UpdateDate', w1
                ]);
            }
        }
    }
}
