/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class VoucherDetailDialog extends Common.GridEditorDialog<VoucherDetailsRow> {
   // export class VoucherDetailDialog extends Serenity.EntityGrid<VouchersRow, any> {
         
        protected getFormKey() { return VoucherDetailsForm.formKey; }
        protected getLocalTextPrefix() { return VoucherDetailsRow.localTextPrefix; }
    
        //protected getIdProperty() { return VoucherDetailsRow.idProperty; }
        //protected getInsertPermission() { return VoucherDetailsRow.insertPermission; }
        //protected getService() { return VoucherDetailService.baseUrl; }

        protected form: VoucherDetailsForm;

        constructor() {
            super();

            this.form = new VoucherDetailsForm(this.idPrefix);
             
            //this.form.Debit.addValidationRule(this.uniqueName, e=> {

            //    if (this.form.Debit.value > 100) {
       
            //        return "Unit price must be less than 100";
            //    }
            
            //});

            this.form.ExchangeRate.addValidationRule(this.uniqueName, e => {

                if (this.form.ExchangeRate.value == null ||
                    this.form.ExchangeRate.value ==0
                    ) {

                    return "Exchange Rate must not be blank/zero!";
                }

            });




            this.form.AccountID.changeSelect2(e => {

               // this.form.CurrencyID.clearItems();
  
                    var accountID = Q.toId(this.form.AccountID.value);

                var account = Point.Accounts.AccountsRow.getLookup().itemById[accountID];


                var curid = Q.toId(account.CurrencyId);

                this.form.CurrencyID.value = curid;


                this.form.CurrencyName.value = account.CurrencyName;
                this.form.AccountDisplay.value = account.AccountDisplay;

                var currency = Point.Accounts.CurrenciesRow.getLookup().itemById[curid];


              //  if (account.CurrencyId == 1) this.form.ExchangeRate.value = 1;


                var Organizationn = Point.Administration.OrganizationsRow.getLookup().itemById[1];
                if (currency.CurrencyId == Organizationn.CurrencyId) {
                    this.form.ExchangeRate.value = 1;

                    Serenity.EditorUtils.setReadOnly(this.form.ExchangeRate, true);
                    //this.form.ExchangeRate.element.attr("ReadOnly", "true");
                    //this.form.ExchangeRate.element.css("background-color", "#F4F4F4");
                }
                else {
                    this.form.ExchangeRate.value = 1;

                    Serenity.EditorUtils.setReadOnly(this.form.ExchangeRate, false);
                }


               

             

                // this.form.CurrencyID.addOption(curid, account.AccountTitle);

                // this.form.CurrencyID.props.value = "1";


                // this.form.CurrencyID.
                // this.form.PlanId.addOption("1", "ABC");



            });

         


        }
    }
}