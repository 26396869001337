namespace Point.Services {
    export interface ServiceTypesRow {
        ServiceTypeId?: number;
        ServiceTypeName?: string;
        UnitPrice?: number;
        Tax?: number;
        SaleAccount?: string;
        CostAccount?: string;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace ServiceTypesRow {
        export const idProperty = 'ServiceTypeId';
        export const nameProperty = 'ServiceTypeName';
        export const localTextPrefix = 'Services.ServiceTypes';
        export const lookupKey = 'Services.ServiceTypes';

        export function getLookup(): Q.Lookup<ServiceTypesRow> {
            return Q.getLookup<ServiceTypesRow>('Services.ServiceTypes');
        }
        export const deletePermission = 'Services:ServiceTypes';
        export const insertPermission = 'Services:ServiceTypes';
        export const readPermission = 'Services:ServiceTypes';
        export const updatePermission = 'Services:ServiceTypes';

        export declare const enum Fields {
            ServiceTypeId = "ServiceTypeId",
            ServiceTypeName = "ServiceTypeName",
            UnitPrice = "UnitPrice",
            Tax = "Tax",
            SaleAccount = "SaleAccount",
            CostAccount = "CostAccount",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
