/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class MasterDetailDialog extends Common.GridEditorDialog<MasterDetailsRow> {
        protected getFormKey() { return MasterDetailsForm.formKey; }
        protected getLocalTextPrefix() { return MasterDetailsRow.localTextPrefix; }

        protected form: MasterDetailsForm;

        constructor() {
            super();

            this.form = new MasterDetailsForm(this.idPrefix);

          
            this.form.UnitPrice.addValidationRule(this.uniqueName, e=> {

                if (this.form.UnitPrice.value > 100) {
       
                    return "Unit price must be less than 100";
                }
            
            });

        }
    }
}