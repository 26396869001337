namespace Point.Products {
    export interface ProductCategoriesRow {
        ProductCategoryId?: number;
        ProductCategoryName?: string;
        Description?: string;
        Picture?: string;
        Active?: boolean;
    }

    export namespace ProductCategoriesRow {
        export const idProperty = 'ProductCategoryId';
        export const nameProperty = 'ProductCategoryName';
        export const localTextPrefix = 'Products.ProductCategories';
        export const lookupKey = 'Products.ProductCategories';

        export function getLookup(): Q.Lookup<ProductCategoriesRow> {
            return Q.getLookup<ProductCategoriesRow>('Products.ProductCategories');
        }
        export const deletePermission = 'Products:ProductCategories';
        export const insertPermission = 'Products:ProductCategories';
        export const readPermission = 'Products:ProductCategories';
        export const updatePermission = 'Products:ProductCategories';

        export declare const enum Fields {
            ProductCategoryId = "ProductCategoryId",
            ProductCategoryName = "ProductCategoryName",
            Description = "Description",
            Picture = "Picture",
            Active = "Active"
        }
    }
}
