namespace Point.Default {
    export interface UserDashboardDataRow {
        DashboardKey?: string;
        UserId?: number;
        Data?: string;
    }

    export namespace UserDashboardDataRow {
        export const localTextPrefix = 'Default.UserDashboardData';
        export const deletePermission = 'Administration:General';
        export const insertPermission = 'Administration:General';
        export const readPermission = 'Administration:General';
        export const updatePermission = 'Administration:General';

        export declare const enum Fields {
            DashboardKey = "DashboardKey",
            UserId = "UserId",
            Data = "Data"
        }
    }
}
