namespace Point.Services {
    export interface ServiceDetailsForm {
        ServiceTypeID: Serenity.LookupEditor;
        ServiceTypeName: Serenity.StringEditor;
        RefNo: Serenity.StringEditor;
        Description: Serenity.StringEditor;
        UnitPrice: Serenity.DecimalEditor;
        Qty: Serenity.DecimalEditor;
        Discount: Serenity.DecimalEditor;
        Total: Serenity.DecimalEditor;
    }

    export class ServiceDetailsForm extends Serenity.PrefixedContext {
        static formKey = 'Services.ServiceDetail';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ServiceDetailsForm.init)  {
                ServiceDetailsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DecimalEditor;

                Q.initFormType(ServiceDetailsForm, [
                    'ServiceTypeID', w0,
                    'ServiceTypeName', w1,
                    'RefNo', w1,
                    'Description', w1,
                    'UnitPrice', w2,
                    'Qty', w2,
                    'Discount', w2,
                    'Total', w2
                ]);
            }
        }
    }
}
