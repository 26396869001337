


namespace Point.RealEstate {

    

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()

    export class BookingsDialog extends Serenity.EntityDialog<BookingsRow, any> {
        protected getFormKey() { return BookingsForm.formKey; }
        protected getIdProperty() { return BookingsRow.idProperty; }
        protected getLocalTextPrefix() { return BookingsRow.localTextPrefix; }
        protected getNameProperty() { return BookingsRow.nameProperty; }
        protected getService() { return BookingsService.baseUrl; }
        protected getDeletePermission() { return BookingsRow.deletePermission; }
        protected getInsertPermission() { return BookingsRow.insertPermission; }
        protected getUpdatePermission() { return BookingsRow.updatePermission; }

        protected form = new BookingsForm(this.idPrefix);

        constructor() {
            super();


            
      


            this.form.ProjectId.changeSelect2(e => {

                this.form.PlanId.clearItems();

                var planRows = Point.RealEstate.ProjectPlansRow.getLookup().items;

                for (var i = 0; i < planRows.length; i++) {

                    if (planRows[i].ProjectID.toString() == this.form.ProjectId.value.toString())
                        this.form.PlanId.addOption(planRows[i].PlanID.toString(), planRows[i].PlanName);

                }
                //this.form.PlanId.addOption("1", "ABC");

                

            });


            this.form.PlanId.changeSelect2(e => {

                var planID = Q.toId(this.form.PlanId.value);
                if (planID != null) {
                 var planRow = Point.RealEstate.ProjectPlansRow.getLookup().itemById[planID];

                 this.form.Membership.value = planRow.Membership;
                 this.form.Booking.value = planRow.Booking;
                 this.form.Confirmation.value = planRow.Confirmation;
                 this.form.OnPossion.value = planRow.OnPossion;
                 this.form.Processing.value = planRow.Processing;

                }


                //var planID = Q.toId(this.form.PlanId.value);

                //var fld = Point.RealEstate.ProjectPlansRow.Fields;
                //var planRow = new Point.RealEstate.ProjectPlansRow
                //new  SqlQuery().From(planRow)
                //    .Select(
                //        fld.Membership,
                //        fld.Booking)
                //    .Where(fld.PlanID == planID)
                //    //.GetFirst(this.Connection);



            });


            this.form.PaymentPlanId.changeSelect2(e => {

                var planID = Q.toId(this.form.PlanId.value);
                if (planID != null) {
                    var planRow = Point.RealEstate.ProjectPlansRow.getLookup().itemById[planID];

                    this.form.BookingNo.value = this.form.PaymentPlanId.text;
                    if (this.form.PaymentPlanId.text == "Monthly")
                        this.form.InstallmentAmount.value = planRow.Monthly

                    if (this.form.PaymentPlanId.text == "Quarterly")
                        this.form.InstallmentAmount.value = planRow.Quarterly

                    if (this.form.PaymentPlanId.text == "Half Yearly")
                        this.form.InstallmentAmount.value = planRow.HalfYearly

                    if (this.form.PaymentPlanId.text == "Yearly")
                        this.form.InstallmentAmount.value = planRow.Yearly


                    if (this.form.PaymentPlanId.text == "Lum Sum")
                        this.form.InstallmentAmount.value = planRow.LumsumPrice

                    //this.form.Membership.value = planRow.Membership;
                    //this.form.Booking.value = planRow.Booking;
                    //this.form.Confirmation.value = planRow.Confirmation;
                    //this.form.OnPossion.value = planRow.OnPossion;
                    //this.form.Processing.value = planRow.Processing;

                }


                //var planID = Q.toId(this.form.PlanId.value);

                //var fld = Point.RealEstate.ProjectPlansRow.Fields;
                //var planRow = new Point.RealEstate.ProjectPlansRow
                //new  SqlQuery().From(planRow)
                //    .Select(
                //        fld.Membership,
                //        fld.Booking)
                //    .Where(fld.PlanID == planID)
                //    //.GetFirst(this.Connection);



            });





        }


     
  
    }
}