namespace Point.TTS {
    export interface TaskTypesForm {
        TaskType: Serenity.StringEditor;
        DefaultUserId: Serenity.LookupEditor;
        Active: Serenity.BooleanEditor;
        WebEnabled: Serenity.BooleanEditor;
    }

    export class TaskTypesForm extends Serenity.PrefixedContext {
        static formKey = 'TTS.TaskTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TaskTypesForm.init)  {
                TaskTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(TaskTypesForm, [
                    'TaskType', w0,
                    'DefaultUserId', w1,
                    'Active', w2,
                    'WebEnabled', w2
                ]);
            }
        }
    }
}
