namespace Point.Default {
    export interface CashInHandSettingForm {
        Title: Serenity.StringEditor;
        RefreshIntervalMiliseconds: Serenity.EnumEditor;
        BoxColorClass: Serenity.EnumEditor;
    }

    export class CashInHandSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.CashInHandSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!CashInHandSettingForm.init)  {
                CashInHandSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;

                Q.initFormType(CashInHandSettingForm, [
                    'Title', w0,
                    'RefreshIntervalMiliseconds', w1,
                    'BoxColorClass', w1
                ]);
            }
        }
    }
}
