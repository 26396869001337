
namespace Point.TTS {

    @Serenity.Decorators.registerClass()
    export class TasksGrid extends Serenity.EntityGrid<TasksRow, any> {
        protected getColumnsKey() { return 'TTS.Tasks'; }
        protected getDialogType() { return TasksDialog; }
        protected getIdProperty() { return TasksRow.idProperty; }
        protected getInsertPermission() { return TasksRow.insertPermission; }
        protected getLocalTextPrefix() { return TasksRow.localTextPrefix; }
        protected getService() { return TasksService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }


    function sayHello() {
        const compiler = (document.getElementById("compiler") as HTMLInputElement)
            .value;
        const framework = (document.getElementById("framework") as HTMLInputElement)
            .value;
        return `Hello from ${compiler} and ${framework}!`;
    }
}