
namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class SaleReturnsGrid extends Serenity.EntityGrid<SaleReturnsRow, any> {
        protected getColumnsKey() { return 'Sales.SaleReturns'; }
        protected getDialogType() { return SaleReturnsDialog; }
        protected getIdProperty() { return SaleReturnsRow.idProperty; }
        protected getInsertPermission() { return SaleReturnsRow.insertPermission; }
        protected getLocalTextPrefix() { return SaleReturnsRow.localTextPrefix; }
        protected getService() { return SaleReturnsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}