namespace Point.HRM {
    export interface EmployeeSalariesRow {
        EmployeeSalaryID?: number;
        EmployeeID?: number;
        StartDate?: string;
        EndDate?: string;
        BasicSalary?: number;
        Transportation?: number;
        HouseRent?: number;
        Utilities?: number;
        Medical?: number;
        ProvidentFund?: number;
        LoanDeduction?: number;
        EOBI?: number;
        IncomeTax?: number;
        GrossSalary?: number;
        TotalDeductions?: number;
        NetSalary?: number;
    }

    export namespace EmployeeSalariesRow {
        export const idProperty = 'EmployeeSalaryID';
        export const localTextPrefix = 'HRM.EmployeeSalaries';
        export const lookupKey = 'HRM.EmployeeSalaries';

        export function getLookup(): Q.Lookup<EmployeeSalariesRow> {
            return Q.getLookup<EmployeeSalariesRow>('HRM.EmployeeSalaries');
        }
        export const deletePermission = 'HRM:EmployeeSalaries';
        export const insertPermission = 'HRM:EmployeeSalaries';
        export const readPermission = 'HRM:EmployeeSalaries';
        export const updatePermission = 'HRM:EmployeeSalaries';

        export declare const enum Fields {
            EmployeeSalaryID = "EmployeeSalaryID",
            EmployeeID = "EmployeeID",
            StartDate = "StartDate",
            EndDate = "EndDate",
            BasicSalary = "BasicSalary",
            Transportation = "Transportation",
            HouseRent = "HouseRent",
            Utilities = "Utilities",
            Medical = "Medical",
            ProvidentFund = "ProvidentFund",
            LoanDeduction = "LoanDeduction",
            EOBI = "EOBI",
            IncomeTax = "IncomeTax",
            GrossSalary = "GrossSalary",
            TotalDeductions = "TotalDeductions",
            NetSalary = "NetSalary"
        }
    }
}
