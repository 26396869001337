namespace Point.Accounts {
    export interface FinancialPeriodsForm {
        PeriodName: Serenity.StringEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        Active: Serenity.BooleanEditor;
    }

    export class FinancialPeriodsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.FinancialPeriods';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!FinancialPeriodsForm.init)  {
                FinancialPeriodsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(FinancialPeriodsForm, [
                    'PeriodName', w0,
                    'StartDate', w1,
                    'EndDate', w1,
                    'Active', w2
                ]);
            }
        }
    }
}
