
namespace Point.RealEstate {

    @Serenity.Decorators.registerClass()
    export class TypesDialog extends Serenity.EntityDialog<TypesRow, any> {
        protected getFormKey() { return TypesForm.formKey; }
        protected getIdProperty() { return TypesRow.idProperty; }
        protected getLocalTextPrefix() { return TypesRow.localTextPrefix; }
        protected getNameProperty() { return TypesRow.nameProperty; }
        protected getService() { return TypesService.baseUrl; }
        protected getDeletePermission() { return TypesRow.deletePermission; }
        protected getInsertPermission() { return TypesRow.insertPermission; }
        protected getUpdatePermission() { return TypesRow.updatePermission; }

        protected form = new TypesForm(this.idPrefix);

    }
}