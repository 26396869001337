namespace Point.Meeting {
    export interface MeetingAgendaRow {
        AgendaId?: number;
        MeetingId?: number;
        AgendaNumber?: number;
        Title?: string;
        Description?: string;
        AgendaTypeId?: number;
        RequestedByContactId?: number;
        Images?: string;
        Attachments?: string;
        MeetingMeetingName?: string;
        MeetingMeetingNumber?: string;
        MeetingMeetingGuid?: string;
        MeetingMeetingTypeId?: number;
        MeetingStartDate?: string;
        MeetingEndDate?: string;
        MeetingLocationId?: number;
        MeetingUnitId?: number;
        MeetingOrganizerContactId?: number;
        MeetingReporterContactId?: number;
        MeetingInsertUserId?: number;
        MeetingInsertDate?: string;
        MeetingUpdateUserId?: number;
        MeetingUpdateDate?: string;
        AgendaTypeName?: string;
        RequestedByContactFirstName?: string;
        RequestedByContactLastName?: string;
        RequestedByContactFullName?: string;
        RequestedByContactEmail?: string;
    }

    export namespace MeetingAgendaRow {
        export const idProperty = 'AgendaId';
        export const nameProperty = 'Title';
        export const localTextPrefix = 'Meeting.MeetingAgenda';
        export const deletePermission = 'Meeting:General';
        export const insertPermission = 'Meeting:General';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:General';

        export declare const enum Fields {
            AgendaId = "AgendaId",
            MeetingId = "MeetingId",
            AgendaNumber = "AgendaNumber",
            Title = "Title",
            Description = "Description",
            AgendaTypeId = "AgendaTypeId",
            RequestedByContactId = "RequestedByContactId",
            Images = "Images",
            Attachments = "Attachments",
            MeetingMeetingName = "MeetingMeetingName",
            MeetingMeetingNumber = "MeetingMeetingNumber",
            MeetingMeetingGuid = "MeetingMeetingGuid",
            MeetingMeetingTypeId = "MeetingMeetingTypeId",
            MeetingStartDate = "MeetingStartDate",
            MeetingEndDate = "MeetingEndDate",
            MeetingLocationId = "MeetingLocationId",
            MeetingUnitId = "MeetingUnitId",
            MeetingOrganizerContactId = "MeetingOrganizerContactId",
            MeetingReporterContactId = "MeetingReporterContactId",
            MeetingInsertUserId = "MeetingInsertUserId",
            MeetingInsertDate = "MeetingInsertDate",
            MeetingUpdateUserId = "MeetingUpdateUserId",
            MeetingUpdateDate = "MeetingUpdateDate",
            AgendaTypeName = "AgendaTypeName",
            RequestedByContactFirstName = "RequestedByContactFirstName",
            RequestedByContactLastName = "RequestedByContactLastName",
            RequestedByContactFullName = "RequestedByContactFullName",
            RequestedByContactEmail = "RequestedByContactEmail"
        }
    }
}
