/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Services {

    @Serenity.Decorators.registerClass()
    export class ServiceDetailDialog extends Common.GridEditorDialog<ServiceDetailsRow> {
        protected getFormKey() { return ServiceDetailsForm.formKey; }
        protected getLocalTextPrefix() { return ServiceDetailsRow.localTextPrefix; }

        protected form: ServiceDetailsForm;

        constructor() {
            super();

            this.form = new ServiceDetailsForm(this.idPrefix);

          
            this.form.UnitPrice.addValidationRule(this.uniqueName, e=> {

                if (this.form.UnitPrice.value > 100) {
       
                    return "Unit price must be less than 100";
                }
            
            });


            this.form.UnitPrice.change(e => {

                this.doCalculation();


            });


            this.form.Discount.change(e => {

                this.doCalculation();


            });



            this.form.Qty.change(e => {

                this.doCalculation();


            });
            

            this.form.ServiceTypeID.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();

                

                var serviceTypeID = Q.toId(this.form.ServiceTypeID.value);

                var serviceType = Point.Services.ServiceTypesRow.getLookup().itemById[serviceTypeID];

                this.form.Qty.value = 1;
                this.form.UnitPrice.value = serviceType.UnitPrice;
               
                this.form.ServiceTypeName.value = serviceType.ServiceTypeName;

                this.doCalculation();

               //// var curid = Q.toId(account.CurrencyId);

               //// this.form.CurrencyID.value = curid;


               // this.form.CurrencyName.value = account.CurrencyName;
               // this.form.AccountDisplay.value = account.AccountDisplay;

               // if (account.CurrencyId == 1) this.form.ExchangeRate.value = 1;


               // // this.form.CurrencyID.addOption(curid, account.AccountTitle);

               // // this.form.CurrencyID.props.value = "1";


               // // this.form.CurrencyID.
               // // this.form.PlanId.addOption("1", "ABC");



            });
        }

       
        private doCalculation() {
            var unitprice = 0;
            if (this.form.UnitPrice.value != null)
                unitprice = this.form.UnitPrice.value;

            var qty = 1;
            if (this.form.Qty.value != null)
                qty = this.form.Qty.value;

            var discount = 0;
            if (this.form.Discount.value != null)
                discount = this.form.Discount.value;


            this.form.Total.value = (unitprice * qty) - discount;
        }
    }
}