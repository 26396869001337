namespace Point.Accounts {
    export interface ExchangeRatesForm {
        CurrencyId: Serenity.LookupEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        Rate: Serenity.DecimalEditor;
    }

    export class ExchangeRatesForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.ExchangeRates';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ExchangeRatesForm.init)  {
                ExchangeRatesForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.DateEditor;
                var w2 = s.DecimalEditor;

                Q.initFormType(ExchangeRatesForm, [
                    'CurrencyId', w0,
                    'StartDate', w1,
                    'EndDate', w1,
                    'Rate', w2
                ]);
            }
        }
    }
}
