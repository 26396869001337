namespace Point {
    export enum eSaleOrder {
        Pending = 0,
        InProcess = 1,
        OnHold = 2,
        Completed = 3,
        Cancelled = 4
    }
    Serenity.Decorators.registerEnumType(eSaleOrder, 'Point.eSaleOrder');
}
