
namespace Point.Administration {

    @Serenity.Decorators.registerClass()
    export class NumbersDialog extends Serenity.EntityDialog<NumbersRow, any> {
        protected getFormKey() { return NumbersForm.formKey; }
        protected getIdProperty() { return NumbersRow.idProperty; }
        protected getLocalTextPrefix() { return NumbersRow.localTextPrefix; }
        protected getNameProperty() { return NumbersRow.nameProperty; }
        protected getService() { return NumbersService.baseUrl; }
        protected getDeletePermission() { return NumbersRow.deletePermission; }
        protected getInsertPermission() { return NumbersRow.insertPermission; }
        protected getUpdatePermission() { return NumbersRow.updatePermission; }

        protected form = new NumbersForm(this.idPrefix);

    }
}