namespace Point.Accounts {
    export interface VoucherDetailsRow {
        TransactionID?: number;
        VoucherID?: number;
        Debit?: number;
        Credit?: number;
        ExchangeRate?: number;
        AccountID?: number;
        CurrencyID?: number;
        BranchID?: number;
        DepartmentID?: number;
        CategoryID?: number;
        AccountDisplay?: string;
        CurrencyName?: string;
        TransactionNo?: string;
        TransactionDate?: string;
        TransactionType?: string;
        TREF?: string;
        Particulars?: string;
        ChequeNo?: string;
        RefNo?: string;
        Status?: number;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace VoucherDetailsRow {
        export const idProperty = 'TransactionID';
        export const localTextPrefix = 'Accounts.VoucherDetails';
        export const deletePermission = 'Accounts:VoucherDetails';
        export const insertPermission = 'Accounts:VoucherDetails';
        export const readPermission = 'Accounts:VoucherDetails';
        export const updatePermission = 'Accounts:VoucherDetails';

        export declare const enum Fields {
            TransactionID = "TransactionID",
            VoucherID = "VoucherID",
            Debit = "Debit",
            Credit = "Credit",
            ExchangeRate = "ExchangeRate",
            AccountID = "AccountID",
            CurrencyID = "CurrencyID",
            BranchID = "BranchID",
            DepartmentID = "DepartmentID",
            CategoryID = "CategoryID",
            AccountDisplay = "AccountDisplay",
            CurrencyName = "CurrencyName",
            TransactionNo = "TransactionNo",
            TransactionDate = "TransactionDate",
            TransactionType = "TransactionType",
            TREF = "TREF",
            Particulars = "Particulars",
            ChequeNo = "ChequeNo",
            RefNo = "RefNo",
            Status = "Status",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
