namespace Point.Accounts {
    export interface ExchangeRatesRow {
        ExchangeRateId?: number;
        CurrencyId?: number;
        CurrencyName?: string;
        StartDate?: string;
        EndDate?: string;
        Rate?: number;
    }

    export namespace ExchangeRatesRow {
        export const idProperty = 'ExchangeRateId';
        export const localTextPrefix = 'Accounts.ExchangeRates';
        export const deletePermission = 'Accounts:Exchange Rates';
        export const insertPermission = 'Accounts:Exchange Rates';
        export const readPermission = 'Accounts:Exchange Rates';
        export const updatePermission = 'Accounts:Exchange Rates';

        export declare const enum Fields {
            ExchangeRateId = "ExchangeRateId",
            CurrencyId = "CurrencyId",
            CurrencyName = "CurrencyName",
            StartDate = "StartDate",
            EndDate = "EndDate",
            Rate = "Rate"
        }
    }
}
