namespace Point.Administration {
    export interface NumbersRow {
        NumberId?: number;
        OrganizationId?: number;
        NumberType?: string;
        NextNumber?: number;
    }

    export namespace NumbersRow {
        export const idProperty = 'NumberId';
        export const nameProperty = 'NumberType';
        export const localTextPrefix = 'Administration.Numbers';
        export const deletePermission = 'Administration:Numbers';
        export const insertPermission = 'Administration:Numbers';
        export const readPermission = 'Administration:Numbers';
        export const updatePermission = 'Administration:Numbers';

        export declare const enum Fields {
            NumberId = "NumberId",
            OrganizationId = "OrganizationId",
            NumberType = "NumberType",
            NextNumber = "NextNumber"
        }
    }
}
