
namespace Point.Services {
  
    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class ServicesDialog extends Serenity.EntityDialog<ServicesRow, any> {
        protected getFormKey() { return ServicesForm.formKey; }
        protected getIdProperty() { return ServicesRow.idProperty; }
        protected getLocalTextPrefix() { return ServicesRow.localTextPrefix; }
        protected getNameProperty() { return ServicesRow.nameProperty; }
        protected getService() { return ServicesService.baseUrl; }
        protected getDeletePermission() { return ServicesRow.deletePermission; }
        protected getInsertPermission() { return ServicesRow.insertPermission; }
        protected getUpdatePermission() { return ServicesRow.updatePermission; }

        protected form = new ServicesForm(this.idPrefix);
        

        protected updateInterface() {
            super.updateInterface();



            //var today = new Date();
            //var dd = String(today.getDate());
            //var mm = String(today.getMonth() + 1); //January is 0!
            //var yyyy = today.getFullYear();
            //var ntoday = mm + '/' + dd + '/' + yyyy;
            //this.form.Date.value = ntoday;

            if (this.form.PaidAmount.value == null)
                this.form.PaidAmount.value = 0;

        }

        constructor() {


            

            super();

            this.form = new ServicesForm(this.idPrefix);

         
            (this.form.DetailList.view as any).onRowCountChanged.subscribe(() => {
                this.doCalculation();
                // Q.notifySuccess(total.toString());
            });


            (this.form.DetailList.view as any).onDataChanged.subscribe(() => {
                this.doCalculation();
                // Q.notifySuccess(total.toString());
            });

            this.form.Discount.change(e => {
                this.doCalculation();

            });


            this.form.Tax.change(e => {
                this.doCalculation();

            });
            //this.form.Debit.addValidationRule(this.uniqueName, e=> {

            //    if (this.form.Debit.value > 100) {

            //        return "Unit price must be less than 100";
            //    }

            //});

            //this.form.ExchangeRate.addValidationRule(this.uniqueName, e => {

            //    if (this.form.ExchangeRate.value == null ||
            //        this.form.ExchangeRate.value == 0
            //    ) {

            //        return "Exchange Rate must not be blank/zero!";
            //    }

            //});




            this.form.CustomerId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();

                var customerID = Q.toId(this.form.CustomerId.value);

                var customer = Point.BusinessPartners.CustomersRow.getLookup().itemById[customerID];


                var curid = Q.toId(customer.CurrencyId);
                this.form.CurrencyID.value = curid;

                var currency = Point.Accounts.CurrenciesRow.getLookup().itemById[curid];


                //this.form.ExchangeRate.element.hide();
                
                //this.form.ExchangeRate.element.css("font-weight", "bold");
                //this.form.ExchangeRate.element.css("color", "#d1d1d1");

                var Organizationn = Point.Administration.OrganizationsRow.getLookup().itemById[1];
                if (currency.CurrencyId == Organizationn.CurrencyId) {
                    this.form.ExchangeRate.value = 1;
                    this.form.ExchangeRate.element.attr("ReadOnly", "true");
                    this.form.ExchangeRate.element.css("background-color", "#F4F4F4");
                }
                else {
                    this.form.ExchangeRate.value = 1;

                    this.form.ExchangeRate.element.removeAttr("ReadOnly");
                    this.form.ExchangeRate.element.css("background-color", "#FFFFFF");
                }
             //   this.form.cu.value = curid;



                

                
         



                //this.form.CurrencyName.value = account.CurrencyName;
                //this.form.AccountDisplay.value = account.AccountDisplay;

                //if (account.CurrencyId == 1) this.form.ExchangeRate.value = 1;


                // this.form.CurrencyID.addOption(curid, account.AccountTitle);

                // this.form.CurrencyID.props.value = "1";


                // this.form.CurrencyID.
                // this.form.PlanId.addOption("1", "ABC");



            });




        }

        private doCalculation() {
            var total = 0;


            for (var k of this.form.DetailList.getItems()) {
                total += k.Total || 0;
            }

            
            this.form.GrossTotal.value = total;

            var discount = 0;
            if (this.form.Discount.value!=null)
             discount = this.form.Discount.value;

            var tax = 0;
            if (this.form.Tax.value != null)
                tax = this.form.Tax.value;


            var paid = 0;
            if (this.form.PaidAmount.value != null)
                paid = this.form.PaidAmount.value;

            var netwithoutTax = (total - discount);

            var taxamount = (netwithoutTax * tax)/100;

            var net = netwithoutTax + taxamount;

            this.form.NetTotal.value = net;

            var balance = net - paid;
            this.form.Balance.value = balance;



            //this.form.GrandTotal.value = total;


            //var subDiscount = 0;
            //for (var k of this.form.LineItems.getItems()) {
            //    subDiscount += (k.Discount * k.Quantity * k.UnitPrice / 100) || 0;
            //}

            //this.form.TotalDiscount.value = this.form.Discount.value + subDiscount;

            //var vat = 0;
            //if (SmartERP.Administration.CompaniesRow.getLookup().items.length > 0) {
            //    vat = SmartERP.Administration.CompaniesRow.getLookup().items[0].VatPercentage;
            //}
            //this.form.Vat.value = (this.form.GrandTotal.value - this.form.Discount.value) * vat / 100;
            //this.form.Vat.element.closest('.field').find('.caption').text('Vat (' + vat + '%)');

            //this.form.NetTotal.value = this.form.GrandTotal.value - this.form.Discount.value + this.form.Vat.value + this.form.ShippingCost.value;

            //this.form.Due.value = this.form.NetTotal.value - this.form.PaidAmount.value;
            //if ((this.form.PaidAmount.value - this.form.NetTotal.value) > 0) {
            //    this.form.Change.value = this.form.PaidAmount.value - this.form.NetTotal.value;
            //    this.form.Due.value = 0;
            //} else {
            //    this.form.Change.value = 0;
            //}
        }

    }
}