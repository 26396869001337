namespace Point.HRM {
    export interface EmployeeSalariesForm {
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        BasicSalary: Serenity.DecimalEditor;
        Transportation: Serenity.DecimalEditor;
        HouseRent: Serenity.DecimalEditor;
        Utilities: Serenity.DecimalEditor;
        Medical: Serenity.DecimalEditor;
        LoanDeduction: Serenity.DecimalEditor;
        IncomeTax: Serenity.DecimalEditor;
        ProvidentFund: Serenity.DecimalEditor;
        EOBI: Serenity.DecimalEditor;
        GrossSalary: Serenity.DecimalEditor;
        TotalDeductions: Serenity.DecimalEditor;
        NetSalary: Serenity.DecimalEditor;
    }

    export class EmployeeSalariesForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeSalaries';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeSalariesForm.init)  {
                EmployeeSalariesForm.init = true;

                var s = Serenity;
                var w0 = s.DateEditor;
                var w1 = s.DecimalEditor;

                Q.initFormType(EmployeeSalariesForm, [
                    'StartDate', w0,
                    'EndDate', w0,
                    'BasicSalary', w1,
                    'Transportation', w1,
                    'HouseRent', w1,
                    'Utilities', w1,
                    'Medical', w1,
                    'LoanDeduction', w1,
                    'IncomeTax', w1,
                    'ProvidentFund', w1,
                    'EOBI', w1,
                    'GrossSalary', w1,
                    'TotalDeductions', w1,
                    'NetSalary', w1
                ]);
            }
        }
    }
}
