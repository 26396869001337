namespace Point.Products {
    export interface UnitsForm {
        UnitCode: Serenity.StringEditor;
        UnitName: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class UnitsForm extends Serenity.PrefixedContext {
        static formKey = 'Products.Units';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!UnitsForm.init)  {
                UnitsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(UnitsForm, [
                    'UnitCode', w0,
                    'UnitName', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
