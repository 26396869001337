
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class SalarySlipsDialog extends Serenity.EntityDialog<SalarySlipsRow, any> {
        protected getFormKey() { return SalarySlipsForm.formKey; }
        protected getIdProperty() { return SalarySlipsRow.idProperty; }
        protected getLocalTextPrefix() { return SalarySlipsRow.localTextPrefix; }
        protected getNameProperty() { return SalarySlipsRow.nameProperty; }
        protected getService() { return SalarySlipsService.baseUrl; }
        protected getDeletePermission() { return SalarySlipsRow.deletePermission; }
        protected getInsertPermission() { return SalarySlipsRow.insertPermission; }
        protected getUpdatePermission() { return SalarySlipsRow.updatePermission; }

        protected form = new SalarySlipsForm(this.idPrefix);

        constructor() {
            super();

            this.form.EmployeeId.changeSelect2(e => {

            

                this.doGetSalary();

            });


            this.form.SalaryMonth.change(e => {
                this.doGetSalary();
            });

           
            this.form.CustomEntry.change(e => {
                this.AllowCustomEntry();
            });

            this.form.UnPaidDays.change(e => {
                this.doCalculation();
            });






            this.form.BasicSalary.change(e => {
                this.doCalculation();
            });

            this.form.HouseRent.change(e => {
                this.doCalculation();
            });

            this.form.Utilities.change(e => {
                this.doCalculation();
            });


            this.form.Medical.change(e => {
                this.doCalculation();
            });


            this.form.Transportation.change(e => {
                this.doCalculation();
            });


            this.form.MedicalReimbursement.change(e => {
                this.doCalculation();
            });


            this.form.ExpensesReimbursement.change(e => {
                this.doCalculation();
            });


            this.form.OtherEarning1Amount.change(e => {
                this.doCalculation();
            });


            this.form.OtherEarning2Amount.change(e => {
                this.doCalculation();
            });


            this.form.TaxDeduction.change(e => {
                this.doCalculation();
            });



            this.form.LoanDeduction.change(e => {
                this.doCalculation();
            });

            this.form.LeaveDeduction.change(e => {
                this.doCalculation();
            });


            this.form.Fine.change(e => {
                this.doCalculation();
            });


            this.form.ProvidentFund.change(e => {
                this.doCalculation();
            });

            this.form.AdvanceSalary.change(e => {
                this.doCalculation();
            });

            this.form.EOBI.change(e => {
                this.doCalculation();
            });

            this.form.OtherDeduction1Amount.change(e => {
                this.doCalculation();
            });

            this.form.OtherDeduction2Amount.change(e => {
                this.doCalculation();
            });





        }



        private AllowCustomEntry() {


            var bcustom = this.form.CustomEntry.value;

            
           


          


            if (bcustom == true) {


                this.form.PerDaySalary.element.removeAttr("ReadOnly");
                this.form.PerDaySalary.element.css("background-color", "#FFFFFF");

                this.form.PaidDays.element.removeAttr("ReadOnly");
                this.form.PaidDays.element.css("background-color", "#FFFFFF");

                this.form.UnPaidDays.element.removeAttr("ReadOnly");
                this.form.UnPaidDays.element.css("background-color", "#FFFFFF");

                this.form.LeaveDeduction.element.removeAttr("ReadOnly");
                this.form.LeaveDeduction.element.css("background-color", "#FFFFFF");

                this.form.BasicSalary.element.removeAttr("ReadOnly");
                this.form.BasicSalary.element.css("background-color", "#FFFFFF");

                this.form.Transportation.element.removeAttr("ReadOnly");
                this.form.Transportation.element.css("background-color", "#FFFFFF");

                this.form.HouseRent.element.removeAttr("ReadOnly");
                this.form.HouseRent.element.css("background-color", "#FFFFFF");

                this.form.Utilities.element.removeAttr("ReadOnly");
                this.form.Utilities.element.css("background-color", "#FFFFFF");

                this.form.Medical.element.removeAttr("ReadOnly");
                this.form.Medical.element.css("background-color", "#FFFFFF");

                this.form.ProvidentFund.element.removeAttr("ReadOnly");
                this.form.ProvidentFund.element.css("background-color", "#FFFFFF");

                this.form.LoanDeduction.element.removeAttr("ReadOnly");
                this.form.LoanDeduction.element.css("background-color", "#FFFFFF");

                this.form.EOBI.element.removeAttr("ReadOnly");
                this.form.EOBI.element.css("background-color", "#FFFFFF");

                this.form.TaxDeduction.element.removeAttr("ReadOnly");
                this.form.TaxDeduction.element.css("background-color", "#FFFFFF");


         
                

            }
            else {


                this.form.PerDaySalary.element.attr("ReadOnly", "true");
                this.form.PerDaySalary.element.css("background-color", "#F4F4F4");

                this.form.PaidDays.element.attr("ReadOnly", "true");
                this.form.PaidDays.element.css("background-color", "#F4F4F4");

                this.form.UnPaidDays.element.attr("ReadOnly", "true");
                this.form.UnPaidDays.element.css("background-color", "#F4F4F4");


                this.form.LeaveDeduction.element.attr("ReadOnly", "true");
                this.form.LeaveDeduction.element.css("background-color", "#F4F4F4");

                


                this.form.BasicSalary.element.attr("ReadOnly", "true");
                this.form.BasicSalary.element.css("background-color", "#F4F4F4");

                this.form.Transportation.element.attr("ReadOnly", "true");
                this.form.Transportation.element.css("background-color", "#F4F4F4");

                this.form.HouseRent.element.attr("ReadOnly", "true");
                this.form.HouseRent.element.css("background-color", "#F4F4F4");

                this.form.Utilities.element.attr("ReadOnly", "true");
                this.form.Utilities.element.css("background-color", "#F4F4F4");

                this.form.Medical.element.attr("ReadOnly", "true");
                this.form.Medical.element.css("background-color", "#F4F4F4");

                this.form.ProvidentFund.element.attr("ReadOnly", "true");
                this.form.ProvidentFund.element.css("background-color", "#F4F4F4");

                this.form.LoanDeduction.element.attr("ReadOnly", "true");
                this.form.LoanDeduction.element.css("background-color", "#F4F4F4");

                this.form.EOBI.element.attr("ReadOnly", "true");
                this.form.EOBI.element.css("background-color", "#F4F4F4");

                this.form.TaxDeduction.element.attr("ReadOnly", "true");
                this.form.TaxDeduction.element.css("background-color", "#F4F4F4");




            }
           



        }

        private doGetSalary() {

            var empID = Q.toId(this.form.EmployeeId.value);
            var mystartdate = this.form.SalaryMonth.value;

            var scriteria = "EmployeeID = " + empID + "  and ( '" + mystartdate + "' between StartDate and EndDate )";




            var salary = Point.HRM.EmployeeSalaryService.GetBasic({
                ContainsText: scriteria
            }, resp => {
                var sal = resp;
                    if (sal != null) {
                        this.form.BasicSalary.value = sal.BasicSalary;
                        this.form.Transportation.value = sal.Transportation;
                        this.form.HouseRent.value = sal.HouseRent;
                        this.form.Utilities.value = sal.Utilities;
                        this.form.Medical.value = sal.Medical;
                        this.form.ProvidentFund.value = sal.ProvidentFund;
                        this.form.LoanDeduction.value = sal.LoanDeduction;
                        this.form.EOBI.value = sal.EOBI;
                        this.form.TaxDeduction.value = sal.IncomeTax;
                        this.doCalculation();
                    }
            });

            

        }



        private doCalculation() {

       

            var BasicSalary = this.form.BasicSalary.value;
            var Transportation = this.form.Transportation.value;
            var HouseRent = this.form.HouseRent.value;
            var Utilities = this.form.Utilities.value;
            var Medical = this.form.Medical.value;
            var MedicalReimbursement = this.form.MedicalReimbursement.value;
            var ExpensesReimbursement = this.form.ExpensesReimbursement.value;
            var Earning1 = this.form.OtherEarning1Amount.value;
            var Earning2 = this.form.OtherEarning2Amount.value;


            var ProvidentFund = this.form.ProvidentFund.value;
            var LoanDeduction = this.form.LoanDeduction.value;
            var EOBI = this.form.EOBI.value;
            var TaxDeduction = this.form.TaxDeduction.value;
            var AdvanceSalary = this.form.AdvanceSalary.value;
            var LeaveDeduction = this.form.LeaveDeduction.value;
            var Deduction1 = this.form.OtherDeduction1Amount.value;
            var Deduction2 = this.form.OtherDeduction2Amount.value;

            var TotalEarnings = BasicSalary + Transportation + HouseRent + Utilities + Medical + MedicalReimbursement + ExpensesReimbursement+ Earning1 + Earning2;

            var TotalDeductionns = ProvidentFund + LoanDeduction + EOBI + AdvanceSalary +LeaveDeduction+TaxDeduction + Deduction1 + Deduction2;


            var NetSalary = TotalEarnings - TotalDeductionns;

            this.form.NetSalary.value = NetSalary;



                 }

    }




}