declare var Morris;

namespace Point.Default {
    import Jdw = J.DashboardWidget;

    export class SaleGraphSettingParams {
        Title: string;
        TitleIconClass: string;
        RefreshIntervalMiliseconds: number;
        ChartType: number
    }

    export class SaleGraph extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<SaleGraphSettingParams>> {
        protected getContentSettingDialogType() { return SaleGraphSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:SaleGraphView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:SaleGraphModify"; }

        chart: any;

        constructor(opt: Jdw.BaseDashboardWidgetOptions<SaleGraphSettingParams>) {
            super(opt);

        }

        protected buildWidgetData(widgetContentParams: SaleGraphSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams);

            this.refreshIntervalMiliseconds = Q.toId(widgetContentParams.RefreshIntervalMiliseconds);
            this.updateTitlebar(widgetContentParams);

            //if (this.chart == null || typeof this.chart == 'undefined') {
            //    this.chart = Morris.Line({
            //        element: this.byId("chartContainer"),
            //        data: [{y: 0, a: 0, b: 0}],
            //        xkey: 'y',
            //        ykeys: ['a', 'b'],
            //        labels: ['Services', 'Products']
            //    });
            //}

            if (this.chart == undefined) {
                widgetContentParams.ChartType = 2;
            }

           
            this.byId("chartContainer").text('');



            if (widgetContentParams.ChartType == 1 ) {
                this.chart = Morris.Line({
                    element: this.byId("chartContainer"),
                  
                    xkey: 'y',
                    ykeys: ['a', 'b'],
                    labels: ['Services', 'Products']
                });

            }
            else if (widgetContentParams.ChartType == 2) {
                this.chart = Morris.Bar({
                    element: this.byId("chartContainer"),
                   
                    xkey: 'y',
                    ykeys: ['a', 'b'],
                    labels: ['Services', 'Products']
                });
               
            }
            else if (widgetContentParams.ChartType == 3) {
                this.chart = Morris.Area ({
                    element: this.byId("chartContainer"),
                   
                    xkey: 'y',
                    ykeys: ['a', 'b'],
                    labels: ['Services', 'Products']
                });
              
            }

            let date1 ="";
            let a1 = 0;
            let b1 = 0;

            let date2 = "";
            let a2 = 0;
            let b2 = 0;

            let date3 = "";
            let a3 = 0;
            let b3 = 0;

            let date4 = "";
            let a4 = 0;
            let b4 = 0;

            let date5 = "";
            let a5 = 0;
            let b5 = 0;

            let date6 = "";
            let a6 = 0;
            let b6 = 0;

            let date7 = "";
            let a7 = 0;
            let b7 = 0;


            Point.Accounts.AccountsService.GetSaleGraph({
             //   ContainsText: accountid.toString()
             //    ContainsText: "Today"
            }, response => {

                //this.byId("AccountCode").text(response[0].AccountCode);

                    date1 = response[0].Date1;
                    a1 = response[0].Product1;
                    b1 = response[0].Service1;

                    date2 = response[0].Date2;
                    a2 = response[0].Product2;
                    b2 = response[0].Service2;

                    date3 = response[0].Date3;
                    a3 = response[0].Product3;
                    b3 = response[0].Service3;

                    date4 = response[0].Date4;
                    a4 = response[0].Product4;
                    b4 = response[0].Service4;

                    date5 = response[0].Date5;
                    a5 = response[0].Product5;
                    b5 = response[0].Service5;

                    date6 = response[0].Date6;
                    a6 = response[0].Product6;
                    b6 = response[0].Service6;

                    date7 = response[0].Date7;
                    a7 = response[0].Product7;
                    b7 = response[0].Service7;



            });



            return Promise.resolve()
                .then(x => new Promise(resolve => setTimeout(() => resolve(x), 800))) // demo time for call ajax
                .then(() => {
                    this.chart.setData([
                        { y: date1, a: a1, b: b1 },
                        { y: date2, a: a2, b: b2 },
                        { y: date3, a: a3, b: b3 },
                        { y: date4, a: a4, b: b4 },
                        { y: date5, a: a5, b: b5 },
                        { y: date6, a: a6, b: b6 },
                        { y: date7, a: a7, b: b7 }
                    ]);

                    this.chart.redraw();
                })
                .then(() => {
                    $(window).resize(() => {
                        this.afterWidgetStopResizing();
                    });
                });
            
        }

        protected updateTitlebar(widgetContentParams: SaleGraphSettingParams) {
            let widgetName = "";
            if (Q.trimToNull(widgetContentParams.Title) == null) {
                widgetName = Q.coalesce(this.getWidgetTitle(), "&nbsp;");
            }
            else {
                widgetName = widgetContentParams.Title;
            }

            this.byId("title").text($("<div />").html(widgetName).text());
            this.byId("titleIcon").attr('class', `${widgetContentParams.TitleIconClass}`);
        }

        protected afterWidgetStopResizing() {
            super.afterWidgetStopResizing();

            Q.debounce(() => {
                if (this.chart) {
                    var parentDivWidth = this.byId("chartContainer").parent("div").width();
                    var parentDivHeight = this.byId("chartContainer").parent("div").height();
                    this.byId("chartContainer").css("min-width", parentDivWidth).css("min-height", parentDivHeight);
                    this.byId("chartContainer").find("svg:nth-child(1)").css("min-width", parentDivWidth).css("min-height", parentDivHeight);
                    this.chart.redraw();
                }
            }, 100)(); // should have debounce
        }

        protected canShowRefreshLoadingIndicator() {
            return true;
        }

        protected canShowSettingButton() {
            return true;
        }

        protected canShowCountdown() {
            return true;
        }

        protected embedWidgetToDialog(dlg: Jdw.BaseWidgetViewInDialog<Jdw.BaseWidgetViewInDialogOptions>, wg: Jdw.BaseDashboardWidget<any>) {
            dlg.element.append(wg.element.find(".chart-container"));
            dlg.dialogOpen();
        }

        protected getWidgetTitle() {
            return "Services and Product Sale";
        }
    }
}