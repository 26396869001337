namespace Point.RealEstate {
    export interface BookingsRow {
        BookingId?: number;
        BookingNo?: string;
        BookingDate?: string;
        CustomerId?: number;
        ProjectId?: number;
        PlanId?: number;
        Membership?: number;
        Processing?: number;
        Booking?: number;
        Confirmation?: number;
        OnPossion?: number;
        PaymentPlanId?: number;
        Installments?: number;
        InstallmentAmount?: number;
        InstallmentStartDate?: string;
        AllotmentNo?: string;
        StatusId?: number;
        CreatedBy?: number;
        CreatedOn?: string;
        ModifiedBy?: number;
        ModifiedOn?: string;
        Verified?: boolean;
        Locked?: boolean;
        ProjectProjectName?: string;
        ProjectCountryId?: number;
        ProjectStateId?: number;
        ProjectCityId?: number;
        ProjectActive?: boolean;
        PlanProjectId?: number;
        PlanPlanName?: string;
        PlanBeds?: number;
        PlanArea?: number;
        PlanAreaId?: number;
        PlanMembership?: number;
        PlanProcessing?: number;
        PlanBooking?: number;
        PlanConfirmation?: number;
        PlanMonthly?: number;
        PlanQuarterly?: number;
        PlanHalfYearly?: number;
        PlanYearly?: number;
        PlanLumsumPrice?: number;
        PlanTotalAmount?: number;
        PlanOnPossion?: number;
        PlanActive?: boolean;
        PlanList?: BookingPlansRow[];
    }

    export namespace BookingsRow {
        export const idProperty = 'BookingId';
        export const nameProperty = 'BookingNo';
        export const localTextPrefix = 'RealEstate.Bookings';
        export const lookupKey = 'RealEstate.Bookings';

        export function getLookup(): Q.Lookup<BookingsRow> {
            return Q.getLookup<BookingsRow>('RealEstate.Bookings');
        }
        export const deletePermission = 'RealEstate:Bookings';
        export const insertPermission = 'RealEstate:Bookings';
        export const readPermission = 'RealEstate:Bookings';
        export const updatePermission = 'RealEstate:Bookings';

        export declare const enum Fields {
            BookingId = "BookingId",
            BookingNo = "BookingNo",
            BookingDate = "BookingDate",
            CustomerId = "CustomerId",
            ProjectId = "ProjectId",
            PlanId = "PlanId",
            Membership = "Membership",
            Processing = "Processing",
            Booking = "Booking",
            Confirmation = "Confirmation",
            OnPossion = "OnPossion",
            PaymentPlanId = "PaymentPlanId",
            Installments = "Installments",
            InstallmentAmount = "InstallmentAmount",
            InstallmentStartDate = "InstallmentStartDate",
            AllotmentNo = "AllotmentNo",
            StatusId = "StatusId",
            CreatedBy = "CreatedBy",
            CreatedOn = "CreatedOn",
            ModifiedBy = "ModifiedBy",
            ModifiedOn = "ModifiedOn",
            Verified = "Verified",
            Locked = "Locked",
            ProjectProjectName = "ProjectProjectName",
            ProjectCountryId = "ProjectCountryId",
            ProjectStateId = "ProjectStateId",
            ProjectCityId = "ProjectCityId",
            ProjectActive = "ProjectActive",
            PlanProjectId = "PlanProjectId",
            PlanPlanName = "PlanPlanName",
            PlanBeds = "PlanBeds",
            PlanArea = "PlanArea",
            PlanAreaId = "PlanAreaId",
            PlanMembership = "PlanMembership",
            PlanProcessing = "PlanProcessing",
            PlanBooking = "PlanBooking",
            PlanConfirmation = "PlanConfirmation",
            PlanMonthly = "PlanMonthly",
            PlanQuarterly = "PlanQuarterly",
            PlanHalfYearly = "PlanHalfYearly",
            PlanYearly = "PlanYearly",
            PlanLumsumPrice = "PlanLumsumPrice",
            PlanTotalAmount = "PlanTotalAmount",
            PlanOnPossion = "PlanOnPossion",
            PlanActive = "PlanActive",
            PlanList = "PlanList"
        }
    }
}
