
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class ExchangeRatesDialog extends Serenity.EntityDialog<ExchangeRatesRow, any> {
        protected getFormKey() { return ExchangeRatesForm.formKey; }
        protected getIdProperty() { return ExchangeRatesRow.idProperty; }
        protected getLocalTextPrefix() { return ExchangeRatesRow.localTextPrefix; }
        protected getService() { return ExchangeRatesService.baseUrl; }
        protected getDeletePermission() { return ExchangeRatesRow.deletePermission; }
        protected getInsertPermission() { return ExchangeRatesRow.insertPermission; }
        protected getUpdatePermission() { return ExchangeRatesRow.updatePermission; }

        protected form = new ExchangeRatesForm(this.idPrefix);

    }
}