/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeSalaryDialog extends Common.GridEditorDialog<EmployeeSalariesRow> {
        protected getFormKey() { return EmployeeSalariesForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeSalariesRow.localTextPrefix; }

        protected form: EmployeeSalariesForm;

        constructor() {
            super();

            this.form = new EmployeeSalariesForm(this.idPrefix);

          
            this.form.BasicSalary.change(e => {

                this.doCalculation();


            });


            this.form.Transportation.change(e => {

                this.doCalculation();


            });

            this.form.HouseRent.change(e => {

                this.doCalculation();


            });

            this.form.Utilities.change(e => {

                this.doCalculation();


            });

            this.form.Medical.change(e => {

                this.doCalculation();


            });

            this.form.LoanDeduction.change(e => {

                this.doCalculation();


            });

            this.form.IncomeTax.change(e => {

                this.doCalculation();


            });


            this.form.ProvidentFund.change(e => {

                this.doCalculation();


            });

            this.form.EOBI.change(e => {

                this.doCalculation();


            });
            


        }


        private doCalculation() {

           
            var GrossSalary = 0;
            var Deductions = 0;
            var NetSalary = 0;

            var BasicSalary = 0;
            if (this.form.BasicSalary.value != null)
                BasicSalary = this.form.BasicSalary.value;

            var Transportation = 0;
            if (this.form.Transportation.value != null)
                Transportation = this.form.Transportation.value;


            var HouseRent = 0;
            if (this.form.HouseRent.value != null)
                HouseRent = this.form.HouseRent.value;


            var Utilities = 0;
            if (this.form.Utilities.value != null)
                Utilities = this.form.Utilities.value;


            var Medical = 0;
            if (this.form.Medical.value != null)
                Medical = this.form.Medical.value;

            GrossSalary = BasicSalary + Transportation + HouseRent + Utilities + Medical;


            this.form.GrossSalary.value = GrossSalary;

            var LoanDeduction = 0;
            if (this.form.LoanDeduction.value != null)
                LoanDeduction = this.form.LoanDeduction.value;


            var IncomeTax = 0;
            if (this.form.IncomeTax.value != null)
                IncomeTax = this.form.IncomeTax.value;

            var ProvidentFund = 0;
            if (this.form.ProvidentFund.value != null)
                ProvidentFund = this.form.ProvidentFund.value;


            var EOBI = 0;
            if (this.form.EOBI.value != null)
                EOBI = this.form.EOBI.value;

            Deductions = LoanDeduction + IncomeTax + ProvidentFund + EOBI;
            this.form.TotalDeductions.value = Deductions;


            NetSalary = GrossSalary - Deductions;
            this.form.NetSalary.value = NetSalary;


        }
    }
}