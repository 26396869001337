namespace Point.Accounts {
    export namespace AccountsService {
        export const baseUrl = 'Accounts/Accounts';

        export declare function Create(request: Serenity.SaveRequest<AccountsRow>, onSuccess?: (response: Serenity.SaveResponse) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function Update(request: Serenity.SaveRequest<AccountsRow>, onSuccess?: (response: Serenity.SaveResponse) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function Delete(request: Serenity.DeleteRequest, onSuccess?: (response: Serenity.DeleteResponse) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function Retrieve(request: Serenity.RetrieveRequest, onSuccess?: (response: Serenity.RetrieveResponse<AccountsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function List(request: Serenity.ListRequest, onSuccess?: (response: Serenity.ListResponse<AccountsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function GetCashInHand(request: Serenity.ListRequest, onSuccess?: (response: number) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function GetCashInBank(request: Serenity.ListRequest, onSuccess?: (response: number) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function GetSaleGraph(request: Serenity.ListRequest, onSuccess?: (response: System.Data.DataTable) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function GetAccountStatus(request: Serenity.ListRequest, onSuccess?: (response: System.Data.DataTable) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;

        export declare const enum Methods {
            Create = "Accounts/Accounts/Create",
            Update = "Accounts/Accounts/Update",
            Delete = "Accounts/Accounts/Delete",
            Retrieve = "Accounts/Accounts/Retrieve",
            List = "Accounts/Accounts/List",
            GetCashInHand = "Accounts/Accounts/GetCashInHand",
            GetCashInBank = "Accounts/Accounts/GetCashInBank",
            GetSaleGraph = "Accounts/Accounts/GetSaleGraph",
            GetAccountStatus = "Accounts/Accounts/GetAccountStatus"
        }

        [
            'Create', 
            'Update', 
            'Delete', 
            'Retrieve', 
            'List', 
            'GetCashInHand', 
            'GetCashInBank', 
            'GetSaleGraph', 
            'GetAccountStatus'
        ].forEach(x => {
            (<any>AccountsService)[x] = function (r, s, o) {
                return Q.serviceRequest(baseUrl + '/' + x, r, s, o);
            };
        });
    }
}
