namespace Point.BusinessPartners {
    export interface BanksRow {
        BankId?: number;
        BankName?: string;
        AccountNo?: string;
        AccountTitle?: string;
        Branch?: string;
        Address?: string;
        ContactName?: string;
        PhoneNo?: string;
        AllowTransactions?: boolean;
        ShowInReport?: boolean;
        CurrencyId?: number;
        Account?: string;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace BanksRow {
        export const idProperty = 'BankId';
        export const nameProperty = 'BankName';
        export const localTextPrefix = 'BusinessPartners.Banks';
        export const deletePermission = 'BusinessPartners:Banks';
        export const insertPermission = 'BusinessPartners:Banks';
        export const readPermission = 'BusinessPartners:Banks';
        export const updatePermission = 'BusinessPartners:Banks';

        export declare const enum Fields {
            BankId = "BankId",
            BankName = "BankName",
            AccountNo = "AccountNo",
            AccountTitle = "AccountTitle",
            Branch = "Branch",
            Address = "Address",
            ContactName = "ContactName",
            PhoneNo = "PhoneNo",
            AllowTransactions = "AllowTransactions",
            ShowInReport = "ShowInReport",
            CurrencyId = "CurrencyId",
            Account = "Account",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
