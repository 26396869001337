namespace Point.Accounts {
    export interface VoucherTypesRow {
        VoucherTypeId?: number;
        VoucherTypeCode?: string;
        VoucherTypeName?: string;
        VoucherFooter?: string;
    }

    export namespace VoucherTypesRow {
        export const idProperty = 'VoucherTypeId';
        export const nameProperty = 'VoucherTypeCode';
        export const localTextPrefix = 'Accounts.VoucherTypes';
        export const lookupKey = 'Accounts.VoucherTypes';

        export function getLookup(): Q.Lookup<VoucherTypesRow> {
            return Q.getLookup<VoucherTypesRow>('Accounts.VoucherTypes');
        }
        export const deletePermission = 'Accounts:VoucherTypes';
        export const insertPermission = 'Accounts:VoucherTypes';
        export const readPermission = 'Accounts:VoucherTypes';
        export const updatePermission = 'Accounts:VoucherTypes';

        export declare const enum Fields {
            VoucherTypeId = "VoucherTypeId",
            VoucherTypeCode = "VoucherTypeCode",
            VoucherTypeName = "VoucherTypeName",
            VoucherFooter = "VoucherFooter"
        }
    }
}
