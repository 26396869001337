namespace Point.Purchases {
    export interface PurchaseOrderDetailsRow {
        PurchaseOrderDetailID?: number;
        PurchaseOrderID?: number;
        UnitPrice?: number;
        UnitCode?: string;
        Qty?: number;
        ProductName?: string;
        DiscountPers?: number;
        Discount?: number;
        TaxDetailId?: number;
        TaxDetailName?: string;
        Tax?: number;
        Total?: number;
        ProductID?: number;
        WarehouseId?: number;
    }

    export namespace PurchaseOrderDetailsRow {
        export const idProperty = 'PurchaseOrderDetailID';
        export const localTextPrefix = 'Purchases.PurchaseOrderDetails';
        export const deletePermission = 'Purchases:PurchaseOrderDetails';
        export const insertPermission = 'Purchases:PurchaseOrderDetails';
        export const readPermission = 'Purchases:PurchaseOrderDetails';
        export const updatePermission = 'Purchases:PurchaseOrderDetails';

        export declare const enum Fields {
            PurchaseOrderDetailID = "PurchaseOrderDetailID",
            PurchaseOrderID = "PurchaseOrderID",
            UnitPrice = "UnitPrice",
            UnitCode = "UnitCode",
            Qty = "Qty",
            ProductName = "ProductName",
            DiscountPers = "DiscountPers",
            Discount = "Discount",
            TaxDetailId = "TaxDetailId",
            TaxDetailName = "TaxDetailName",
            Tax = "Tax",
            Total = "Total",
            ProductID = "ProductID",
            WarehouseId = "WarehouseId"
        }
    }
}
