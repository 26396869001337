namespace Point.BusinessPartners {
    export namespace PermissionKeys {
        export const General = "BusinessPartners:General";

        namespace Countries {
            export const View = "Masters:Country:View";
            export const Create = "Masters:Country:Create";
            export const Update = "Masters:Country:Update";
            export const Delete = "Masters:Country:Delete";
        }

        namespace States {
            export const View = "Masters:State:View";
            export const Create = "Masters:State:Create";
            export const Update = "Masters:State:Update";
            export const Delete = "Masters:State:Delete";
        }
    }
}
