namespace Point.BusinessPartners {
    export interface TerritoriesRow {
        TerritoryId?: number;
        TerritoryName?: string;
    }

    export namespace TerritoriesRow {
        export const idProperty = 'TerritoryId';
        export const nameProperty = 'TerritoryName';
        export const localTextPrefix = 'BusinessPartners.Territories';
        export const lookupKey = 'BusinessPartners.Territories';

        export function getLookup(): Q.Lookup<TerritoriesRow> {
            return Q.getLookup<TerritoriesRow>('BusinessPartners.Territories');
        }
        export const deletePermission = 'BusinessPartners:Territories';
        export const insertPermission = 'BusinessPartners:Territories';
        export const readPermission = 'BusinessPartners:Territories';
        export const updatePermission = 'BusinessPartners:Territories';

        export declare const enum Fields {
            TerritoryId = "TerritoryId",
            TerritoryName = "TerritoryName"
        }
    }
}
