
namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class SaleQuotationsGrid extends Serenity.EntityGrid<SaleQuotationsRow, any> {
        protected getColumnsKey() { return 'Sales.SaleQuotations'; }
        protected getDialogType() { return SaleQuotationsDialog; }
        protected getIdProperty() { return SaleQuotationsRow.idProperty; }
        protected getInsertPermission() { return SaleQuotationsRow.insertPermission; }
        protected getLocalTextPrefix() { return SaleQuotationsRow.localTextPrefix; }
        protected getService() { return SaleQuotationsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}