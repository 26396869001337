namespace Point.HRM {
    export interface TrainingsForm {
        TrainingCode: Serenity.StringEditor;
        TrainingTitle: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class TrainingsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.Trainings';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TrainingsForm.init)  {
                TrainingsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(TrainingsForm, [
                    'TrainingCode', w0,
                    'TrainingTitle', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
