namespace Point.RealEstate {
    export interface ProjectPlansForm {
        PlanName: Serenity.StringEditor;
        Beds: Serenity.EnumEditor;
        AreaId: Serenity.EnumEditor;
        Area: Serenity.IntegerEditor;
        Membership: Serenity.DecimalEditor;
        Processing: Serenity.DecimalEditor;
        Booking: Serenity.DecimalEditor;
        Confirmation: Serenity.DecimalEditor;
        OnPossion: Serenity.DecimalEditor;
        Monthly: Serenity.DecimalEditor;
        Quarterly: Serenity.DecimalEditor;
        HalfYearly: Serenity.DecimalEditor;
        Yearly: Serenity.DecimalEditor;
        TotalAmount: Serenity.DecimalEditor;
        LumsumPrice: Serenity.DecimalEditor;
    }

    export class ProjectPlansForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.ProjectPlan';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ProjectPlansForm.init)  {
                ProjectPlansForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;
                var w2 = s.IntegerEditor;
                var w3 = s.DecimalEditor;

                Q.initFormType(ProjectPlansForm, [
                    'PlanName', w0,
                    'Beds', w1,
                    'AreaId', w1,
                    'Area', w2,
                    'Membership', w3,
                    'Processing', w3,
                    'Booking', w3,
                    'Confirmation', w3,
                    'OnPossion', w3,
                    'Monthly', w3,
                    'Quarterly', w3,
                    'HalfYearly', w3,
                    'Yearly', w3,
                    'TotalAmount', w3,
                    'LumsumPrice', w3
                ]);
            }
        }
    }
}
