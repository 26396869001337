namespace Point.Default {
    export interface QuickButtonsSettingForm {
        Title: Serenity.StringEditor;
        NewContact: Serenity.BooleanEditor;
        NewCustomer: Serenity.BooleanEditor;
        NewVoucher: Serenity.BooleanEditor;
        DayBook: Serenity.BooleanEditor;
        NewService: Serenity.BooleanEditor;
        Attendance: Serenity.BooleanEditor;
        Products: Serenity.BooleanEditor;
        NewInvoice: Serenity.BooleanEditor;
        NewTask: Serenity.BooleanEditor;
        ViewOrders: Serenity.BooleanEditor;
    }

    export class QuickButtonsSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.QuickButtonsSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!QuickButtonsSettingForm.init)  {
                QuickButtonsSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(QuickButtonsSettingForm, [
                    'Title', w0,
                    'NewContact', w1,
                    'NewCustomer', w1,
                    'NewVoucher', w1,
                    'DayBook', w1,
                    'NewService', w1,
                    'Attendance', w1,
                    'Products', w1,
                    'NewInvoice', w1,
                    'NewTask', w1,
                    'ViewOrders', w1
                ]);
            }
        }
    }
}
