/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Products {

    @Serenity.Decorators.registerClass()
    export class ProductSalePriceDialog extends Common.GridEditorDialog<ProductSalePricesRow> {
        protected getFormKey() { return ProductSalePricesForm.formKey; }
        protected getLocalTextPrefix() { return ProductSalePricesRow.localTextPrefix; }

        protected form: ProductSalePricesForm;

        constructor() {
            super();

            this.form = new ProductSalePricesForm(this.idPrefix);

            this.form.SalePrice.addValidationRule(this.uniqueName, e => {

                if (this.form.SalePrice.value == 0) {

                    return "Sale Price must not be zero.";
                }

            });

            this.form.CustomerGroupId.changeSelect2(e => {

          



                var CustomerGroupId = Q.toId(this.form.CustomerGroupId.value);

                var CustomerGroup = Point.BusinessPartners.CustomerGroupsRow.getLookup().itemById[CustomerGroupId];


                this.form.CustomerGroupName.value = CustomerGroup.CustomerGroupName




            });


            this.form.CustomerId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var CustomerId = Q.toId(this.form.CustomerId.value);

                var Customer = Point.BusinessPartners.CustomersRow.getLookup().itemById[CustomerId];


                this.form.CustomerName.value = Customer.CustomerName




            });


        }
    }
}