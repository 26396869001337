namespace Point.TTS {
    export interface TasksRow {
        TaskId?: number;
        TaskNo?: string;
        Subject?: string;
        Statusid?: number;
        InitiatedById?: number;
        OwnerId?: number;
        Notes?: string;
        TaskTypeId?: number;
        CommunicationType?: number;
        CommunicationMethod?: number;
        CustomerId?: number;
        Priority?: number;
        SmsAlert?: boolean;
        EmailAlert?: boolean;
        RealBookingId?: number;
        ConfirmationNo?: string;
        DueDate?: string;
        Attachment?: string;
        RateId?: number;
        RateComments?: string;
        InitiatedByUsername?: string;
        InitiatedByDisplayName?: string;
        InitiatedByEmail?: string;
        InitiatedBySource?: string;
        InitiatedByLastDirectoryUpdate?: string;
        InitiatedByUserImage?: string;
        InitiatedByInsertDate?: string;
        InitiatedByInsertUserId?: number;
        InitiatedByUpdateDate?: string;
        InitiatedByUpdateUserId?: number;
        InitiatedByIsActive?: number;
        InitiatedByMobilePhoneNumber?: string;
        InitiatedByMobilePhoneVerified?: boolean;
        InitiatedByTwoFactorAuth?: number;
        OwnerUsername?: string;
        OwnerDisplayName?: string;
        OwnerEmail?: string;
        OwnerSource?: string;
        OwnerLastDirectoryUpdate?: string;
        OwnerUserImage?: string;
        OwnerInsertDate?: string;
        OwnerInsertUserId?: number;
        OwnerUpdateDate?: string;
        OwnerUpdateUserId?: number;
        OwnerIsActive?: number;
        OwnerMobilePhoneNumber?: string;
        OwnerMobilePhoneVerified?: boolean;
        OwnerTwoFactorAuth?: number;
        TaskType?: string;
        TaskTypeDefaultUserId?: number;
        TaskTypeActive?: boolean;
        TaskTypeWebEnabled?: boolean;
        CustomerCustomerCode?: string;
        CustomerCustomerGroupId?: number;
        CustomerCustomerName?: string;
        CustomerTitleId?: number;
        CustomerContactName?: string;
        CustomerMobileNo?: string;
        CustomerBranchId?: number;
        CustomerAddress?: string;
        CustomerCountryId?: number;
        CustomerStateId?: number;
        CustomerCityId?: number;
        CustomerCurrencyId?: number;
        CustomerRegionId?: number;
        CustomerTerritoryId?: number;
        CustomerCreditLimit?: number;
        CustomerPostalCode?: string;
        CustomerCountry?: string;
        CustomerPhoneNo?: string;
        CustomerEmail?: string;
        CustomerLogo?: string;
        CustomerCreatedBy?: number;
        CustomerCreatedOn?: string;
        CustomerModifiedBy?: number;
        CustomerModifiedOn?: string;
        CustomerActive?: boolean;
        CustomerAllowLogin?: boolean;
        CustomerShowInReport?: boolean;
        CustomerAllowTransactions?: boolean;
        RealBookingBookingNo?: string;
        RealBookingBookingDate?: string;
        RealBookingCustomerId?: number;
        RealBookingProjectId?: number;
        RealBookingPlanId?: number;
        RealBookingMembership?: number;
        RealBookingProcessing?: number;
        RealBookingBooking?: number;
        RealBookingConfirmation?: number;
        RealBookingOnPossion?: number;
        RealBookingPaymentPlanId?: number;
        RealBookingInstallments?: number;
        RealBookingInstallmentAmount?: number;
        RealBookingInstallmentStartDate?: string;
        RealBookingAllotmentNo?: string;
        RealBookingStatusId?: number;
        RealBookingCreatedBy?: number;
        RealBookingCreatedOn?: string;
        RealBookingModifiedBy?: number;
        RealBookingModifiedOn?: string;
        RealBookingVerified?: boolean;
        RealBookingLocked?: boolean;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace TasksRow {
        export const idProperty = 'TaskId';
        export const nameProperty = 'TaskNo';
        export const localTextPrefix = 'TTS.Tasks';
        export const deletePermission = 'Task Tracking:Tasks';
        export const insertPermission = 'Task Tracking:Tasks';
        export const readPermission = 'Task Tracking:Tasks';
        export const updatePermission = 'Task Tracking:Tasks';

        export declare const enum Fields {
            TaskId = "TaskId",
            TaskNo = "TaskNo",
            Subject = "Subject",
            Statusid = "Statusid",
            InitiatedById = "InitiatedById",
            OwnerId = "OwnerId",
            Notes = "Notes",
            TaskTypeId = "TaskTypeId",
            CommunicationType = "CommunicationType",
            CommunicationMethod = "CommunicationMethod",
            CustomerId = "CustomerId",
            Priority = "Priority",
            SmsAlert = "SmsAlert",
            EmailAlert = "EmailAlert",
            RealBookingId = "RealBookingId",
            ConfirmationNo = "ConfirmationNo",
            DueDate = "DueDate",
            Attachment = "Attachment",
            RateId = "RateId",
            RateComments = "RateComments",
            InitiatedByUsername = "InitiatedByUsername",
            InitiatedByDisplayName = "InitiatedByDisplayName",
            InitiatedByEmail = "InitiatedByEmail",
            InitiatedBySource = "InitiatedBySource",
            InitiatedByLastDirectoryUpdate = "InitiatedByLastDirectoryUpdate",
            InitiatedByUserImage = "InitiatedByUserImage",
            InitiatedByInsertDate = "InitiatedByInsertDate",
            InitiatedByInsertUserId = "InitiatedByInsertUserId",
            InitiatedByUpdateDate = "InitiatedByUpdateDate",
            InitiatedByUpdateUserId = "InitiatedByUpdateUserId",
            InitiatedByIsActive = "InitiatedByIsActive",
            InitiatedByMobilePhoneNumber = "InitiatedByMobilePhoneNumber",
            InitiatedByMobilePhoneVerified = "InitiatedByMobilePhoneVerified",
            InitiatedByTwoFactorAuth = "InitiatedByTwoFactorAuth",
            OwnerUsername = "OwnerUsername",
            OwnerDisplayName = "OwnerDisplayName",
            OwnerEmail = "OwnerEmail",
            OwnerSource = "OwnerSource",
            OwnerLastDirectoryUpdate = "OwnerLastDirectoryUpdate",
            OwnerUserImage = "OwnerUserImage",
            OwnerInsertDate = "OwnerInsertDate",
            OwnerInsertUserId = "OwnerInsertUserId",
            OwnerUpdateDate = "OwnerUpdateDate",
            OwnerUpdateUserId = "OwnerUpdateUserId",
            OwnerIsActive = "OwnerIsActive",
            OwnerMobilePhoneNumber = "OwnerMobilePhoneNumber",
            OwnerMobilePhoneVerified = "OwnerMobilePhoneVerified",
            OwnerTwoFactorAuth = "OwnerTwoFactorAuth",
            TaskType = "TaskType",
            TaskTypeDefaultUserId = "TaskTypeDefaultUserId",
            TaskTypeActive = "TaskTypeActive",
            TaskTypeWebEnabled = "TaskTypeWebEnabled",
            CustomerCustomerCode = "CustomerCustomerCode",
            CustomerCustomerGroupId = "CustomerCustomerGroupId",
            CustomerCustomerName = "CustomerCustomerName",
            CustomerTitleId = "CustomerTitleId",
            CustomerContactName = "CustomerContactName",
            CustomerMobileNo = "CustomerMobileNo",
            CustomerBranchId = "CustomerBranchId",
            CustomerAddress = "CustomerAddress",
            CustomerCountryId = "CustomerCountryId",
            CustomerStateId = "CustomerStateId",
            CustomerCityId = "CustomerCityId",
            CustomerCurrencyId = "CustomerCurrencyId",
            CustomerRegionId = "CustomerRegionId",
            CustomerTerritoryId = "CustomerTerritoryId",
            CustomerCreditLimit = "CustomerCreditLimit",
            CustomerPostalCode = "CustomerPostalCode",
            CustomerCountry = "CustomerCountry",
            CustomerPhoneNo = "CustomerPhoneNo",
            CustomerEmail = "CustomerEmail",
            CustomerLogo = "CustomerLogo",
            CustomerCreatedBy = "CustomerCreatedBy",
            CustomerCreatedOn = "CustomerCreatedOn",
            CustomerModifiedBy = "CustomerModifiedBy",
            CustomerModifiedOn = "CustomerModifiedOn",
            CustomerActive = "CustomerActive",
            CustomerAllowLogin = "CustomerAllowLogin",
            CustomerShowInReport = "CustomerShowInReport",
            CustomerAllowTransactions = "CustomerAllowTransactions",
            RealBookingBookingNo = "RealBookingBookingNo",
            RealBookingBookingDate = "RealBookingBookingDate",
            RealBookingCustomerId = "RealBookingCustomerId",
            RealBookingProjectId = "RealBookingProjectId",
            RealBookingPlanId = "RealBookingPlanId",
            RealBookingMembership = "RealBookingMembership",
            RealBookingProcessing = "RealBookingProcessing",
            RealBookingBooking = "RealBookingBooking",
            RealBookingConfirmation = "RealBookingConfirmation",
            RealBookingOnPossion = "RealBookingOnPossion",
            RealBookingPaymentPlanId = "RealBookingPaymentPlanId",
            RealBookingInstallments = "RealBookingInstallments",
            RealBookingInstallmentAmount = "RealBookingInstallmentAmount",
            RealBookingInstallmentStartDate = "RealBookingInstallmentStartDate",
            RealBookingAllotmentNo = "RealBookingAllotmentNo",
            RealBookingStatusId = "RealBookingStatusId",
            RealBookingCreatedBy = "RealBookingCreatedBy",
            RealBookingCreatedOn = "RealBookingCreatedOn",
            RealBookingModifiedBy = "RealBookingModifiedBy",
            RealBookingModifiedOn = "RealBookingModifiedOn",
            RealBookingVerified = "RealBookingVerified",
            RealBookingLocked = "RealBookingLocked",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
