namespace Point.Accounts {
    export interface FinancialPeriodsRow {
        PeriodId?: number;
        PeriodName?: string;
        StartDate?: string;
        EndDate?: string;
        Active?: boolean;
    }

    export namespace FinancialPeriodsRow {
        export const idProperty = 'PeriodId';
        export const nameProperty = 'PeriodName';
        export const localTextPrefix = 'Accounts.FinancialPeriods';
        export const deletePermission = 'Accounts:FinancialPeriods';
        export const insertPermission = 'Accounts:FinancialPeriods';
        export const readPermission = 'Accounts:FinancialPeriods';
        export const updatePermission = 'Accounts:FinancialPeriods';

        export declare const enum Fields {
            PeriodId = "PeriodId",
            PeriodName = "PeriodName",
            StartDate = "StartDate",
            EndDate = "EndDate",
            Active = "Active"
        }
    }
}
