namespace Point.RealEstate {
    export interface BookingsForm {
        BookingNo: Serenity.StringEditor;
        BookingDate: Serenity.DateEditor;
        CustomerId: Serenity.LookupEditor;
        ProjectId: Serenity.LookupEditor;
        PlanId: Serenity.LookupEditor;
        AllotmentNo: Serenity.StringEditor;
        Verified: Serenity.BooleanEditor;
        Locked: Serenity.BooleanEditor;
        StatusId: Serenity.EnumEditor;
        Membership: Serenity.DecimalEditor;
        Processing: Serenity.DecimalEditor;
        Booking: Serenity.DecimalEditor;
        Confirmation: Serenity.DecimalEditor;
        OnPossion: Serenity.DecimalEditor;
        PaymentPlanId: Serenity.EnumEditor;
        Installments: Serenity.IntegerEditor;
        InstallmentAmount: Serenity.DecimalEditor;
        InstallmentStartDate: Serenity.DateEditor;
        CreatedBy: Serenity.IntegerEditor;
        CreatedOn: Serenity.DateEditor;
        ModifiedBy: Serenity.IntegerEditor;
        ModifiedOn: Serenity.DateEditor;
        PlanList: BookingPlansEditor;
    }

    export class BookingsForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.Bookings';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!BookingsForm.init)  {
                BookingsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;
                var w2 = s.LookupEditor;
                var w3 = s.BooleanEditor;
                var w4 = s.EnumEditor;
                var w5 = s.DecimalEditor;
                var w6 = s.IntegerEditor;
                var w7 = BookingPlansEditor;

                Q.initFormType(BookingsForm, [
                    'BookingNo', w0,
                    'BookingDate', w1,
                    'CustomerId', w2,
                    'ProjectId', w2,
                    'PlanId', w2,
                    'AllotmentNo', w0,
                    'Verified', w3,
                    'Locked', w3,
                    'StatusId', w4,
                    'Membership', w5,
                    'Processing', w5,
                    'Booking', w5,
                    'Confirmation', w5,
                    'OnPossion', w5,
                    'PaymentPlanId', w4,
                    'Installments', w6,
                    'InstallmentAmount', w5,
                    'InstallmentStartDate', w1,
                    'CreatedBy', w6,
                    'CreatedOn', w1,
                    'ModifiedBy', w6,
                    'ModifiedOn', w1,
                    'PlanList', w7
                ]);
            }
        }
    }
}
