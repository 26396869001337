/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.RealEstate {

    @Serenity.Decorators.registerClass()
    export class BookingPlanDialog extends Common.GridEditorDialog<BookingPlansRow> {
        protected getFormKey() { return BookingPlansForm.formKey; }
        protected getLocalTextPrefix() { return BookingPlansRow.localTextPrefix; }

        protected form: BookingPlansForm;

        constructor() {
            super();

            this.form = new BookingPlansForm(this.idPrefix);

          
            //this.form.AmountDue.addValidationRule(this.uniqueName, e=> {

            //    if (this.form.AmountDue.value > 100) {
       
            //        return "Unit price must be less than 100";
            //    }
            
            //});

        }
    }
}