namespace Point.Sales {
    export interface SaleQuotationDetailsRow {
        SaleQuotationDetailID?: number;
        SaleQuotationID?: number;
        UnitPrice?: number;
        UnitCode?: string;
        Qty?: number;
        ProductName?: string;
        DiscountPers?: number;
        Discount?: number;
        TaxDetailId?: number;
        TaxDetailName?: string;
        Tax?: number;
        Total?: number;
        ProductID?: number;
        WarehouseId?: number;
    }

    export namespace SaleQuotationDetailsRow {
        export const idProperty = 'SaleQuotationDetailID';
        export const localTextPrefix = 'Sales.SaleQuotationDetails';
        export const deletePermission = 'Sales:SaleQuotationDetails';
        export const insertPermission = 'Sales:SaleQuotationDetails';
        export const readPermission = 'Sales:SaleQuotationDetails';
        export const updatePermission = 'Sales:SaleQuotationDetails';

        export declare const enum Fields {
            SaleQuotationDetailID = "SaleQuotationDetailID",
            SaleQuotationID = "SaleQuotationID",
            UnitPrice = "UnitPrice",
            UnitCode = "UnitCode",
            Qty = "Qty",
            ProductName = "ProductName",
            DiscountPers = "DiscountPers",
            Discount = "Discount",
            TaxDetailId = "TaxDetailId",
            TaxDetailName = "TaxDetailName",
            Tax = "Tax",
            Total = "Total",
            ProductID = "ProductID",
            WarehouseId = "WarehouseId"
        }
    }
}
