namespace Point.Accounts {
    export interface VoucherTypesForm {
        VoucherTypeCode: Serenity.StringEditor;
        VoucherTypeName: Serenity.StringEditor;
        VoucherFooter: Serenity.HtmlContentEditor;
    }

    export class VoucherTypesForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.VoucherTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!VoucherTypesForm.init)  {
                VoucherTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.HtmlContentEditor;

                Q.initFormType(VoucherTypesForm, [
                    'VoucherTypeCode', w0,
                    'VoucherTypeName', w0,
                    'VoucherFooter', w1
                ]);
            }
        }
    }
}
