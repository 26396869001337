namespace Point {
    export enum eAccountNature {
        Assets = 1,
        Equity = 2,
        Liability = 3,
        Revenue = 4,
        Expense = 5
    }
    Serenity.Decorators.registerEnumType(eAccountNature, 'Point.eAccountNature');
}
