/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class TaxDetailDialog extends Common.GridEditorDialog<TaxDetailsRow> {
        protected getFormKey() { return TaxDetailsForm.formKey; }
        protected getLocalTextPrefix() { return TaxDetailsRow.localTextPrefix; }

        protected form: TaxDetailsForm;

        constructor() {
            super();

            this.form = new TaxDetailsForm(this.idPrefix);

          
            ////this.form.UnitPrice.addValidationRule(this.uniqueName, e=> {

            ////    if (this.form.UnitPrice.value > 100) {
       
            ////        return "Unit price must be less than 100";
            ////    }
            
            ////});

        }
    }
}