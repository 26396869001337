namespace Point.Web.Modules.RealEstate {
    export enum PropertyArea {
        SquareFeet = 0,
        SquareYards = 1,
        SquareMeters = 2,
        Marla = 3,
        Kanal = 4
    }
    Serenity.Decorators.registerEnumType(PropertyArea, 'Point.Web.Modules.RealEstate.PropertyArea', 'Point.RealEstae.PropertyArea');
}
