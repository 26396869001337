namespace Point.Default {
    export namespace LayoutByUserdDashboardService {
        export const baseUrl = 'J/DashboardWidgetSystem';

        export declare function LoadDashboardLayout(request: LoadDashboardLayoutRequest, onSuccess?: (response: LoadDashboardLayoutResponse) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function SaveDashboardLayout(request: SaveDashboardLayoutRequest, onSuccess?: (response: SaveDashboardLayoutResponse) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;

        export declare const enum Methods {
            LoadDashboardLayout = "J/DashboardWidgetSystem/LoadDashboardLayout",
            SaveDashboardLayout = "J/DashboardWidgetSystem/SaveDashboardLayout"
        }

        [
            'LoadDashboardLayout', 
            'SaveDashboardLayout'
        ].forEach(x => {
            (<any>LayoutByUserdDashboardService)[x] = function (r, s, o) {
                return Q.serviceRequest(baseUrl + '/' + x, r, s, o);
            };
        });
    }
}
