namespace Point.HRM {
    export interface QualificationsRow {
        QualificationId?: number;
        QualificationCode?: string;
        QualificationTitle?: string;
        Active?: boolean;
    }

    export namespace QualificationsRow {
        export const idProperty = 'QualificationId';
        export const nameProperty = 'QualificationTitle';
        export const localTextPrefix = 'HRM.Qualifications';
        export const lookupKey = 'HRM.Qualifications';

        export function getLookup(): Q.Lookup<QualificationsRow> {
            return Q.getLookup<QualificationsRow>('HRM.Qualifications');
        }
        export const deletePermission = 'HRM:Qualifications';
        export const insertPermission = 'HRM:Qualifications';
        export const readPermission = 'HRM:Qualifications';
        export const updatePermission = 'HRM:Qualifications';

        export declare const enum Fields {
            QualificationId = "QualificationId",
            QualificationCode = "QualificationCode",
            QualificationTitle = "QualificationTitle",
            Active = "Active"
        }
    }
}
