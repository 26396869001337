namespace Point.Products {
    export interface BrandsRow {
        BrandId?: number;
        BrandCode?: string;
        BrandName?: string;
        Active?: boolean;
    }

    export namespace BrandsRow {
        export const idProperty = 'BrandId';
        export const nameProperty = 'BrandCode';
        export const localTextPrefix = 'Products.Brands';
        export const lookupKey = 'Products.Brands';

        export function getLookup(): Q.Lookup<BrandsRow> {
            return Q.getLookup<BrandsRow>('Products.Brands');
        }
        export const deletePermission = 'Products:Brands';
        export const insertPermission = 'Products:Brands';
        export const readPermission = 'Products:Brands';
        export const updatePermission = 'Products:Brands';

        export declare const enum Fields {
            BrandId = "BrandId",
            BrandCode = "BrandCode",
            BrandName = "BrandName",
            Active = "Active"
        }
    }
}
