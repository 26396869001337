namespace Point.BusinessPartners {
    export interface StatesRow {
        StateId?: number;
        Name?: string;
        CountryId?: number;
        Active?: boolean;
        CountryName?: string;
    }

    export namespace StatesRow {
        export const idProperty = 'StateId';
        export const nameProperty = 'Name';
        export const localTextPrefix = 'BusinessPartners.States';
        export const lookupKey = 'BusinessPartners.States';

        export function getLookup(): Q.Lookup<StatesRow> {
            return Q.getLookup<StatesRow>('BusinessPartners.States');
        }
        export const deletePermission = 'Business Partners:States';
        export const insertPermission = 'Business Partners:States';
        export const readPermission = 'Business Partners:States';
        export const updatePermission = 'Business Partners:States';

        export declare const enum Fields {
            StateId = "StateId",
            Name = "Name",
            CountryId = "CountryId",
            Active = "Active",
            CountryName = "CountryName"
        }
    }
}
