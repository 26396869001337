namespace Point.Meeting {
    export interface MeetingAgendaForm {
        Title: Serenity.StringEditor;
        Description: Serenity.HtmlReportContentEditor;
        AgendaTypeId: Serenity.LookupEditor;
        RequestedByContactId: Serenity.LookupEditor;
        AgendaNumber: Serenity.IntegerEditor;
        Images: Serenity.MultipleImageUploadEditor;
        Attachments: Serenity.MultipleImageUploadEditor;
    }

    export class MeetingAgendaForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.MeetingAgenda';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingAgendaForm.init)  {
                MeetingAgendaForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.HtmlReportContentEditor;
                var w2 = s.LookupEditor;
                var w3 = s.IntegerEditor;
                var w4 = s.MultipleImageUploadEditor;

                Q.initFormType(MeetingAgendaForm, [
                    'Title', w0,
                    'Description', w1,
                    'AgendaTypeId', w2,
                    'RequestedByContactId', w2,
                    'AgendaNumber', w3,
                    'Images', w4,
                    'Attachments', w4
                ]);
            }
        }
    }
}
