
namespace Point.Administration {

    @Serenity.Decorators.registerClass()
    export class NumbersGrid extends Serenity.EntityGrid<NumbersRow, any> {
        protected getColumnsKey() { return 'Administration.Numbers'; }
        protected getDialogType() { return NumbersDialog; }
        protected getIdProperty() { return NumbersRow.idProperty; }
        protected getInsertPermission() { return NumbersRow.insertPermission; }
        protected getLocalTextPrefix() { return NumbersRow.localTextPrefix; }
        protected getService() { return NumbersService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}