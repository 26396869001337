namespace Point.Products {
    export interface ProductSalePricesForm {
        CustomerGroupId: Serenity.LookupEditor;
        CustomerGroupName: Serenity.StringEditor;
        CustomerId: Serenity.LookupEditor;
        CustomerName: Serenity.StringEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        SalePrice: Serenity.DecimalEditor;
    }

    export class ProductSalePricesForm extends Serenity.PrefixedContext {
        static formKey = 'Products.ProductSalePrice';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ProductSalePricesForm.init)  {
                ProductSalePricesForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;
                var w3 = s.DecimalEditor;

                Q.initFormType(ProductSalePricesForm, [
                    'CustomerGroupId', w0,
                    'CustomerGroupName', w1,
                    'CustomerId', w0,
                    'CustomerName', w1,
                    'StartDate', w2,
                    'EndDate', w2,
                    'SalePrice', w3
                ]);
            }
        }
    }
}
