namespace Point.HRM {
    export interface DepartmentsRow {
        DepartmentId?: number;
        DepartmentName?: string;
    }

    export namespace DepartmentsRow {
        export const idProperty = 'DepartmentId';
        export const nameProperty = 'DepartmentName';
        export const localTextPrefix = 'Organization.Departments';
        export const lookupKey = 'Organization.Departments';

        export function getLookup(): Q.Lookup<DepartmentsRow> {
            return Q.getLookup<DepartmentsRow>('Organization.Departments');
        }
        export const deletePermission = 'HRM:Departments';
        export const insertPermission = 'HRM:Departments';
        export const readPermission = 'HRM:Departments';
        export const updatePermission = 'HRM:Departments';

        export declare const enum Fields {
            DepartmentId = "DepartmentId",
            DepartmentName = "DepartmentName"
        }
    }
}
