namespace Point {
    export enum eLeaveType {
        Annual = 0,
        Casual = 1,
        Sick = 2,
        Compensatory = 3,
        UnPaid = 4
    }
    Serenity.Decorators.registerEnumType(eLeaveType, 'Point.eLeaveType');
}
