namespace Point {
    export enum eTaskStatus {
        Open = 0,
        InProcess = 1,
        OnHold = 2,
        Completed = 3,
        Closed = 4,
        Cancelled = 5
    }
    Serenity.Decorators.registerEnumType(eTaskStatus, 'Point.eTaskStatus');
}
