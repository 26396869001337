namespace Point.Accounts {
    export interface AccountsForm {
        AccountTitle: Serenity.StringEditor;
        AccountCode: Serenity.IntegerEditor;
        ParentId: Serenity.LookupEditor;
        HeadLevel: Serenity.IntegerEditor;
        HeadType: Serenity.StringEditor;
        AllowTransactions: Serenity.BooleanEditor;
        IsGl: Serenity.BooleanEditor;
        CurrencyId: Serenity.LookupEditor;
    }

    export class AccountsForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.Accounts';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!AccountsForm.init)  {
                AccountsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.IntegerEditor;
                var w2 = s.LookupEditor;
                var w3 = s.BooleanEditor;

                Q.initFormType(AccountsForm, [
                    'AccountTitle', w0,
                    'AccountCode', w1,
                    'ParentId', w2,
                    'HeadLevel', w1,
                    'HeadType', w0,
                    'AllowTransactions', w3,
                    'IsGl', w3,
                    'CurrencyId', w2
                ]);
            }
        }
    }
}
