
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class VoucherTypesDialog extends Serenity.EntityDialog<VoucherTypesRow, any> {
        protected getFormKey() { return VoucherTypesForm.formKey; }
        protected getIdProperty() { return VoucherTypesRow.idProperty; }
        protected getLocalTextPrefix() { return VoucherTypesRow.localTextPrefix; }
        protected getNameProperty() { return VoucherTypesRow.nameProperty; }
        protected getService() { return VoucherTypesService.baseUrl; }
        protected getDeletePermission() { return VoucherTypesRow.deletePermission; }
        protected getInsertPermission() { return VoucherTypesRow.insertPermission; }
        protected getUpdatePermission() { return VoucherTypesRow.updatePermission; }

        protected form = new VoucherTypesForm(this.idPrefix);

    }
}