namespace Point.Services {
    export interface ServicesRow {
        ServiceId?: number;
        VoucherNo?: string;
        CustomerId?: number;
        Date?: string;
        Discount?: number;
        Tax?: number;
        GrossTotal?: number;
        NetTotal?: number;
        PaidAmount?: number;
        Balance?: number;
        Remarks?: string;
        PaymentAccount?: number;
        Status?: number;
        CurrencyID?: number;
        ExchangeRate?: number;
        ContactName?: string;
        CurrencyName?: string;
        RefNo?: string;
        CustomerCustomerCode?: string;
        CustomerName?: string;
        CustomerCustomerGroupId?: number;
        CustomerTitleId?: number;
        CustomerContactName?: string;
        CustomerBranchId?: number;
        CustomerAddress?: string;
        CustomerCountryId?: number;
        CustomerStateId?: number;
        CustomerCityId?: number;
        CustomerCurrencyId?: number;
        CustomerRegionId?: number;
        CustomerTerritoryId?: number;
        CustomerCreditLimit?: number;
        CustomerPostalCode?: string;
        CustomerCountry?: string;
        CustomerPhone?: string;
        CustomerLogo?: string;
        CustomerActive?: boolean;
        DetailList?: ServiceDetailsRow[];
        SupplierList?: ServiceSuppliersRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace ServicesRow {
        export const idProperty = 'ServiceId';
        export const nameProperty = 'Remarks';
        export const localTextPrefix = 'Services.Services';
        export const deletePermission = 'Services:Services';
        export const insertPermission = 'Services:Services';
        export const readPermission = 'Services:Services';
        export const updatePermission = 'Services:Services';

        export declare const enum Fields {
            ServiceId = "ServiceId",
            VoucherNo = "VoucherNo",
            CustomerId = "CustomerId",
            Date = "Date",
            Discount = "Discount",
            Tax = "Tax",
            GrossTotal = "GrossTotal",
            NetTotal = "NetTotal",
            PaidAmount = "PaidAmount",
            Balance = "Balance",
            Remarks = "Remarks",
            PaymentAccount = "PaymentAccount",
            Status = "Status",
            CurrencyID = "CurrencyID",
            ExchangeRate = "ExchangeRate",
            ContactName = "ContactName",
            CurrencyName = "CurrencyName",
            RefNo = "RefNo",
            CustomerCustomerCode = "CustomerCustomerCode",
            CustomerName = "CustomerName",
            CustomerCustomerGroupId = "CustomerCustomerGroupId",
            CustomerTitleId = "CustomerTitleId",
            CustomerContactName = "CustomerContactName",
            CustomerBranchId = "CustomerBranchId",
            CustomerAddress = "CustomerAddress",
            CustomerCountryId = "CustomerCountryId",
            CustomerStateId = "CustomerStateId",
            CustomerCityId = "CustomerCityId",
            CustomerCurrencyId = "CustomerCurrencyId",
            CustomerRegionId = "CustomerRegionId",
            CustomerTerritoryId = "CustomerTerritoryId",
            CustomerCreditLimit = "CustomerCreditLimit",
            CustomerPostalCode = "CustomerPostalCode",
            CustomerCountry = "CustomerCountry",
            CustomerPhone = "CustomerPhone",
            CustomerLogo = "CustomerLogo",
            CustomerActive = "CustomerActive",
            DetailList = "DetailList",
            SupplierList = "SupplierList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
