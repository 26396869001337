namespace Point.RealEstate {
    export interface FeatureChargesRow {
        FeatureID?: number;
        ProjectID?: number;
        Charges?: number;
        FeatureName?: string;
    }

    export namespace FeatureChargesRow {
        export const idProperty = 'FeatureID';
        export const nameProperty = 'FeatureName';
        export const localTextPrefix = 'RealEstate.FeatureCharges';
        export const deletePermission = 'RealEstate:FeatureCharges';
        export const insertPermission = 'RealEstate:FeatureCharges';
        export const readPermission = 'RealEstate:FeatureCharges';
        export const updatePermission = 'RealEstate:FeatureCharges';

        export declare const enum Fields {
            FeatureID = "FeatureID",
            ProjectID = "ProjectID",
            Charges = "Charges",
            FeatureName = "FeatureName"
        }
    }
}
