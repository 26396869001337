namespace Point.Organization {
    export interface ContactForm {
        TitleId: Serenity.LookupEditor;
        FirstName: Serenity.StringEditor;
        LastName: Serenity.StringEditor;
        CompanyName: Serenity.StringEditor;
        Email: Serenity.EmailEditor;
        Active: Serenity.BooleanEditor;
        Address: Serenity.StringEditor;
        CountryId: Serenity.LookupEditor;
        StateId: Serenity.LookupEditor;
        CityId: Serenity.LookupEditor;
        PostalCode: Serenity.StringEditor;
        PhoneNo: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class ContactForm extends Serenity.PrefixedContext {
        static formKey = 'Organization.Contact';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ContactForm.init)  {
                ContactForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.EmailEditor;
                var w3 = s.BooleanEditor;
                var w4 = s.DateEditor;

                Q.initFormType(ContactForm, [
                    'TitleId', w0,
                    'FirstName', w1,
                    'LastName', w1,
                    'CompanyName', w1,
                    'Email', w2,
                    'Active', w3,
                    'Address', w1,
                    'CountryId', w0,
                    'StateId', w0,
                    'CityId', w0,
                    'PostalCode', w1,
                    'PhoneNo', w1,
                    'InsertName', w1,
                    'InsertDate', w4,
                    'UpdateName', w1,
                    'UpdateDate', w4
                ]);
            }
        }
    }
}
