namespace Point.Accounts {
    export interface OpeningBalanceForm {
        TransactionDate: Serenity.DateEditor;
        AccountId: Serenity.LookupEditor;
        Particulars: Serenity.StringEditor;
        Debit: Serenity.DecimalEditor;
        Credit: Serenity.DecimalEditor;
        Status: Serenity.EnumEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class OpeningBalanceForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.OpeningBalance';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!OpeningBalanceForm.init)  {
                OpeningBalanceForm.init = true;

                var s = Serenity;
                var w0 = s.DateEditor;
                var w1 = s.LookupEditor;
                var w2 = s.StringEditor;
                var w3 = s.DecimalEditor;
                var w4 = s.EnumEditor;

                Q.initFormType(OpeningBalanceForm, [
                    'TransactionDate', w0,
                    'AccountId', w1,
                    'Particulars', w2,
                    'Debit', w3,
                    'Credit', w3,
                    'Status', w4,
                    'InsertName', w2,
                    'InsertDate', w0,
                    'UpdateName', w2,
                    'UpdateDate', w0
                ]);
            }
        }
    }
}
