namespace Point.HRM {
    export interface EmployeeLeavesRow {
        EmployeeLeaveId?: number;
        EmployeeId?: number;
        StartDate?: string;
        EndDate?: string;
        LeaveType?: number;
        LeaveDuration?: number;
        UnInformed?: boolean;
        LeaveApplication?: string;
        Notes?: string;
        EmployeeFirstName?: string;
        EmployeeLastName?: string;
        EmployeePayroll?: boolean;
    }

    export namespace EmployeeLeavesRow {
        export const idProperty = 'EmployeeLeaveId';
        export const nameProperty = 'EmployeeId';
        export const localTextPrefix = 'HRM.EmployeeLeaves';
        export const deletePermission = 'Human Resources:Employee Leaves';
        export const insertPermission = 'Human Resources:Employee Leaves';
        export const readPermission = 'Human Resources:Employee Leaves';
        export const updatePermission = 'Human Resources:Employee Leaves';

        export declare const enum Fields {
            EmployeeLeaveId = "EmployeeLeaveId",
            EmployeeId = "EmployeeId",
            StartDate = "StartDate",
            EndDate = "EndDate",
            LeaveType = "LeaveType",
            LeaveDuration = "LeaveDuration",
            UnInformed = "UnInformed",
            LeaveApplication = "LeaveApplication",
            Notes = "Notes",
            EmployeeFirstName = "EmployeeFirstName",
            EmployeeLastName = "EmployeeLastName",
            EmployeePayroll = "EmployeePayroll"
        }
    }
}
