
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class EmployeeLeavesDialog extends Serenity.EntityDialog<EmployeeLeavesRow, any> {
        protected getFormKey() { return EmployeeLeavesForm.formKey; }
        protected getIdProperty() { return EmployeeLeavesRow.idProperty; }
        protected getLocalTextPrefix() { return EmployeeLeavesRow.localTextPrefix; }
        protected getNameProperty() { return EmployeeLeavesRow.nameProperty; }
        protected getService() { return EmployeeLeavesService.baseUrl; }
        protected getDeletePermission() { return EmployeeLeavesRow.deletePermission; }
        protected getInsertPermission() { return EmployeeLeavesRow.insertPermission; }
        protected getUpdatePermission() { return EmployeeLeavesRow.updatePermission; }

        protected form = new EmployeeLeavesForm(this.idPrefix);

    }
}