
namespace Point.BusinessPartners {

    @Serenity.Decorators.registerClass()
    export class TitlesGrid extends Serenity.EntityGrid<TitlesRow, any> {
        protected getColumnsKey() { return 'BusinessPartners.Titles'; }
        protected getDialogType() { return TitlesDialog; }
        protected getIdProperty() { return TitlesRow.idProperty; }
        protected getInsertPermission() { return TitlesRow.insertPermission; }
        protected getLocalTextPrefix() { return TitlesRow.localTextPrefix; }
        protected getService() { return TitlesService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }


        protected getColumns() {
            var columns = super.getColumns();


            columns.splice(0, 0, {
                field: 'View Details',
                name: '',
                format: ctx => '<a class="inline-action view-details" title="View details"><i class="fa fa-pencil-square-o text-blue" aria-hidden="true"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });


            return columns;
        }


        protected onClick(e: JQueryEventObject, row: number, cell: number) {
            super.onClick(e, row, cell);

            if (e.isDefaultPrevented())
                return;

            var item = this.itemAt(row);
            var target = $(e.target);

            // if user clicks "i" element, e.g. icon
            if (target.parent().hasClass('inline-action'))
                target = target.parent();

            if (target.hasClass('inline-action')) {
                e.preventDefault();


                if (target.hasClass('view-details')) {
                    this.editItem(item.TitleId);

                }
            }
        }
v
    }
}