namespace Point.HRM {
    export interface HolidaysRow {
        HolidayId?: number;
        Title?: string;
        StartDate?: string;
        EndDate?: string;
    }

    export namespace HolidaysRow {
        export const idProperty = 'HolidayId';
        export const nameProperty = 'Title';
        export const localTextPrefix = 'HRM.Holidays';
        export const deletePermission = 'HRM:Holidays';
        export const insertPermission = 'HRM:Holidays';
        export const readPermission = 'HRM:Holidays';
        export const updatePermission = 'HRM:Holidays';

        export declare const enum Fields {
            HolidayId = "HolidayId",
            Title = "Title",
            StartDate = "StartDate",
            EndDate = "EndDate"
        }
    }
}
