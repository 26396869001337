namespace Point.Sales {
    export interface ShippersRow {
        ShipperId?: number;
        CompanyName?: string;
        Phone?: string;
    }

    export namespace ShippersRow {
        export const idProperty = 'ShipperId';
        export const nameProperty = 'CompanyName';
        export const localTextPrefix = 'Sales.Shippers';
        export const deletePermission = 'Sales:Shippers';
        export const insertPermission = 'Sales:Shippers';
        export const readPermission = 'Sales:Shippers';
        export const updatePermission = 'Sales:Shippers';

        export declare const enum Fields {
            ShipperId = "ShipperId",
            CompanyName = "CompanyName",
            Phone = "Phone"
        }
    }
}
