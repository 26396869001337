namespace Point {
    export enum eRating {
        VeryPoor = 0,
        Fair = 1,
        Good = 2,
        VeryGood = 3,
        Excellent = 4
    }
    Serenity.Decorators.registerEnumType(eRating, 'Point.eRating');
}
