
namespace Point.Purchases {

    @Serenity.Decorators.registerClass()
    export class PurchaseReturnsGrid extends Serenity.EntityGrid<PurchaseReturnsRow, any> {
        protected getColumnsKey() { return 'Purchases.PurchaseReturns'; }
        protected getDialogType() { return PurchaseReturnsDialog; }
        protected getIdProperty() { return PurchaseReturnsRow.idProperty; }
        protected getInsertPermission() { return PurchaseReturnsRow.insertPermission; }
        protected getLocalTextPrefix() { return PurchaseReturnsRow.localTextPrefix; }
        protected getService() { return PurchaseReturnsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}