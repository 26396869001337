namespace Point.Accounts {
    export interface CurrenciesRow {
        CurrencyId?: number;
        CurrencyName?: string;
        CurrencySymbol?: string;
        Active?: boolean;
    }

    export namespace CurrenciesRow {
        export const idProperty = 'CurrencyId';
        export const nameProperty = 'CurrencyName';
        export const localTextPrefix = 'Accounts.Currencies';
        export const lookupKey = 'Accounts.Currencies';

        export function getLookup(): Q.Lookup<CurrenciesRow> {
            return Q.getLookup<CurrenciesRow>('Accounts.Currencies');
        }
        export const deletePermission = 'Accounts:Currencies:Delete';
        export const insertPermission = 'Accounts:Modify Currencies';
        export const readPermission = 'Accounts:Read Currencies';
        export const updatePermission = 'Accounts:Modify Currencies';

        export declare const enum Fields {
            CurrencyId = "CurrencyId",
            CurrencyName = "CurrencyName",
            CurrencySymbol = "CurrencySymbol",
            Active = "Active"
        }
    }
}
