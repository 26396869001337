namespace Point.RealEstate {
    export interface SubTypesRow {
        SubTypeId?: number;
        SubTypeName?: string;
        TypeId?: number;
        Active?: boolean;
        TypeTypeName?: string;
        TypeActive?: boolean;
    }

    export namespace SubTypesRow {
        export const idProperty = 'SubTypeId';
        export const nameProperty = 'SubTypeName';
        export const localTextPrefix = 'RealEstate.SubTypes';
        export const lookupKey = 'RealEstate.SubTypes';

        export function getLookup(): Q.Lookup<SubTypesRow> {
            return Q.getLookup<SubTypesRow>('RealEstate.SubTypes');
        }
        export const deletePermission = 'RealEstate:Sub Types';
        export const insertPermission = 'RealEstate:Sub Types';
        export const readPermission = 'RealEstate:Sub Types';
        export const updatePermission = 'RealEstate:Sub Types';

        export declare const enum Fields {
            SubTypeId = "SubTypeId",
            SubTypeName = "SubTypeName",
            TypeId = "TypeId",
            Active = "Active",
            TypeTypeName = "TypeTypeName",
            TypeActive = "TypeActive"
        }
    }
}
