
namespace Point.Products {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class ProductTransferDialog extends Serenity.EntityDialog<ProductTransferRow, any> {
        protected getFormKey() { return ProductTransferForm.formKey; }
        protected getIdProperty() { return ProductTransferRow.idProperty; }
        protected getLocalTextPrefix() { return ProductTransferRow.localTextPrefix; }
        protected getNameProperty() { return ProductTransferRow.nameProperty; }
        protected getService() { return ProductTransferService.baseUrl; }
        protected getDeletePermission() { return ProductTransferRow.deletePermission; }
        protected getInsertPermission() { return ProductTransferRow.insertPermission; }
        protected getUpdatePermission() { return ProductTransferRow.updatePermission; }

        protected form = new ProductTransferForm(this.idPrefix);

        protected updateInterface() {
            super.updateInterface();



            var today = new Date();
            var dd = String(today.getDate());
            var mm = String(today.getMonth() + 1); //January is 0!
            var yyyy = today.getFullYear();
            var ntoday = mm + '/' + dd + '/' + yyyy;
            this.form.TransferDate.value = ntoday;

            

        }
    }
}