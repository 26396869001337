namespace Point.Default {
    export interface PendingOrdersSettingForm {
        Title: Serenity.StringEditor;
        RefreshIntervalMiliseconds: Serenity.EnumEditor;
        BoxColorClass: Serenity.EnumEditor;
        LastOrders: Serenity.IntegerEditor;
        Sound: Serenity.EnumEditor;
    }

    export class PendingOrdersSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.PendingOrdersSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!PendingOrdersSettingForm.init)  {
                PendingOrdersSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;
                var w2 = s.IntegerEditor;

                Q.initFormType(PendingOrdersSettingForm, [
                    'Title', w0,
                    'RefreshIntervalMiliseconds', w1,
                    'BoxColorClass', w1,
                    'LastOrders', w2,
                    'Sound', w1
                ]);
            }
        }
    }
}
