namespace Point.HRM {
    export interface ComplaintTypesRow {
        ComplaintTypeId?: number;
        ComplaintType?: string;
    }

    export namespace ComplaintTypesRow {
        export const idProperty = 'ComplaintTypeId';
        export const nameProperty = 'ComplaintType';
        export const localTextPrefix = 'HRM.ComplaintTypes';
        export const lookupKey = 'HRM.ComplaintTypes';

        export function getLookup(): Q.Lookup<ComplaintTypesRow> {
            return Q.getLookup<ComplaintTypesRow>('HRM.ComplaintTypes');
        }
        export const deletePermission = 'HRM:Complaint Types';
        export const insertPermission = 'HRM:Complaint Types';
        export const readPermission = 'HRM:Complaint Types';
        export const updatePermission = 'HRM:Complaint Types';

        export declare const enum Fields {
            ComplaintTypeId = "ComplaintTypeId",
            ComplaintType = "ComplaintType"
        }
    }
}
