
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class EmployeeAttendanceGrid extends Serenity.EntityGrid<EmployeeAttendanceRow, any> {
        protected getColumnsKey() { return 'HRM.EmployeeAttendance'; }
        protected getDialogType() { return EmployeeAttendanceDialog; }
        protected getIdProperty() { return EmployeeAttendanceRow.idProperty; }
        protected getInsertPermission() { return EmployeeAttendanceRow.insertPermission; }
        protected getLocalTextPrefix() { return EmployeeAttendanceRow.localTextPrefix; }
        protected getService() { return EmployeeAttendanceService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}