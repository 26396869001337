namespace Point.BusinessPartners {
    export interface CitiesRow {
        CityId?: number;
        Name?: string;
        CountryId?: number;
        StateId?: number;
        Active?: boolean;
        CountryName?: string;
        CountryActive?: boolean;
        StateName?: string;
        StateCountryId?: number;
        StateActive?: boolean;
    }

    export namespace CitiesRow {
        export const idProperty = 'CityId';
        export const nameProperty = 'Name';
        export const localTextPrefix = 'BusinessPartners.Cities';
        export const lookupKey = 'BusinessPartners.Cities';

        export function getLookup(): Q.Lookup<CitiesRow> {
            return Q.getLookup<CitiesRow>('BusinessPartners.Cities');
        }
        export const deletePermission = 'Business Partners:Cities';
        export const insertPermission = 'Business Partners:Cities';
        export const readPermission = 'Business Partners:Cities';
        export const updatePermission = 'Business Partners:Cities';

        export declare const enum Fields {
            CityId = "CityId",
            Name = "Name",
            CountryId = "CountryId",
            StateId = "StateId",
            Active = "Active",
            CountryName = "CountryName",
            CountryActive = "CountryActive",
            StateName = "StateName",
            StateCountryId = "StateCountryId",
            StateActive = "StateActive"
        }
    }
}
