namespace Point.RealEstate {
    export interface TypesRow {
        TypeId?: number;
        TypeName?: string;
        Active?: boolean;
    }

    export namespace TypesRow {
        export const idProperty = 'TypeId';
        export const nameProperty = 'TypeName';
        export const localTextPrefix = 'RealEstate.Types';
        export const lookupKey = 'RealEstate.Types';

        export function getLookup(): Q.Lookup<TypesRow> {
            return Q.getLookup<TypesRow>('RealEstate.Types');
        }
        export const deletePermission = 'RealEstate:Types';
        export const insertPermission = 'RealEstate:Types';
        export const readPermission = 'RealEstate:Types';
        export const updatePermission = 'RealEstate:Types';

        export declare const enum Fields {
            TypeId = "TypeId",
            TypeName = "TypeName",
            Active = "Active"
        }
    }
}
