namespace Point {
    export enum eCommunicationMethod {
        Telephone = 0,
        Email = 1,
        Letter = 2,
        WebForm = 3,
        LiveChat = 4
    }
    Serenity.Decorators.registerEnumType(eCommunicationMethod, 'Point.eCommunicationMethod');
}
