namespace Point.HRM {
    export interface EmployeeLeaveCreditsForm {
        TransactionDate: Serenity.DateEditor;
        LeaveType: Serenity.EnumEditor;
        Debit: Serenity.DecimalEditor;
        Credit: Serenity.DecimalEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeLeaveCreditsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeLeaveCredits';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeLeaveCreditsForm.init)  {
                EmployeeLeaveCreditsForm.init = true;

                var s = Serenity;
                var w0 = s.DateEditor;
                var w1 = s.EnumEditor;
                var w2 = s.DecimalEditor;
                var w3 = s.StringEditor;

                Q.initFormType(EmployeeLeaveCreditsForm, [
                    'TransactionDate', w0,
                    'LeaveType', w1,
                    'Debit', w2,
                    'Credit', w2,
                    'Notes', w3
                ]);
            }
        }
    }
}
