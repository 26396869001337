namespace Point.Meeting {
    export interface MeetingAttendeeForm {
        MeetingId: Serenity.IntegerEditor;
        ContactId: Serenity.IntegerEditor;
        AttendeeType: Serenity.EnumEditor;
        AttendanceStatus: Serenity.EnumEditor;
    }

    export class MeetingAttendeeForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.MeetingAttendee';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingAttendeeForm.init)  {
                MeetingAttendeeForm.init = true;

                var s = Serenity;
                var w0 = s.IntegerEditor;
                var w1 = s.EnumEditor;

                Q.initFormType(MeetingAttendeeForm, [
                    'MeetingId', w0,
                    'ContactId', w0,
                    'AttendeeType', w1,
                    'AttendanceStatus', w1
                ]);
            }
        }
    }
}
