namespace Point.Meeting {
    export interface MeetingAttendeeRow {
        AttendeeId?: number;
        MeetingId?: number;
        ContactId?: number;
        AttendeeType?: MeetingAttendeeType;
        AttendanceStatus?: MeetingAttendanceStatus;
        MeetingMeetingName?: string;
        MeetingMeetingNumber?: string;
        MeetingMeetingGuid?: string;
        MeetingMeetingTypeId?: number;
        MeetingStartDate?: string;
        MeetingEndDate?: string;
        MeetingLocationId?: number;
        MeetingUnitId?: number;
        MeetingOrganizerContactId?: number;
        MeetingReporterContactId?: number;
        MeetingInsertUserId?: number;
        MeetingInsertDate?: string;
        MeetingUpdateUserId?: number;
        MeetingUpdateDate?: string;
        ContactFirstName?: string;
        ContactLastName?: string;
        ContactFullName?: string;
        ContactEmail?: string;
    }

    export namespace MeetingAttendeeRow {
        export const idProperty = 'AttendeeId';
        export const localTextPrefix = 'Meeting.MeetingAttendee';
        export const deletePermission = 'Meeting:General';
        export const insertPermission = 'Meeting:General';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:General';

        export declare const enum Fields {
            AttendeeId = "AttendeeId",
            MeetingId = "MeetingId",
            ContactId = "ContactId",
            AttendeeType = "AttendeeType",
            AttendanceStatus = "AttendanceStatus",
            MeetingMeetingName = "MeetingMeetingName",
            MeetingMeetingNumber = "MeetingMeetingNumber",
            MeetingMeetingGuid = "MeetingMeetingGuid",
            MeetingMeetingTypeId = "MeetingMeetingTypeId",
            MeetingStartDate = "MeetingStartDate",
            MeetingEndDate = "MeetingEndDate",
            MeetingLocationId = "MeetingLocationId",
            MeetingUnitId = "MeetingUnitId",
            MeetingOrganizerContactId = "MeetingOrganizerContactId",
            MeetingReporterContactId = "MeetingReporterContactId",
            MeetingInsertUserId = "MeetingInsertUserId",
            MeetingInsertDate = "MeetingInsertDate",
            MeetingUpdateUserId = "MeetingUpdateUserId",
            MeetingUpdateDate = "MeetingUpdateDate",
            ContactFirstName = "ContactFirstName",
            ContactLastName = "ContactLastName",
            ContactFullName = "ContactFullName",
            ContactEmail = "ContactEmail"
        }
    }
}
