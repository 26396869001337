namespace Point.Products {
    export interface ProductCostPricesRow {
        ProductCostPriceID?: number;
        ProductID?: number;
        SupplierGroupId?: number;
        SupplierGroupName?: string;
        SupplierId?: number;
        SupplierName?: string;
        StartDate?: string;
        EndDate?: string;
        CostPrice?: number;
    }

    export namespace ProductCostPricesRow {
        export const idProperty = 'ProductCostPriceID';
        export const localTextPrefix = 'Products.ProductCostPrices';
        export const deletePermission = 'Products:ProductCostPrices';
        export const insertPermission = 'Products:ProductCostPrices';
        export const readPermission = 'Products:ProductCostPrices';
        export const updatePermission = 'Products:ProductCostPrices';

        export declare const enum Fields {
            ProductCostPriceID = "ProductCostPriceID",
            ProductID = "ProductID",
            SupplierGroupId = "SupplierGroupId",
            SupplierGroupName = "SupplierGroupName",
            SupplierId = "SupplierId",
            SupplierName = "SupplierName",
            StartDate = "StartDate",
            EndDate = "EndDate",
            CostPrice = "CostPrice"
        }
    }
}
