namespace Point.HRM {
    export interface TrainingsRow {
        TrainingId?: number;
        TrainingCode?: string;
        TrainingTitle?: string;
        Active?: boolean;
    }

    export namespace TrainingsRow {
        export const idProperty = 'TrainingId';
        export const nameProperty = 'TrainingTitle';
        export const localTextPrefix = 'HRM.Trainings';
        export const lookupKey = 'HRM.Trainings';

        export function getLookup(): Q.Lookup<TrainingsRow> {
            return Q.getLookup<TrainingsRow>('HRM.Trainings');
        }
        export const deletePermission = 'HRM:Trainings';
        export const insertPermission = 'HRM:Trainings';
        export const readPermission = 'HRM:Trainings';
        export const updatePermission = 'HRM:Trainings';

        export declare const enum Fields {
            TrainingId = "TrainingId",
            TrainingCode = "TrainingCode",
            TrainingTitle = "TrainingTitle",
            Active = "Active"
        }
    }
}
