namespace Point {
    export enum eProcessClose {
        Pending = 0,
        InProcess = 1,
        Onhold = 2,
        Completed = 3,
        Cancelled = 4
    }
    Serenity.Decorators.registerEnumType(eProcessClose, 'Point.eProcessClose');
}
