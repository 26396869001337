namespace Point.Administration {
    export interface BranchForm {
        BranchNo: Serenity.StringEditor;
        BranchCode: Serenity.StringEditor;
        BranchName: Serenity.StringEditor;
        HeadOffice: Serenity.BooleanEditor;
    }

    export class BranchForm extends Serenity.PrefixedContext {
        static formKey = 'Administration.Branch';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!BranchForm.init)  {
                BranchForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(BranchForm, [
                    'BranchNo', w0,
                    'BranchCode', w0,
                    'BranchName', w0,
                    'HeadOffice', w1
                ]);
            }
        }
    }
}
