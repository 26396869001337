namespace Point.HRM {
    export interface DesignationsRow {
        DesignationId?: number;
        DesignationName?: string;
    }

    export namespace DesignationsRow {
        export const idProperty = 'DesignationId';
        export const nameProperty = 'DesignationName';
        export const localTextPrefix = 'HRM.Designations';
        export const lookupKey = 'HRM.Designations';

        export function getLookup(): Q.Lookup<DesignationsRow> {
            return Q.getLookup<DesignationsRow>('HRM.Designations');
        }
        export const deletePermission = 'HRM:Designations';
        export const insertPermission = 'HRM:Designations';
        export const readPermission = 'HRM:Designations';
        export const updatePermission = 'HRM:Designations';

        export declare const enum Fields {
            DesignationId = "DesignationId",
            DesignationName = "DesignationName"
        }
    }
}
