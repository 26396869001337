namespace Point.Sales {
    export interface SaleInvoiceDetailsForm {
        ProductID: Serenity.LookupEditor;
        ProductName: Serenity.StringEditor;
        UnitPrice: Serenity.DecimalEditor;
        WarehouseId: Serenity.LookupEditor;
        TaxDetailName: Serenity.StringEditor;
        Qty: Serenity.DecimalEditor;
        DiscountPers: Serenity.DecimalEditor;
        Discount: Serenity.DecimalEditor;
        TaxDetailId: Serenity.LookupEditor;
        Tax: Serenity.DecimalEditor;
        Total: Serenity.DecimalEditor;
        UnitCode: Serenity.StringEditor;
    }

    export class SaleInvoiceDetailsForm extends Serenity.PrefixedContext {
        static formKey = 'Sales.SaleInvoiceDetail';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SaleInvoiceDetailsForm.init)  {
                SaleInvoiceDetailsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DecimalEditor;

                Q.initFormType(SaleInvoiceDetailsForm, [
                    'ProductID', w0,
                    'ProductName', w1,
                    'UnitPrice', w2,
                    'WarehouseId', w0,
                    'TaxDetailName', w1,
                    'Qty', w2,
                    'DiscountPers', w2,
                    'Discount', w2,
                    'TaxDetailId', w0,
                    'Tax', w2,
                    'Total', w2,
                    'UnitCode', w1
                ]);
            }
        }
    }
}
