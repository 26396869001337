
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class AccountTypesDialog extends Serenity.EntityDialog<AccountTypesRow, any> {
        protected getFormKey() { return AccountTypesForm.formKey; }
        protected getIdProperty() { return AccountTypesRow.idProperty; }
        protected getLocalTextPrefix() { return AccountTypesRow.localTextPrefix; }
        protected getNameProperty() { return AccountTypesRow.nameProperty; }
        protected getService() { return AccountTypesService.baseUrl; }
        protected getDeletePermission() { return AccountTypesRow.deletePermission; }
        protected getInsertPermission() { return AccountTypesRow.insertPermission; }
        protected getUpdatePermission() { return AccountTypesRow.updatePermission; }

        protected form = new AccountTypesForm(this.idPrefix);

    }
}