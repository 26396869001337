
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class EmployeeAttendanceDialog extends Serenity.EntityDialog<EmployeeAttendanceRow, any> {
        protected getFormKey() { return EmployeeAttendanceForm.formKey; }
        protected getIdProperty() { return EmployeeAttendanceRow.idProperty; }
        protected getLocalTextPrefix() { return EmployeeAttendanceRow.localTextPrefix; }
        protected getNameProperty() { return EmployeeAttendanceRow.nameProperty; }
        protected getService() { return EmployeeAttendanceService.baseUrl; }
        protected getDeletePermission() { return EmployeeAttendanceRow.deletePermission; }
        protected getInsertPermission() { return EmployeeAttendanceRow.insertPermission; }
        protected getUpdatePermission() { return EmployeeAttendanceRow.updatePermission; }

        protected form = new EmployeeAttendanceForm(this.idPrefix);

    }
}