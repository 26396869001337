namespace Point.Services {
    export interface ServicesForm {
        VoucherNo: Serenity.StringEditor;
        Date: Serenity.DateEditor;
        CustomerId: Serenity.LookupEditor;
        Status: Serenity.EnumEditor;
        CurrencyID: Serenity.LookupEditor;
        ExchangeRate: Serenity.DecimalEditor;
        ContactName: Serenity.StringEditor;
        RefNo: Serenity.StringEditor;
        Remarks: Serenity.StringEditor;
        DetailList: ServiceDetailsEditor;
        GrossTotal: Serenity.DecimalEditor;
        Discount: Serenity.DecimalEditor;
        Tax: Serenity.DecimalEditor;
        NetTotal: Serenity.DecimalEditor;
        PaymentAccount: Serenity.StringEditor;
        PaidAmount: Serenity.DecimalEditor;
        Balance: Serenity.DecimalEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
        SupplierList: ServiceSuppliersEditor;
    }

    export class ServicesForm extends Serenity.PrefixedContext {
        static formKey = 'Services.Services';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ServicesForm.init)  {
                ServicesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;
                var w2 = s.LookupEditor;
                var w3 = s.EnumEditor;
                var w4 = s.DecimalEditor;
                var w5 = ServiceDetailsEditor;
                var w6 = ServiceSuppliersEditor;

                Q.initFormType(ServicesForm, [
                    'VoucherNo', w0,
                    'Date', w1,
                    'CustomerId', w2,
                    'Status', w3,
                    'CurrencyID', w2,
                    'ExchangeRate', w4,
                    'ContactName', w0,
                    'RefNo', w0,
                    'Remarks', w0,
                    'DetailList', w5,
                    'GrossTotal', w4,
                    'Discount', w4,
                    'Tax', w4,
                    'NetTotal', w4,
                    'PaymentAccount', w0,
                    'PaidAmount', w4,
                    'Balance', w4,
                    'InsertName', w0,
                    'InsertDate', w1,
                    'UpdateName', w0,
                    'UpdateDate', w1,
                    'SupplierList', w6
                ]);
            }
        }
    }
}
