namespace Point.Sales {
    export interface SaleReturnsForm {
        ReturnNo: Serenity.StringEditor;
        ReturnDate: Serenity.DateEditor;
        DueDate: Serenity.DateEditor;
        CustomerId: Serenity.LookupEditor;
        CurrencyID: Serenity.LookupEditor;
        ExchangeRate: Serenity.DecimalEditor;
        ContactName: Serenity.StringEditor;
        RefNo: Serenity.StringEditor;
        Status: Serenity.EnumEditor;
        Notes: Serenity.StringEditor;
        DetailList: SaleReturnDetailsEditor;
        SubTotal: Serenity.DecimalEditor;
        Discount: Serenity.DecimalEditor;
        Tax: Serenity.DecimalEditor;
        Total: Serenity.DecimalEditor;
        PaymentAccount: Serenity.StringEditor;
        Paid: Serenity.DecimalEditor;
        Balance: Serenity.DecimalEditor;
        Verified: Serenity.BooleanEditor;
        VerifiedBy: Serenity.IntegerEditor;
        VerifiedOn: Serenity.IntegerEditor;
        Locked: Serenity.BooleanEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
        Attachment: Serenity.ImageUploadEditor;
    }

    export class SaleReturnsForm extends Serenity.PrefixedContext {
        static formKey = 'Sales.SaleReturns';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SaleReturnsForm.init)  {
                SaleReturnsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.DateEditor;
                var w2 = s.LookupEditor;
                var w3 = s.DecimalEditor;
                var w4 = s.EnumEditor;
                var w5 = SaleReturnDetailsEditor;
                var w6 = s.BooleanEditor;
                var w7 = s.IntegerEditor;
                var w8 = s.ImageUploadEditor;

                Q.initFormType(SaleReturnsForm, [
                    'ReturnNo', w0,
                    'ReturnDate', w1,
                    'DueDate', w1,
                    'CustomerId', w2,
                    'CurrencyID', w2,
                    'ExchangeRate', w3,
                    'ContactName', w0,
                    'RefNo', w0,
                    'Status', w4,
                    'Notes', w0,
                    'DetailList', w5,
                    'SubTotal', w3,
                    'Discount', w3,
                    'Tax', w3,
                    'Total', w3,
                    'PaymentAccount', w0,
                    'Paid', w3,
                    'Balance', w3,
                    'Verified', w6,
                    'VerifiedBy', w7,
                    'VerifiedOn', w7,
                    'Locked', w6,
                    'InsertName', w0,
                    'InsertDate', w1,
                    'UpdateName', w0,
                    'UpdateDate', w1,
                    'Attachment', w8
                ]);
            }
        }
    }
}
