namespace Point.HRM {
    export interface QualificationsForm {
        QualificationCode: Serenity.StringEditor;
        QualificationTitle: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class QualificationsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.Qualifications';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!QualificationsForm.init)  {
                QualificationsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(QualificationsForm, [
                    'QualificationCode', w0,
                    'QualificationTitle', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
