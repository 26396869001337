namespace Point.Default {
    import Jdw = J.DashboardWidget;

    export class QuickButtonsSettingParams {
        Title: string;
        NewContact: boolean;
        NewCustomer: boolean;
        NewVoucher: boolean;
        DayBook: boolean;
        NewService: boolean;
        Attendance: boolean;
        Products: boolean;
        NewInvoice: boolean;
        NewTask: boolean;
        ViewOrders: boolean;
        




       
    }

    export class QuickButtons extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<QuickButtonsSettingParams>> {
        protected getContentSettingDialogType() { return QuickButtonsSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:QuickButtonsView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:QuickButtonsModify"; }

        constructor(opt: Jdw.BaseDashboardWidgetOptions<QuickButtonsSettingParams>) {
            super(opt);

         

            //this.refreshIntervalMiliseconds = 1000;

            //setTimeout(() => this.canShowRefreshLoadingIndicator = () => true, 5000);
            //setTimeout(() => this.canShowRefreshLoadingIndicator = () => false, 15000);            
        }

        protected buildWidgetData(widgetContentParams: QuickButtonsSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams);

            this.updateTitlebar(widgetContentParams);

            let shtml = "";

            if (widgetContentParams.NewContact)
                shtml += " <a href='/Organization/Contact#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-user-plus' style='margin-bottom:5px'></i> New Contact </a>";

            if (widgetContentParams.NewCustomer)
                shtml += " <a href='/BusinessPartners/Customers#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-newspaper-o' style='margin-bottom:5px'></i> New Customer </a>";

            if (widgetContentParams.NewVoucher)
                shtml += " <a href='/Accounts/Vouchers#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-edit' style='margin-bottom:5px'></i> New Voucher </a>";

            if (widgetContentParams.DayBook)
                shtml += " <a href='/Reports/DayBook' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-calendar' style='margin-bottom:5px'></i> Day Book </a>";

            if (widgetContentParams.NewService)
                shtml += " <a href='/Services/Services#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-magic' style='margin-bottom:5px'></i> New Service </a>";

            if (widgetContentParams.Attendance)
                shtml += " <a href='/HRM/EmployeeAttendance#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-ticket' style='margin-bottom:5px'></i> Attendance </a>";

            if (widgetContentParams.Products)
                shtml += " <a href='/Products/Products' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-diamond' style='margin-bottom:5px'></i> Products </a>";

            if (widgetContentParams.NewInvoice)
                shtml += " <a href='/Sales/SaleInvoices#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-spinner' style='margin-bottom:5px'></i> New Invoice </a>";

            if (widgetContentParams.NewTask)
                shtml += " <a href='/TTS/Tasks#new' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-calendar-plus-o' style='margin-bottom:5px'></i> New Task </a>";


            if (widgetContentParams.ViewOrders)
                shtml += " <a href='/Sales/SaleOrders' class='btn btn-app' style='width: 100px;height:70px;'> <i class='fa fa-shopping-cart' style='margin-bottom:5px'></i> View Orders </a>";


            this.byId("buttons").html(shtml);



            return;

            //return $.get(`https://jsonplaceholder.typicode.com/todos/1`, (data) => {
               
            //}).then(() => { });
        }

        protected updateTitlebar(widgetContentParams: QuickButtonsSettingParams) {
            let widgetName = "";
            if (Q.trimToNull(widgetContentParams.Title) == null) {
                widgetName = Q.coalesce(this.getWidgetTitle(), "&nbsp;");
            }
            else {
                widgetName = widgetContentParams.Title;
            }

            this.byId("title").text($("<div />").html(widgetName).text());
            //this.byId("titleIcon").attr('class', `${widgetContentParams.TitleIconClass}`);
        }

        protected embedWidgetToDialog(dlg: Jdw.BaseWidgetViewInDialog<Jdw.BaseWidgetViewInDialogOptions>, wg: Jdw.BaseDashboardWidget<any>) {
            dlg.element.append(wg.element.find(".box-body"));
            dlg.dialogOpen();
        }

        protected canShowLoadingOverlayWhenRefresh() {
            return false;
        }

        protected canShowRefreshLoadingIndicator() {
            return false;
        }

        protected getWidgetTitle() {
            return "Quick Buttons";
        }
    }
}