namespace Point.HRM {
    export interface DesignationsForm {
        DesignationName: Serenity.StringEditor;
    }

    export class DesignationsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.Designations';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!DesignationsForm.init)  {
                DesignationsForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;

                Q.initFormType(DesignationsForm, [
                    'DesignationName', w0
                ]);
            }
        }
    }
}
