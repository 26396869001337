
namespace Point.Meeting {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class MeetingDecisionDialog extends Serenity.EntityDialog<MeetingDecisionRow, any> {
        protected getFormKey() { return MeetingDecisionForm.formKey; }
        protected getIdProperty() { return MeetingDecisionRow.idProperty; }
        protected getLocalTextPrefix() { return MeetingDecisionRow.localTextPrefix; }
        protected getNameProperty() { return MeetingDecisionRow.Fields.DecisionNumber; }
        protected getService() { return MeetingDecisionService.baseUrl; }

        protected form = new MeetingDecisionForm(this.idPrefix);

        protected loadEntity(entity: MeetingDecisionRow) {
            this.form.AgendaId.meetingId = entity.MeetingId;
            super.loadEntity(entity);
        }

        protected getSaveEntity() {
            var se = super.getSaveEntity();
            se.MeetingId = Q.coalesce(se.MeetingId, this.entity.MeetingId);
            return se;
        }
    }
}