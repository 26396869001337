namespace Point.HRM {
    export interface EmployeeTrainingsRow {
        EmployeeTrainingID?: number;
        EmployeeID?: number;
        Notes?: string;
        InstitutionName?: string;
        CompletedOn?: string;
        TrainingId?: number;
        TrainingTitle?: string;
    }

    export namespace EmployeeTrainingsRow {
        export const idProperty = 'EmployeeTrainingID';
        export const localTextPrefix = 'HRM.EmployeeTrainings';
        export const deletePermission = 'HRM:EmployeeTrainings';
        export const insertPermission = 'HRM:EmployeeTrainings';
        export const readPermission = 'HRM:EmployeeTrainings';
        export const updatePermission = 'HRM:EmployeeTrainings';

        export declare const enum Fields {
            EmployeeTrainingID = "EmployeeTrainingID",
            EmployeeID = "EmployeeID",
            Notes = "Notes",
            InstitutionName = "InstitutionName",
            CompletedOn = "CompletedOn",
            TrainingId = "TrainingId",
            TrainingTitle = "TrainingTitle"
        }
    }
}
