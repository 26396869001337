namespace Point.BusinessPartners {
    export interface RegionsRow {
        RegionId?: number;
        RegionName?: string;
    }

    export namespace RegionsRow {
        export const idProperty = 'RegionId';
        export const nameProperty = 'RegionName';
        export const localTextPrefix = 'BusinessPartners.Regions';
        export const lookupKey = 'BusinessPartners.Regions';

        export function getLookup(): Q.Lookup<RegionsRow> {
            return Q.getLookup<RegionsRow>('BusinessPartners.Regions');
        }
        export const deletePermission = 'BusinessPartners:Regions';
        export const insertPermission = 'BusinessPartners:Regions';
        export const readPermission = 'BusinessPartners:Regions';
        export const updatePermission = 'BusinessPartners:Regions';

        export declare const enum Fields {
            RegionId = "RegionId",
            RegionName = "RegionName"
        }
    }
}
