namespace Point.HRM {
    export interface EmployeeQualificationsForm {
        QualificationId: Serenity.LookupEditor;
        QualificationTitle: Serenity.StringEditor;
        InstitutionName: Serenity.StringEditor;
        GradeDivision: Serenity.StringEditor;
        CompletedOn: Serenity.DateEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeQualificationsForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeQualifications';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeQualificationsForm.init)  {
                EmployeeQualificationsForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;

                Q.initFormType(EmployeeQualificationsForm, [
                    'QualificationId', w0,
                    'QualificationTitle', w1,
                    'InstitutionName', w1,
                    'GradeDivision', w1,
                    'CompletedOn', w2,
                    'Notes', w1
                ]);
            }
        }
    }
}
