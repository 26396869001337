namespace Point.Meeting {
    export interface MeetingDecisionForm {
        Description: Serenity.HtmlReportContentEditor;
        DueDate: Serenity.DateEditor;
        ResponsibleContactId: Serenity.LookupEditor;
        DecisionNumber: Serenity.IntegerEditor;
        AgendaId: MeetingAgendaEditor;
        ResolutionStatus: Serenity.EnumEditor;
        Images: Serenity.MultipleImageUploadEditor;
        Attachments: Serenity.MultipleImageUploadEditor;
    }

    export class MeetingDecisionForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.MeetingDecision';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingDecisionForm.init)  {
                MeetingDecisionForm.init = true;

                var s = Serenity;
                var w0 = s.HtmlReportContentEditor;
                var w1 = s.DateEditor;
                var w2 = s.LookupEditor;
                var w3 = s.IntegerEditor;
                var w4 = MeetingAgendaEditor;
                var w5 = s.EnumEditor;
                var w6 = s.MultipleImageUploadEditor;

                Q.initFormType(MeetingDecisionForm, [
                    'Description', w0,
                    'DueDate', w1,
                    'ResponsibleContactId', w2,
                    'DecisionNumber', w3,
                    'AgendaId', w4,
                    'ResolutionStatus', w5,
                    'Images', w6,
                    'Attachments', w6
                ]);
            }
        }
    }
}
