namespace Point.Meeting {
    export interface MeetingForm {
        MeetingName: Serenity.StringEditor;
        MeetingTypeId: Serenity.LookupEditor;
        MeetingNumber: Serenity.StringEditor;
        StartDate: Serenity.DateTimeEditor;
        EndDate: Serenity.DateTimeEditor;
        LocationId: Serenity.LookupEditor;
        UnitId: Organization.BusinessUnitEditor;
        OrganizerContactId: Serenity.LookupEditor;
        ReporterContactId: Serenity.LookupEditor;
        AttendeeList: MeetingAttendeeEditor;
    }

    export class MeetingForm extends Serenity.PrefixedContext {
        static formKey = 'Meeting.Meeting';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MeetingForm.init)  {
                MeetingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.DateTimeEditor;
                var w3 = Organization.BusinessUnitEditor;
                var w4 = MeetingAttendeeEditor;

                Q.initFormType(MeetingForm, [
                    'MeetingName', w0,
                    'MeetingTypeId', w1,
                    'MeetingNumber', w0,
                    'StartDate', w2,
                    'EndDate', w2,
                    'LocationId', w1,
                    'UnitId', w3,
                    'OrganizerContactId', w1,
                    'ReporterContactId', w1,
                    'AttendeeList', w4
                ]);
            }
        }
    }
}
