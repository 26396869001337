
namespace Point.Default {
    import Jdw = J.DashboardWidget;

    export class CashInBankDialogView extends Jdw.BaseWidgetViewInDialog<J.DashboardWidget.BaseWidgetViewInDialogOptions> {

        private isInitRefresh: boolean = false;
        constructor(opt: any) {
            super(opt);

            let contentParams: CashInBankSettingParams = this.options.widgetContentParams || (this.options.widget.widgetContentParamsData || { RefreshIntervalMiliseconds: 60000 });

            // if interval less than 1000ms (1 second) then set it to 1000
            // should not set interval too small
            // minimum interval is 1000ms
            let interval = contentParams.RefreshIntervalMiliseconds || 60000;
            if (interval < 60000) {
                interval = 60000;
            }

            if (this.options.widget) {
                if (!this.isInitRefresh) {
                    let self = this;
                    (function execCallback() {
                        setTimeout(function () {
                            if (self.options.widget) {
                                self.options.widget.refreshWidget(self, () => {
                                    execCallback();
                                });
                            }
                        }, interval);
                    })();

                    this.isInitRefresh = true;
                }
            }            
        }
    }
}