
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class QualificationsGrid extends Serenity.EntityGrid<QualificationsRow, any> {
        protected getColumnsKey() { return 'HRM.Qualifications'; }
        protected getDialogType() { return QualificationsDialog; }
        protected getIdProperty() { return QualificationsRow.idProperty; }
        protected getInsertPermission() { return QualificationsRow.insertPermission; }
        protected getLocalTextPrefix() { return QualificationsRow.localTextPrefix; }
        protected getService() { return QualificationsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}