namespace Point.Services {
    export interface ServiceSuppliersRow {
        ServiceSupplierID?: number;
        ServiceID?: number;
        Cost?: number;
        CurrencyID?: number;
        SupplierID?: number;
        ExchangeRate?: number;
        RefNo?: string;
        Description?: string;
        SupplierName?: string;
        CurrencyName?: string;
    }

    export namespace ServiceSuppliersRow {
        export const idProperty = 'ServiceSupplierID';
        export const localTextPrefix = 'Services.ServiceSuppliers';
        export const deletePermission = 'Services:ServiceSuppliers';
        export const insertPermission = 'Services:ServiceSuppliers';
        export const readPermission = 'Services:ServiceSuppliers';
        export const updatePermission = 'Services:ServiceSuppliers';

        export declare const enum Fields {
            ServiceSupplierID = "ServiceSupplierID",
            ServiceID = "ServiceID",
            Cost = "Cost",
            CurrencyID = "CurrencyID",
            SupplierID = "SupplierID",
            ExchangeRate = "ExchangeRate",
            RefNo = "RefNo",
            Description = "Description",
            SupplierName = "SupplierName",
            CurrencyName = "CurrencyName"
        }
    }
}
