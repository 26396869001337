namespace Point.TTS {
    export interface TasksForm {
        TaskNo: Serenity.StringEditor;
        TaskTypeId: Serenity.LookupEditor;
        Subject: Serenity.StringEditor;
        Statusid: Serenity.EnumEditor;
        InitiatedById: Serenity.LookupEditor;
        OwnerId: Serenity.LookupEditor;
        CommunicationType: Serenity.EnumEditor;
        CommunicationMethod: Serenity.EnumEditor;
        CustomerId: Serenity.LookupEditor;
        RealBookingId: Serenity.LookupEditor;
        Priority: Serenity.EnumEditor;
        SmsAlert: Serenity.BooleanEditor;
        EmailAlert: Serenity.BooleanEditor;
        ConfirmationNo: Serenity.StringEditor;
        DueDate: Serenity.DateEditor;
        Notes: Serenity.StringEditor;
        Attachment: Serenity.ImageUploadEditor;
        RateId: Serenity.EnumEditor;
        RateComments: Serenity.StringEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class TasksForm extends Serenity.PrefixedContext {
        static formKey = 'TTS.Tasks';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TasksForm.init)  {
                TasksForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.EnumEditor;
                var w3 = s.BooleanEditor;
                var w4 = s.DateEditor;
                var w5 = s.ImageUploadEditor;

                Q.initFormType(TasksForm, [
                    'TaskNo', w0,
                    'TaskTypeId', w1,
                    'Subject', w0,
                    'Statusid', w2,
                    'InitiatedById', w1,
                    'OwnerId', w1,
                    'CommunicationType', w2,
                    'CommunicationMethod', w2,
                    'CustomerId', w1,
                    'RealBookingId', w1,
                    'Priority', w2,
                    'SmsAlert', w3,
                    'EmailAlert', w3,
                    'ConfirmationNo', w0,
                    'DueDate', w4,
                    'Notes', w0,
                    'Attachment', w5,
                    'RateId', w2,
                    'RateComments', w0,
                    'InsertName', w0,
                    'InsertDate', w4,
                    'UpdateName', w0,
                    'UpdateDate', w4
                ]);
            }
        }
    }
}
