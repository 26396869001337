namespace Point.Sales {
    export interface MastersRow {
        MasterId?: number;
        MasterDate?: string;
        DetailList?: MasterDetailsRow[];
    }

    export namespace MastersRow {
        export const idProperty = 'MasterId';
        export const localTextPrefix = 'Sales.Masters';
        export const deletePermission = 'Sales:Masters';
        export const insertPermission = 'Sales:Masters';
        export const readPermission = 'Sales:Masters';
        export const updatePermission = 'Sales:Masters';

        export declare const enum Fields {
            MasterId = "MasterId",
            MasterDate = "MasterDate",
            DetailList = "DetailList"
        }
    }
}
