
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class WorkingHoursDialog extends Serenity.EntityDialog<WorkingHoursRow, any> {
        protected getFormKey() { return WorkingHoursForm.formKey; }
        protected getIdProperty() { return WorkingHoursRow.idProperty; }
        protected getLocalTextPrefix() { return WorkingHoursRow.localTextPrefix; }
        protected getNameProperty() { return WorkingHoursRow.nameProperty; }
        protected getService() { return WorkingHoursService.baseUrl; }
        protected getDeletePermission() { return WorkingHoursRow.deletePermission; }
        protected getInsertPermission() { return WorkingHoursRow.insertPermission; }
        protected getUpdatePermission() { return WorkingHoursRow.updatePermission; }

        protected form = new WorkingHoursForm(this.idPrefix);

    }
}