namespace Point.HRM {
    export namespace EmployeeSalaryService {
        export const baseUrl = 'HRM/EmployeeSalary';

        export declare function Retrieve(request: Serenity.RetrieveRequest, onSuccess?: (response: Serenity.RetrieveResponse<EmployeeSalariesRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function List(request: Serenity.ListRequest, onSuccess?: (response: Serenity.ListResponse<EmployeeSalariesRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function GetBasic(request: Serenity.ListRequest, onSuccess?: (response: EmployeeSalariesRow) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;

        export declare const enum Methods {
            Retrieve = "HRM/EmployeeSalary/Retrieve",
            List = "HRM/EmployeeSalary/List",
            GetBasic = "HRM/EmployeeSalary/GetBasic"
        }

        [
            'Retrieve', 
            'List', 
            'GetBasic'
        ].forEach(x => {
            (<any>EmployeeSalaryService)[x] = function (r, s, o) {
                return Q.serviceRequest(baseUrl + '/' + x, r, s, o);
            };
        });
    }
}
