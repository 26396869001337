namespace Point.HRM {
    export interface WorkingHoursRow {
        WorkingHourId?: number;
        Title?: string;
        StartDatet?: string;
        EndDate?: string;
        StartTime?: number;
        EndTime?: number;
        SaturdayOff?: boolean;
        BranchId?: number;
        BranchBranchNo?: string;
        BranchBranchCode?: string;
        BranchBranchName?: string;
        StartTimeName?: string;
        EndTimeName?: string;
    }

    export namespace WorkingHoursRow {
        export const idProperty = 'WorkingHourId';
        export const nameProperty = 'Title';
        export const localTextPrefix = 'HRM.WorkingHours';
        export const deletePermission = 'HRM:WorkingHours';
        export const insertPermission = 'HRM:WorkingHours';
        export const readPermission = 'HRM:WorkingHours';
        export const updatePermission = 'HRM:WorkingHours';

        export declare const enum Fields {
            WorkingHourId = "WorkingHourId",
            Title = "Title",
            StartDatet = "StartDatet",
            EndDate = "EndDate",
            StartTime = "StartTime",
            EndTime = "EndTime",
            SaturdayOff = "SaturdayOff",
            BranchId = "BranchId",
            BranchBranchNo = "BranchBranchNo",
            BranchBranchCode = "BranchBranchCode",
            BranchBranchName = "BranchBranchName",
            StartTimeName = "StartTimeName",
            EndTimeName = "EndTimeName"
        }
    }
}
