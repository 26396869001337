namespace Point.HRM {
    export interface EmployeeAttendanceForm {
        EmployeeId: Serenity.LookupEditor;
        Checkin: Serenity.DateTimeEditor;
        Checkout: Serenity.DateTimeEditor;
        Notes: Serenity.StringEditor;
    }

    export class EmployeeAttendanceForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.EmployeeAttendance';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!EmployeeAttendanceForm.init)  {
                EmployeeAttendanceForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.DateTimeEditor;
                var w2 = s.StringEditor;

                Q.initFormType(EmployeeAttendanceForm, [
                    'EmployeeId', w0,
                    'Checkin', w1,
                    'Checkout', w1,
                    'Notes', w2
                ]);
            }
        }
    }
}
