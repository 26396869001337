namespace Point.RealEstate {
    export interface BookingPlansForm {
        BookingPlanID: Serenity.IntegerEditor;
        SNO: Serenity.IntegerEditor;
        Title: Serenity.StringEditor;
        InstallmentNo: Serenity.IntegerEditor;
        DueDate: Serenity.DateEditor;
        AmountDue: Serenity.DecimalEditor;
        ReceiptDate: Serenity.DateEditor;
        AmountReceived: Serenity.DecimalEditor;
        Attachment: Serenity.ImageUploadEditor;
    }

    export class BookingPlansForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.BookingPlan';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!BookingPlansForm.init)  {
                BookingPlansForm.init = true;

                var s = Serenity;
                var w0 = s.IntegerEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;
                var w3 = s.DecimalEditor;
                var w4 = s.ImageUploadEditor;

                Q.initFormType(BookingPlansForm, [
                    'BookingPlanID', w0,
                    'SNO', w0,
                    'Title', w1,
                    'InstallmentNo', w0,
                    'DueDate', w2,
                    'AmountDue', w3,
                    'ReceiptDate', w2,
                    'AmountReceived', w3,
                    'Attachment', w4
                ]);
            }
        }
    }
}
