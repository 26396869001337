/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeLeaveCreditDialog extends Common.GridEditorDialog<EmployeeLeaveCreditsRow> {
        protected getFormKey() { return EmployeeLeaveCreditsForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeLeaveCreditsRow.localTextPrefix; }

        protected form: EmployeeLeaveCreditsForm;

        constructor() {
            super();

            this.form = new EmployeeLeaveCreditsForm(this.idPrefix);

          
         
            


        }


    
    }
}