namespace Point.Common {

    export function EnableFullSizeGrid() {
        $('#GridDiv .grid-title .title-text').append('<a href="#" id="panel-fullscreen" class="pull-left" role="button" title="Toggle fullscreen"><i class="glyphicon glyphicon-resize-full"></i></a>');
        $('#panel-fullscreen').click(function (e) {
            $('#GridDiv').toggleClass('fullscreen');
            Q.initFullHeightGridPage($('#GridDiv'));
        });
    }


}




