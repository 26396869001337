namespace Point.Default {
    import Jdw = J.DashboardWidget;


    export class PendingOrdersSettingParams {
        Title?: string;
        RefreshIntervalMiliseconds?: number;
        BoxColorClass?: number;
        LastOrders?: number;
        Sound?: number;

    }

    export class PendingOrders extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<PendingOrdersSettingParams>> {
        protected getContentSettingDialogType() { return PendingOrdersSettingDialog; }
        getViewPermissionKey() { return "DashboardWidgetSystem:PendingOrdersView"; }
        getModifyPermissionKey() { return "DashboardWidgetSystem:PendingOrdersModify"; }

        constructor(opt: Jdw.BaseDashboardWidgetOptions<PendingOrdersSettingParams>) {
            super(opt);
        }

        protected buildWidgetData(widgetContentParams: PendingOrdersSettingParams): PromiseLike<any> {
            super.buildWidgetData(widgetContentParams); // optional 

            this.refreshIntervalMiliseconds = Q.toId(widgetContentParams.RefreshIntervalMiliseconds || 60000);

            let boxcolor = "bg-red";
            if (widgetContentParams.BoxColorClass == 2) boxcolor = "bg-green";
            if (widgetContentParams.BoxColorClass == 3) boxcolor = "bg-yellow";
            if (widgetContentParams.BoxColorClass == 4) boxcolor = "bg-red";

            if (widgetContentParams.LastOrders == undefined)
                widgetContentParams.LastOrders = 0;
            
            

            return Point.Sales.SaleOrdersService.GetPendingOrders({
                ContainsText:"Today"
            }, response => {

                    this.byId("total").text(response);
                    this.byId("box").attr("class", "small-box " + boxcolor);

                    if (response > widgetContentParams.LastOrders) {
                        widgetContentParams.LastOrders = response;

                        var filename = "http://localhost:55556/Sounds/Beep-pressing-buttons.mp3";

                        if (widgetContentParams.Sound == 2)
                            filename = "http://localhost:55556/Sounds/Clock-alarm-electronic-beep.mp3";

                        if (widgetContentParams.Sound == 3)
                            filename = "http://localhost:55556/Sounds/Connection-established-sound-effect.mp3";

                        if (widgetContentParams.Sound == 4)
                            filename = "http://localhost:55556/Sounds/Error-sound.mp3";

                        if (widgetContentParams.Sound == 5)
                            filename = "http://localhost:55556/Sounds/Glistening-sound-effect.mp3";

                        if (widgetContentParams.Sound == 6)
                            filename = "http://localhost:55556/Sounds/sms-tone.mp3";

                        if (widgetContentParams.Sound == 7)
                            filename = "http://localhost:55556/Sounds/Ticket-machine-sound.mp3";


                        if (widgetContentParams.Sound == 8)
                            filename = "http://localhost:55556/Sounds/Usb-connection-sound-effect.mp3";

                        if (widgetContentParams.Sound == 9)
                            filename = "http://localhost:55556/Sounds/Warning-beeping-sound.mp3";

                        var audio = new Audio(filename);
                        audio.play();
                     


                    }
                    

            });


        }

        protected canShowMaximizeToolbarButton() {
            return true;
        }

        protected canShowSettingButton() {
            return true;
        }

        protected getWidgetTitle() {
            return "Pending Orders";
        }

        
    }
}