
namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class FinancialPeriodsDialog extends Serenity.EntityDialog<FinancialPeriodsRow, any> {
        protected getFormKey() { return FinancialPeriodsForm.formKey; }
        protected getIdProperty() { return FinancialPeriodsRow.idProperty; }
        protected getLocalTextPrefix() { return FinancialPeriodsRow.localTextPrefix; }
        protected getNameProperty() { return FinancialPeriodsRow.nameProperty; }
        protected getService() { return FinancialPeriodsService.baseUrl; }
        protected getDeletePermission() { return FinancialPeriodsRow.deletePermission; }
        protected getInsertPermission() { return FinancialPeriodsRow.insertPermission; }
        protected getUpdatePermission() { return FinancialPeriodsRow.updatePermission; }

        protected form = new FinancialPeriodsForm(this.idPrefix);

    }
}