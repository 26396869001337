namespace Point.HRM {
    export interface ComplaintTypesForm {
        ComplaintType: Serenity.StringEditor;
    }

    export class ComplaintTypesForm extends Serenity.PrefixedContext {
        static formKey = 'HRM.ComplaintTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ComplaintTypesForm.init)  {
                ComplaintTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;

                Q.initFormType(ComplaintTypesForm, [
                    'ComplaintType', w0
                ]);
            }
        }
    }
}
