namespace Point.Accounts {
    export interface CurrencyTypesForm {
        CurrencyTypeName: Serenity.StringEditor;
    }

    export class CurrencyTypesForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.CurrencyTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!CurrencyTypesForm.init)  {
                CurrencyTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;

                Q.initFormType(CurrencyTypesForm, [
                    'CurrencyTypeName', w0
                ]);
            }
        }
    }
}
