
namespace Point.TTS {

    @Serenity.Decorators.registerClass()
    export class TaskTypesGrid extends Serenity.EntityGrid<TaskTypesRow, any> {
        protected getColumnsKey() { return 'TTS.TaskTypes'; }
        protected getDialogType() { return TaskTypesDialog; }
        protected getIdProperty() { return TaskTypesRow.idProperty; }
        protected getInsertPermission() { return TaskTypesRow.insertPermission; }
        protected getLocalTextPrefix() { return TaskTypesRow.localTextPrefix; }
        protected getService() { return TaskTypesService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }

    export class AutoColumnWidthTaskTypes extends Point.TTS.TaskTypesGrid{

        protected createSlickGrid() {
            this.slickGrid = super.createSlickGrid();

            new Serenity.AutoColumnWidthMixin({
                grid: this
            });

            return this.slickGrid;
        }

        /** We change number of rows for demonstration purposes, its not normally needed */
        //protected getViewOptions() {
        //    var opt = super.getViewOptions();
        //    opt.rowsPerPage = 20;
        //    return opt;
        //}
    }
}