
namespace Point.TTS {

    @Serenity.Decorators.registerClass()
    export class TaskTypesDialog extends Serenity.EntityDialog<TaskTypesRow, any> {
        protected getFormKey() { return TaskTypesForm.formKey; }
        protected getIdProperty() { return TaskTypesRow.idProperty; }
        protected getLocalTextPrefix() { return TaskTypesRow.localTextPrefix; }
        protected getNameProperty() { return TaskTypesRow.nameProperty; }
        protected getService() { return TaskTypesService.baseUrl; }
        protected getDeletePermission() { return TaskTypesRow.deletePermission; }
        protected getInsertPermission() { return TaskTypesRow.insertPermission; }
        protected getUpdatePermission() { return TaskTypesRow.updatePermission; }

        protected form = new TaskTypesForm(this.idPrefix);

    }
}