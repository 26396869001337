namespace Point.Products {
    export interface ProductSalePricesRow {
        ProductSalePriceID?: number;
        ProductID?: number;
        CustomerGroupId?: number;
        CustomerGroupName?: string;
        CustomerId?: number;
        CustomerName?: string;
        StartDate?: string;
        EndDate?: string;
        SalePrice?: number;
    }

    export namespace ProductSalePricesRow {
        export const idProperty = 'ProductSalePriceID';
        export const localTextPrefix = 'Products.ProductSalePrices';
        export const deletePermission = 'Products:ProductSalePrices';
        export const insertPermission = 'Products:ProductSalePrices';
        export const readPermission = 'Products:ProductSalePrices';
        export const updatePermission = 'Products:ProductSalePrices';

        export declare const enum Fields {
            ProductSalePriceID = "ProductSalePriceID",
            ProductID = "ProductID",
            CustomerGroupId = "CustomerGroupId",
            CustomerGroupName = "CustomerGroupName",
            CustomerId = "CustomerId",
            CustomerName = "CustomerName",
            StartDate = "StartDate",
            EndDate = "EndDate",
            SalePrice = "SalePrice"
        }
    }
}
