namespace Point {
    export enum eTransactionTypes {
        BP = 1,
        BR = 2,
        CP = 3,
        CR = 4,
        JV = 5,
        SALARY = 6,
        TASK = 7,
        SERV = 8,
        SERSALE = 9,
        SERPAY = 10,
        SERCOST = 11
    }
    Serenity.Decorators.registerEnumType(eTransactionTypes, 'Point.eTransactionTypes');
}
