/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.HRM {

    @Serenity.Decorators.registerClass()
   // @Serenity.Decorators.panel()
    export class EmployeeQualificationDialog extends Common.GridEditorDialog<EmployeeQualificationsRow> {
        protected getFormKey() { return EmployeeQualificationsForm.formKey; }
        protected getLocalTextPrefix() { return EmployeeQualificationsRow.localTextPrefix; }

        protected form: EmployeeQualificationsForm;

        constructor() {
            super();

            this.form = new EmployeeQualificationsForm(this.idPrefix);

            this.form.QualificationId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var QualificationId = Q.toId(this.form.QualificationId.value);

                var Qualification = Point.HRM.QualificationsRow.getLookup().itemById[QualificationId];


                this.form.QualificationTitle.value = Qualification.QualificationTitle;




            });

         
            


        }


    
    }
}