namespace Point.Sales {
    export interface MastersForm {
        MasterDate: Serenity.DateEditor;
        DetailList: MasterDetailsEditor;
    }

    export class MastersForm extends Serenity.PrefixedContext {
        static formKey = 'Sales.Masters';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!MastersForm.init)  {
                MastersForm.init = true;

                var s = Serenity;
                var w0 = s.DateEditor;
                var w1 = MasterDetailsEditor;

                Q.initFormType(MastersForm, [
                    'MasterDate', w0,
                    'DetailList', w1
                ]);
            }
        }
    }
}
