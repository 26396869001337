namespace Point.Services {
    export interface ServiceSuppliersForm {
        SupplierID: Serenity.LookupEditor;
        SupplierName: Serenity.StringEditor;
        RefNo: Serenity.StringEditor;
        Description: Serenity.StringEditor;
        CurrencyID: Serenity.IntegerEditor;
        CurrencyName: Serenity.StringEditor;
        Cost: Serenity.DecimalEditor;
        ExchangeRate: Serenity.DecimalEditor;
    }

    export class ServiceSuppliersForm extends Serenity.PrefixedContext {
        static formKey = 'Services.ServiceSupplier';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ServiceSuppliersForm.init)  {
                ServiceSuppliersForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.IntegerEditor;
                var w3 = s.DecimalEditor;

                Q.initFormType(ServiceSuppliersForm, [
                    'SupplierID', w0,
                    'SupplierName', w1,
                    'RefNo', w1,
                    'Description', w1,
                    'CurrencyID', w2,
                    'CurrencyName', w1,
                    'Cost', w3,
                    'ExchangeRate', w3
                ]);
            }
        }
    }
}
