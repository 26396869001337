namespace Point.Web.Modules.RealEstate {
    export enum PropertyBeds {
        Studio = 0,
        OneBed = 1,
        TwoBeds = 2,
        ThreeBeds = 3,
        FourBeds = 4,
        FiveBeds = 5,
        SixBeds = 6,
        SevenBeds = 7,
        EightBeds = 8,
        NineBeds = 9,
        TenBeds = 10,
        TenPlusBeds = 11
    }
    Serenity.Decorators.registerEnumType(PropertyBeds, 'Point.Web.Modules.RealEstate.PropertyBeds', 'Point.RealEstae.PropertyBeds');
}
