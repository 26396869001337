namespace Point.Accounts {
    export interface OpeningBalanceRow {
        TransactionId?: number;
        TransactionNo?: string;
        TransactionType?: string;
        TransactionDate?: string;
        AccountId?: number;
        Particulars?: string;
        Status?: number;
        Debit?: number;
        Credit?: number;
        ExchangeRate?: number;
        IsOpening?: boolean;
        CoaAccountCode?: number;
        CoaAccountTitle?: string;
        CoaAccountDisplay?: string;
        CoaParentId?: number;
        CoaHeadLevel?: number;
        CoaHeadType?: string;
        CoaAllowTransactions?: boolean;
        CoaIsGl?: boolean;
        CoaIsBudget?: boolean;
        CoaIsDepreciation?: boolean;
        CoaDepreciationRate?: number;
        CoaIsActive?: number;
        CoaEmployeeId?: number;
        CoaBankId?: number;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace OpeningBalanceRow {
        export const idProperty = 'TransactionId';
        export const nameProperty = 'TransactionNo';
        export const localTextPrefix = 'Accounts.OpeningBalance';
        export const deletePermission = 'Accounts:OpeningBalance';
        export const insertPermission = 'Accounts:OpeningBalance';
        export const readPermission = 'Accounts:OpeningBalance';
        export const updatePermission = 'Accounts:OpeningBalance';

        export declare const enum Fields {
            TransactionId = "TransactionId",
            TransactionNo = "TransactionNo",
            TransactionType = "TransactionType",
            TransactionDate = "TransactionDate",
            AccountId = "AccountId",
            Particulars = "Particulars",
            Status = "Status",
            Debit = "Debit",
            Credit = "Credit",
            ExchangeRate = "ExchangeRate",
            IsOpening = "IsOpening",
            CoaAccountCode = "CoaAccountCode",
            CoaAccountTitle = "CoaAccountTitle",
            CoaAccountDisplay = "CoaAccountDisplay",
            CoaParentId = "CoaParentId",
            CoaHeadLevel = "CoaHeadLevel",
            CoaHeadType = "CoaHeadType",
            CoaAllowTransactions = "CoaAllowTransactions",
            CoaIsGl = "CoaIsGl",
            CoaIsBudget = "CoaIsBudget",
            CoaIsDepreciation = "CoaIsDepreciation",
            CoaDepreciationRate = "CoaDepreciationRate",
            CoaIsActive = "CoaIsActive",
            CoaEmployeeId = "CoaEmployeeId",
            CoaBankId = "CoaBankId",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
