
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class ComplaintTypesGrid extends Serenity.EntityGrid<ComplaintTypesRow, any> {
        protected getColumnsKey() { return 'HRM.ComplaintTypes'; }
        protected getDialogType() { return ComplaintTypesDialog; }
        protected getIdProperty() { return ComplaintTypesRow.idProperty; }
        protected getInsertPermission() { return ComplaintTypesRow.insertPermission; }
        protected getLocalTextPrefix() { return ComplaintTypesRow.localTextPrefix; }
        protected getService() { return ComplaintTypesService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}