namespace Point.RealEstate {
    export interface ProjectPlansRow {
        PlanID?: number;
        ProjectID?: number;
        PlanName?: string;
        Beds?: number;
        Area?: number;
        AreaId?: number;
        Membership?: number;
        Processing?: number;
        Booking?: number;
        Confirmation?: number;
        Monthly?: number;
        Quarterly?: number;
        HalfYearly?: number;
        Yearly?: number;
        LumsumPrice?: number;
        TotalAmount?: number;
        OnPossion?: number;
        Active?: boolean;
    }

    export namespace ProjectPlansRow {
        export const idProperty = 'PlanID';
        export const nameProperty = 'PlanName';
        export const localTextPrefix = 'RealEstate.ProjectPlans';
        export const lookupKey = 'RealEstate.ProjectPlans';

        export function getLookup(): Q.Lookup<ProjectPlansRow> {
            return Q.getLookup<ProjectPlansRow>('RealEstate.ProjectPlans');
        }
        export const deletePermission = 'RealEstate:ProjectPlans';
        export const insertPermission = 'RealEstate:ProjectPlans';
        export const readPermission = 'RealEstate:ProjectPlans';
        export const updatePermission = 'RealEstate:ProjectPlans';

        export declare const enum Fields {
            PlanID = "PlanID",
            ProjectID = "ProjectID",
            PlanName = "PlanName",
            Beds = "Beds",
            Area = "Area",
            AreaId = "AreaId",
            Membership = "Membership",
            Processing = "Processing",
            Booking = "Booking",
            Confirmation = "Confirmation",
            Monthly = "Monthly",
            Quarterly = "Quarterly",
            HalfYearly = "HalfYearly",
            Yearly = "Yearly",
            LumsumPrice = "LumsumPrice",
            TotalAmount = "TotalAmount",
            OnPossion = "OnPossion",
            Active = "Active"
        }
    }
}
