namespace Point.HRM {
    export interface SalarySlipsRow {
        SalarySlipId?: number;
        VoucherNumber?: string;
        EmployeeId?: number;
        SalaryMonth?: string;
        StartDate?: string;
        EndDate?: string;
        PaidDays?: number;
        UnPaidDays?: number;
        BasicSalary?: number;
        HouseRent?: number;
        Utilities?: number;
        Medical?: number;
        Transportation?: number;
        MedicalReimbursement?: number;
        ExpensesReimbursement?: number;
        OtherEarning1Title?: string;
        OtherEarning1Amount?: number;
        OtherEarning2Title?: string;
        OtherEarning2Amount?: number;
        TaxDeduction?: number;
        LoanDeduction?: number;
        LeaveDeduction?: number;
        Fine?: number;
        ProvidentFund?: number;
        AdvanceSalary?: number;
        EOBI?: number;
        OtherDeduction1Title?: string;
        OtherDeduction1Amount?: number;
        OtherDeduction2Title?: string;
        OtherDeduction2Amount?: number;
        NetSalary?: number;
        AccruedDate?: string;
        PaymentDate?: string;
        ExpenseAccount?: number;
        PaymentAccount?: number;
        CustomEntry?: boolean;
        ExchangeRate?: number;
        Status?: number;
        Verified?: boolean;
        VoucherLock?: boolean;
        PerDaySalary?: number;
        Notes?: string;
        EmployeeTitleId?: number;
        EmployeeFirstName?: string;
        EmployeeLastName?: string;
        EmployeeBirthDate?: string;
        EmployeeHireDate?: string;
        EmployeeAddress?: string;
        EmployeeCountryId?: number;
        EmployeeStateId?: number;
        EmployeeCityId?: number;
        EmployeePostalCode?: string;
        EmployeeHomePhone?: string;
        EmployeeMobilePhone?: string;
        EmployeeEmail?: string;
        EmployeePicture?: string;
        EmployeeNotes?: string;
        EmployeeReportsTo?: number;
        EmployeePayroll?: boolean;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace SalarySlipsRow {
        export const idProperty = 'SalarySlipId';
        export const nameProperty = 'VoucherNumber';
        export const localTextPrefix = 'HRM.SalarySlips';
        export const deletePermission = 'HRM:Salary Slips';
        export const insertPermission = 'HRM:Salary Slips';
        export const readPermission = 'HRM:Salary Slips';
        export const updatePermission = 'HRM:Salary Slips';

        export declare const enum Fields {
            SalarySlipId = "SalarySlipId",
            VoucherNumber = "VoucherNumber",
            EmployeeId = "EmployeeId",
            SalaryMonth = "SalaryMonth",
            StartDate = "StartDate",
            EndDate = "EndDate",
            PaidDays = "PaidDays",
            UnPaidDays = "UnPaidDays",
            BasicSalary = "BasicSalary",
            HouseRent = "HouseRent",
            Utilities = "Utilities",
            Medical = "Medical",
            Transportation = "Transportation",
            MedicalReimbursement = "MedicalReimbursement",
            ExpensesReimbursement = "ExpensesReimbursement",
            OtherEarning1Title = "OtherEarning1Title",
            OtherEarning1Amount = "OtherEarning1Amount",
            OtherEarning2Title = "OtherEarning2Title",
            OtherEarning2Amount = "OtherEarning2Amount",
            TaxDeduction = "TaxDeduction",
            LoanDeduction = "LoanDeduction",
            LeaveDeduction = "LeaveDeduction",
            Fine = "Fine",
            ProvidentFund = "ProvidentFund",
            AdvanceSalary = "AdvanceSalary",
            EOBI = "EOBI",
            OtherDeduction1Title = "OtherDeduction1Title",
            OtherDeduction1Amount = "OtherDeduction1Amount",
            OtherDeduction2Title = "OtherDeduction2Title",
            OtherDeduction2Amount = "OtherDeduction2Amount",
            NetSalary = "NetSalary",
            AccruedDate = "AccruedDate",
            PaymentDate = "PaymentDate",
            ExpenseAccount = "ExpenseAccount",
            PaymentAccount = "PaymentAccount",
            CustomEntry = "CustomEntry",
            ExchangeRate = "ExchangeRate",
            Status = "Status",
            Verified = "Verified",
            VoucherLock = "VoucherLock",
            PerDaySalary = "PerDaySalary",
            Notes = "Notes",
            EmployeeTitleId = "EmployeeTitleId",
            EmployeeFirstName = "EmployeeFirstName",
            EmployeeLastName = "EmployeeLastName",
            EmployeeBirthDate = "EmployeeBirthDate",
            EmployeeHireDate = "EmployeeHireDate",
            EmployeeAddress = "EmployeeAddress",
            EmployeeCountryId = "EmployeeCountryId",
            EmployeeStateId = "EmployeeStateId",
            EmployeeCityId = "EmployeeCityId",
            EmployeePostalCode = "EmployeePostalCode",
            EmployeeHomePhone = "EmployeeHomePhone",
            EmployeeMobilePhone = "EmployeeMobilePhone",
            EmployeeEmail = "EmployeeEmail",
            EmployeePicture = "EmployeePicture",
            EmployeeNotes = "EmployeeNotes",
            EmployeeReportsTo = "EmployeeReportsTo",
            EmployeePayroll = "EmployeePayroll",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
