
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class ComplaintTypesDialog extends Serenity.EntityDialog<ComplaintTypesRow, any> {
        protected getFormKey() { return ComplaintTypesForm.formKey; }
        protected getIdProperty() { return ComplaintTypesRow.idProperty; }
        protected getLocalTextPrefix() { return ComplaintTypesRow.localTextPrefix; }
        protected getNameProperty() { return ComplaintTypesRow.nameProperty; }
        protected getService() { return ComplaintTypesService.baseUrl; }
        protected getDeletePermission() { return ComplaintTypesRow.deletePermission; }
        protected getInsertPermission() { return ComplaintTypesRow.insertPermission; }
        protected getUpdatePermission() { return ComplaintTypesRow.updatePermission; }

        protected form = new ComplaintTypesForm(this.idPrefix);

    }
}