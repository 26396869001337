namespace Point.Accounts {
    export interface CategoriesRow {
        CategoryId?: number;
        CategoryName?: string;
        Active?: boolean;
    }

    export namespace CategoriesRow {
        export const idProperty = 'CategoryId';
        export const nameProperty = 'CategoryName';
        export const localTextPrefix = 'Accounts.Categories';
        export const lookupKey = 'Accounts.Categories';

        export function getLookup(): Q.Lookup<CategoriesRow> {
            return Q.getLookup<CategoriesRow>('Accounts.Categories');
        }
        export const deletePermission = 'Accounts:Categoriess';
        export const insertPermission = 'Accounts:Categoriess';
        export const readPermission = 'Accounts:Categoriess';
        export const updatePermission = 'Accounts:Categoriess';

        export declare const enum Fields {
            CategoryId = "CategoryId",
            CategoryName = "CategoryName",
            Active = "Active"
        }
    }
}
