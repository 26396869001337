namespace Point.CMS {
    export interface PagesForm {
        PageName: Serenity.StringEditor;
        ParentId: Serenity.IntegerEditor;
        Keywords: Serenity.StringEditor;
        Description: Serenity.StringEditor;
        PageOrder: Serenity.IntegerEditor;
        Active: Serenity.BooleanEditor;
    }

    export class PagesForm extends Serenity.PrefixedContext {
        static formKey = 'CMS.Pages';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!PagesForm.init)  {
                PagesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.IntegerEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(PagesForm, [
                    'PageName', w0,
                    'ParentId', w1,
                    'Keywords', w0,
                    'Description', w0,
                    'PageOrder', w1,
                    'Active', w2
                ]);
            }
        }
    }
}
