namespace Point.Default {
    export interface SaleGraphSettingForm {
        Title: Serenity.StringEditor;
        TitleIconClass: Serenity.StringEditor;
        RefreshIntervalMiliseconds: Serenity.EnumEditor;
        ChartType: Serenity.EnumEditor;
    }

    export class SaleGraphSettingForm extends Serenity.PrefixedContext {
        static formKey = 'Default.SaleGraphSettingForm';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SaleGraphSettingForm.init)  {
                SaleGraphSettingForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;

                Q.initFormType(SaleGraphSettingForm, [
                    'Title', w0,
                    'TitleIconClass', w0,
                    'RefreshIntervalMiliseconds', w1,
                    'ChartType', w1
                ]);
            }
        }
    }
}
