namespace Point.HRM {
    export interface EmployeeExperiencesRow {
        EmployeeExperienceID?: number;
        EmployeeID?: number;
        Notes?: string;
        CompanyName?: string;
        StartDate?: string;
        EndDate?: string;
        DesignationId?: number;
        DesignationName?: string;
        ContactDetails?: string;
        ResignReason?: string;
    }

    export namespace EmployeeExperiencesRow {
        export const idProperty = 'EmployeeExperienceID';
        export const localTextPrefix = 'HRM.EmployeeExperiences';
        export const deletePermission = 'HRM:EmployeeExperiences';
        export const insertPermission = 'HRM:EmployeeExperiences';
        export const readPermission = 'HRM:EmployeeExperiences';
        export const updatePermission = 'HRM:EmployeeExperiences';

        export declare const enum Fields {
            EmployeeExperienceID = "EmployeeExperienceID",
            EmployeeID = "EmployeeID",
            Notes = "Notes",
            CompanyName = "CompanyName",
            StartDate = "StartDate",
            EndDate = "EndDate",
            DesignationId = "DesignationId",
            DesignationName = "DesignationName",
            ContactDetails = "ContactDetails",
            ResignReason = "ResignReason"
        }
    }
}
