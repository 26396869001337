
namespace Point.TTS {

    @Serenity.Decorators.registerClass()
    @Serenity.Decorators.panel()
    export class TasksDialog extends Serenity.EntityDialog<TasksRow, any> {
        protected getFormKey() { return TasksForm.formKey; }
        protected getIdProperty() { return TasksRow.idProperty; }
        protected getLocalTextPrefix() { return TasksRow.localTextPrefix; }
        protected getNameProperty() { return TasksRow.nameProperty; }
        protected getService() { return TasksService.baseUrl; }
        protected getDeletePermission() { return TasksRow.deletePermission; }
        protected getInsertPermission() { return TasksRow.insertPermission; }
        protected getUpdatePermission() { return TasksRow.updatePermission; }

        protected form = new TasksForm(this.idPrefix);

    }
}