namespace Point.RealEstate {
    export interface TypesForm {
        TypeName: Serenity.StringEditor;
        Active: Serenity.BooleanEditor;
    }

    export class TypesForm extends Serenity.PrefixedContext {
        static formKey = 'RealEstate.Types';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!TypesForm.init)  {
                TypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.BooleanEditor;

                Q.initFormType(TypesForm, [
                    'TypeName', w0,
                    'Active', w1
                ]);
            }
        }
    }
}
