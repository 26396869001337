namespace Point.Default {
    export enum RefreshIntervalOptions {
        Ten_Seconds = 10000,
        Thirty_Seconds = 30000,
        One_Minute = 60000,
        Five_Minutes = 300000,
        Ten_Minutes = 600000,
        Fifteen_Minutes = 900000,
        Thirty_Minutes = 1800000,
        One_Hour = 3600000
    }
    Serenity.Decorators.registerEnumType(RefreshIntervalOptions, 'Point.Default.RefreshIntervalOptions');
}
