namespace Point.BusinessPartners {
    export interface SuppliersForm {
        SupplierCode: Serenity.StringEditor;
        TitleId: Serenity.LookupEditor;
        SupplierName: Serenity.StringEditor;
        ContactName: Serenity.StringEditor;
        SupplierGroupId: Serenity.LookupEditor;
        MobileNo: Serenity.StringEditor;
        BranchId: Serenity.LookupEditor;
        Active: Serenity.BooleanEditor;
        Logo: Serenity.ImageUploadEditor;
        AllowLogin: Serenity.BooleanEditor;
        Address: Serenity.StringEditor;
        CountryId: Serenity.LookupEditor;
        StateId: Serenity.LookupEditor;
        CityId: Serenity.LookupEditor;
        RegionId: Serenity.IntegerEditor;
        TerritoryId: Serenity.IntegerEditor;
        PostalCode: Serenity.StringEditor;
        PhoneNo: Serenity.StringEditor;
        Email: Serenity.StringEditor;
        CurrencyId: Serenity.LookupEditor;
        ShowInReport: Serenity.BooleanEditor;
        Account: Serenity.StringEditor;
        AllowTransactions: Serenity.BooleanEditor;
        CreditLimit: Serenity.IntegerEditor;
        InsertName: Serenity.StringEditor;
        InsertDate: Serenity.DateEditor;
        UpdateName: Serenity.StringEditor;
        UpdateDate: Serenity.DateEditor;
    }

    export class SuppliersForm extends Serenity.PrefixedContext {
        static formKey = 'BusinessPartners.Suppliers';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!SuppliersForm.init)  {
                SuppliersForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.LookupEditor;
                var w2 = s.BooleanEditor;
                var w3 = s.ImageUploadEditor;
                var w4 = s.IntegerEditor;
                var w5 = s.DateEditor;

                Q.initFormType(SuppliersForm, [
                    'SupplierCode', w0,
                    'TitleId', w1,
                    'SupplierName', w0,
                    'ContactName', w0,
                    'SupplierGroupId', w1,
                    'MobileNo', w0,
                    'BranchId', w1,
                    'Active', w2,
                    'Logo', w3,
                    'AllowLogin', w2,
                    'Address', w0,
                    'CountryId', w1,
                    'StateId', w1,
                    'CityId', w1,
                    'RegionId', w4,
                    'TerritoryId', w4,
                    'PostalCode', w0,
                    'PhoneNo', w0,
                    'Email', w0,
                    'CurrencyId', w1,
                    'ShowInReport', w2,
                    'Account', w0,
                    'AllowTransactions', w2,
                    'CreditLimit', w4,
                    'InsertName', w0,
                    'InsertDate', w5,
                    'UpdateName', w0,
                    'UpdateDate', w5
                ]);
            }
        }
    }
}
