namespace Point.Products {
    export interface ProductCostPricesForm {
        SupplierGroupId: Serenity.LookupEditor;
        SupplierGroupName: Serenity.StringEditor;
        SupplierId: Serenity.LookupEditor;
        SupplierName: Serenity.StringEditor;
        StartDate: Serenity.DateEditor;
        EndDate: Serenity.DateEditor;
        CostPrice: Serenity.DecimalEditor;
    }

    export class ProductCostPricesForm extends Serenity.PrefixedContext {
        static formKey = 'Products.ProductCostPrice';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!ProductCostPricesForm.init)  {
                ProductCostPricesForm.init = true;

                var s = Serenity;
                var w0 = s.LookupEditor;
                var w1 = s.StringEditor;
                var w2 = s.DateEditor;
                var w3 = s.DecimalEditor;

                Q.initFormType(ProductCostPricesForm, [
                    'SupplierGroupId', w0,
                    'SupplierGroupName', w1,
                    'SupplierId', w0,
                    'SupplierName', w1,
                    'StartDate', w2,
                    'EndDate', w2,
                    'CostPrice', w3
                ]);
            }
        }
    }
}
