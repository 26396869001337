namespace Point.Products {
    export interface WarehousesRow {
        WarehouseId?: number;
        WarehouseCode?: string;
        WarehouseName?: string;
        Email?: string;
        Address?: string;
        CountryId?: number;
        StateId?: number;
        CityId?: number;
        RegionId?: number;
        TerritoryId?: number;
        PostalCode?: string;
        PhoneNo?: string;
        MobileNo?: string;
        CreatedBy?: number;
        CreatedOn?: string;
        ModifiedBy?: number;
        ModifiedOn?: string;
        Status?: number;
    }

    export namespace WarehousesRow {
        export const idProperty = 'WarehouseId';
        export const nameProperty = 'WarehouseCode';
        export const localTextPrefix = 'Products.Warehouses';
        export const lookupKey = 'Products.Warehouses';

        export function getLookup(): Q.Lookup<WarehousesRow> {
            return Q.getLookup<WarehousesRow>('Products.Warehouses');
        }
        export const deletePermission = 'Products:Warehouses';
        export const insertPermission = 'Products:Warehouses';
        export const readPermission = 'Products:Warehouses';
        export const updatePermission = 'Products:Warehouses';

        export declare const enum Fields {
            WarehouseId = "WarehouseId",
            WarehouseCode = "WarehouseCode",
            WarehouseName = "WarehouseName",
            Email = "Email",
            Address = "Address",
            CountryId = "CountryId",
            StateId = "StateId",
            CityId = "CityId",
            RegionId = "RegionId",
            TerritoryId = "TerritoryId",
            PostalCode = "PostalCode",
            PhoneNo = "PhoneNo",
            MobileNo = "MobileNo",
            CreatedBy = "CreatedBy",
            CreatedOn = "CreatedOn",
            ModifiedBy = "ModifiedBy",
            ModifiedOn = "ModifiedOn",
            Status = "Status"
        }
    }
}
