namespace Point.Meeting {
    export interface MeetingDecisionRelevantRow {
        DecisionRelevantId?: number;
        DecisionId?: number;
        ContactId?: number;
        DecisionMeetingId?: number;
        DecisionAgendaId?: number;
        DecisionDescription?: string;
        DecisionDecisionNumber?: number;
        DecisionResponsibleContactId?: number;
        DecisionDueDate?: string;
        DecisionResolutionStatus?: number;
        DecisionImages?: string;
        DecisionAttachments?: string;
        ContactFirstName?: string;
        ContactLastName?: string;
        ContactEmail?: string;
    }

    export namespace MeetingDecisionRelevantRow {
        export const idProperty = 'DecisionRelevantId';
        export const localTextPrefix = 'Meeting.MeetingDecisionRelevant';
        export const deletePermission = 'Meeting:General';
        export const insertPermission = 'Meeting:General';
        export const readPermission = 'Meeting:General';
        export const updatePermission = 'Meeting:General';

        export declare const enum Fields {
            DecisionRelevantId = "DecisionRelevantId",
            DecisionId = "DecisionId",
            ContactId = "ContactId",
            DecisionMeetingId = "DecisionMeetingId",
            DecisionAgendaId = "DecisionAgendaId",
            DecisionDescription = "DecisionDescription",
            DecisionDecisionNumber = "DecisionDecisionNumber",
            DecisionResponsibleContactId = "DecisionResponsibleContactId",
            DecisionDueDate = "DecisionDueDate",
            DecisionResolutionStatus = "DecisionResolutionStatus",
            DecisionImages = "DecisionImages",
            DecisionAttachments = "DecisionAttachments",
            ContactFirstName = "ContactFirstName",
            ContactLastName = "ContactLastName",
            ContactEmail = "ContactEmail"
        }
    }
}
