/// <reference path="../../Common/Helpers/GridEditorBase.ts" />

namespace Point.Accounts {

    @Serenity.Decorators.registerClass()
    export class VoucherDetailsEditor extends Common.GridEditorBase<VoucherDetailsRow> {
        protected getColumnsKey() { return "Accounts.VoucherDetails"; }
        protected getDialogType() { return VoucherDetailDialog; }
        protected getLocalTextPrefix() { return VoucherDetailsRow.localTextPrefix; }

        constructor(container: JQuery) {
            super(container);
        }



        protected createSlickGrid() {
            var grid = super.createSlickGrid();

            // need to register this plugin for grouping or you'll have errors
            grid.registerPlugin(new Slick.Data.GroupItemMetadataProvider());

            this.view.setSummaryOptions({
                aggregators: [
                    new Slick.Aggregators.Sum('Debit'),
                    new Slick.Aggregators.Sum('Credit')
                ]
            });

            return grid;
        }

        protected getColumns() {
            var columns = super.getColumns();

            Q.first(columns, x => x.field === 'Debit')
                .groupTotalsFormatter = (totals, col) =>
                    (totals.sum ? (' ' + Q.coalesce(totals.sum[col.field], '')) : '');

            Q.first(columns, x => x.field === 'Credit')
                .groupTotalsFormatter = (totals, col) =>
                    (totals.sum ? (' ' + Q.coalesce(totals.sum[col.field], '')) : '');
                    //(totals.sum ? (' ' + Q.coalesce(Q.formatNumber(totals.sum[col.field], '0.'), '')) : '');

            return columns;
        }

        protected getSlickOptions() {
            var opt = super.getSlickOptions();
            opt.showFooterRow = true;
            return opt;
        }

        protected usePager() {
            return false;
        }






















        validateEntity(row, id) {
            row.AccountID = Q.toId(row.AccountID);

            //var sameProduct = Q.tryFirst(this.view.getItems(), x => x.AccountID === row.AccountID);
            //if (sameProduct && this.id(sameProduct) !== id) {
            //    Q.alert('This product is already in Voucher Details!');
            //    return false;
            //}

            row.AccountTitle = AccountsRow.getLookup().itemById[row.AccountID].AccountTitle;
           // row.LineTotal = (row.Quantity || 0) * (row.Debit || 0) - (row.Discount || 0);
            return true;
        }


    }
}