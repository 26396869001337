namespace Point.Organization {
    export interface ContactRow {
        ContactId?: number;
        TitleId?: number;
        FirstName?: string;
        LastName?: string;
        Email?: string;
        CompanyName?: string;
        Address?: string;
        CountryId?: number;
        StateId?: number;
        CityId?: number;
        PostalCode?: string;
        PhoneNo?: string;
        Active?: boolean;
        TitleTitleName?: string;
        TitleActive?: boolean;
        CountryName?: string;
        CountryActive?: boolean;
        StateName?: string;
        StateCountryId?: number;
        StateActive?: boolean;
        CityName?: string;
        CityCountryId?: number;
        CityStateId?: number;
        CityActive?: number;
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace ContactRow {
        export const idProperty = 'ContactId';
        export const nameProperty = 'FirstName';
        export const localTextPrefix = 'Organization.Contact';
        export const lookupKey = 'Organization.Contact';

        export function getLookup(): Q.Lookup<ContactRow> {
            return Q.getLookup<ContactRow>('Organization.Contact');
        }
        export const deletePermission = 'Business Partners:Contacts';
        export const insertPermission = 'Business Partners:Contacts';
        export const readPermission = 'Business Partners:Contacts';
        export const updatePermission = 'Business Partners:Contacts';

        export declare const enum Fields {
            ContactId = "ContactId",
            TitleId = "TitleId",
            FirstName = "FirstName",
            LastName = "LastName",
            Email = "Email",
            CompanyName = "CompanyName",
            Address = "Address",
            CountryId = "CountryId",
            StateId = "StateId",
            CityId = "CityId",
            PostalCode = "PostalCode",
            PhoneNo = "PhoneNo",
            Active = "Active",
            TitleTitleName = "TitleTitleName",
            TitleActive = "TitleActive",
            CountryName = "CountryName",
            CountryActive = "CountryActive",
            StateName = "StateName",
            StateCountryId = "StateCountryId",
            StateActive = "StateActive",
            CityName = "CityName",
            CityCountryId = "CityCountryId",
            CityStateId = "CityStateId",
            CityActive = "CityActive",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
