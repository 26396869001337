
namespace Point.Default {
    import Jdw = J.DashboardWidget;

    @J.Decorators.registerGroupName()
    export class MyFirstWidget extends Jdw.BaseDashboardWidget<Jdw.BaseDashboardWidgetOptions<any>> {

        refreshCounter: number = 0;

        constructor(opt: Jdw.BaseDashboardWidgetOptions<any>) {
            super(opt);
        }

        initWidget() {
            super.initWidget();

            // your button will be available after initWidget is called
            this.byId("clickMeLink").click(e => {
                Q.notifyWarning("I am clicked");
            });

            this.byId("clickMeLink").hover(function () {
                $(this).css("background", "#ffff22");
            }, function () {
                    $(this).css("background", "white");
            });
        }

        protected buildWidgetData(wcp: any): PromiseLike<any> {
            super.buildWidgetData(wcp);

            return Promise.resolve()
                .then(() => {
                    this.byId("greeting").html(`<ul><li>Hello world! ${Math.random()}</li><li>I can refresh</li>${this.refreshCounter == 0 ? `` : `<li>Nbr of refreshing: ${this.refreshCounter} times</li>`}</ul>`)
                    this.refreshCounter++;
                });
        }

        getTemplate() {
          //  return `<div id="~_greeting"></div><a id="~_clickMeLink" style="cursor: pointer; bottom: 0px; position: absolute; padding: 4px;"><i class="fa fa-hand-o-right text-blue"> Click me</a>`;

            return `<h3> hellow Sir </h3>`;
       
        }

        getWidgetTitle() {
            return "My first simple widget";
        }

        canShowMaximizeToolbarButton() {
            return false;
        }

    }   
    
}