namespace Point.Accounts {
    export interface AccountTypesForm {
        AccountTypeName: Serenity.StringEditor;
        AccountNatureId: Serenity.EnumEditor;
        Active: Serenity.BooleanEditor;
    }

    export class AccountTypesForm extends Serenity.PrefixedContext {
        static formKey = 'Accounts.AccountTypes';
        private static init: boolean;

        constructor(prefix: string) {
            super(prefix);

            if (!AccountTypesForm.init)  {
                AccountTypesForm.init = true;

                var s = Serenity;
                var w0 = s.StringEditor;
                var w1 = s.EnumEditor;
                var w2 = s.BooleanEditor;

                Q.initFormType(AccountTypesForm, [
                    'AccountTypeName', w0,
                    'AccountNatureId', w1,
                    'Active', w2
                ]);
            }
        }
    }
}
