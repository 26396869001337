
namespace Point.Sales {

    @Serenity.Decorators.registerClass()
    export class MastersGrid extends Serenity.EntityGrid<MastersRow, any> {
        protected getColumnsKey() { return 'Sales.Masters'; }
        protected getDialogType() { return MastersDialog; }
        protected getIdProperty() { return MastersRow.idProperty; }
        protected getInsertPermission() { return MastersRow.insertPermission; }
        protected getLocalTextPrefix() { return MastersRow.localTextPrefix; }
        protected getService() { return MastersService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }
    }
}