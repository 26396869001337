namespace Point.Sales {
    export interface SaleOrdersRow {
        SaleOrderId?: number;
        OrderNo?: string;
        CustomerId?: number;
        OrderDate?: string;
        Discount?: number;
        Tax?: number;
        SubTotal?: number;
        Total?: number;
        Paid?: number;
        Balance?: number;
        Notes?: string;
        PaymentAccount?: number;
        Status?: number;
        Attachment?: string;
        DueDate?: string;
        Verified?: boolean;
        VerifiedBy?: number;
        VerifiedOn?: string;
        Locked?: boolean;
        CurrencyID?: number;
        ExchangeRate?: number;
        ContactName?: string;
        CurrencyName?: string;
        RefNo?: string;
        CustomerCustomerCode?: string;
        CustomerName?: string;
        CustomerCustomerGroupId?: number;
        CustomerTitleId?: number;
        CustomerContactName?: string;
        CustomerBranchId?: number;
        CustomerAddress?: string;
        CustomerCountryId?: number;
        CustomerStateId?: number;
        CustomerCityId?: number;
        CustomerCurrencyId?: number;
        CustomerRegionId?: number;
        CustomerTerritoryId?: number;
        CustomerCreditLimit?: number;
        CustomerPostalCode?: string;
        CustomerCountry?: string;
        CustomerPhone?: string;
        CustomerLogo?: string;
        CustomerActive?: boolean;
        DetailList?: SaleOrderDetailsRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace SaleOrdersRow {
        export const idProperty = 'SaleOrderId';
        export const nameProperty = 'Notes';
        export const localTextPrefix = 'Sales.SaleOrders';
        export const deletePermission = 'Sales:Sale Orders';
        export const insertPermission = 'Sales:Sale Orders';
        export const readPermission = 'Sales:Sale Orders';
        export const updatePermission = 'Sales:Sale Orders';

        export declare const enum Fields {
            SaleOrderId = "SaleOrderId",
            OrderNo = "OrderNo",
            CustomerId = "CustomerId",
            OrderDate = "OrderDate",
            Discount = "Discount",
            Tax = "Tax",
            SubTotal = "SubTotal",
            Total = "Total",
            Paid = "Paid",
            Balance = "Balance",
            Notes = "Notes",
            PaymentAccount = "PaymentAccount",
            Status = "Status",
            Attachment = "Attachment",
            DueDate = "DueDate",
            Verified = "Verified",
            VerifiedBy = "VerifiedBy",
            VerifiedOn = "VerifiedOn",
            Locked = "Locked",
            CurrencyID = "CurrencyID",
            ExchangeRate = "ExchangeRate",
            ContactName = "ContactName",
            CurrencyName = "CurrencyName",
            RefNo = "RefNo",
            CustomerCustomerCode = "CustomerCustomerCode",
            CustomerName = "CustomerName",
            CustomerCustomerGroupId = "CustomerCustomerGroupId",
            CustomerTitleId = "CustomerTitleId",
            CustomerContactName = "CustomerContactName",
            CustomerBranchId = "CustomerBranchId",
            CustomerAddress = "CustomerAddress",
            CustomerCountryId = "CustomerCountryId",
            CustomerStateId = "CustomerStateId",
            CustomerCityId = "CustomerCityId",
            CustomerCurrencyId = "CustomerCurrencyId",
            CustomerRegionId = "CustomerRegionId",
            CustomerTerritoryId = "CustomerTerritoryId",
            CustomerCreditLimit = "CustomerCreditLimit",
            CustomerPostalCode = "CustomerPostalCode",
            CustomerCountry = "CustomerCountry",
            CustomerPhone = "CustomerPhone",
            CustomerLogo = "CustomerLogo",
            CustomerActive = "CustomerActive",
            DetailList = "DetailList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
