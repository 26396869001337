/// <reference path="../../Common/Helpers/GridEditorDialog.ts" />

namespace Point.Products {

    @Serenity.Decorators.registerClass()
    export class ProductCostPriceDialog extends Common.GridEditorDialog<ProductCostPricesRow> {
        protected getFormKey() { return ProductCostPricesForm.formKey; }
        protected getLocalTextPrefix() { return ProductCostPricesRow.localTextPrefix; }

        protected form: ProductCostPricesForm;

        constructor() {
            super();

            this.form = new ProductCostPricesForm(this.idPrefix);

            this.form.CostPrice.addValidationRule(this.uniqueName, e => {

                if (this.form.CostPrice.value == 0) {

                    return "Cost Price must not be zero.";
                }

            });

            this.form.SupplierGroupId.changeSelect2(e => {

          



                var SupplierGroupId = Q.toId(this.form.SupplierGroupId.value);

                var SupplierGroup = Point.BusinessPartners.SupplierGroupsRow.getLookup().itemById[SupplierGroupId];


                this.form.SupplierGroupName.value = SupplierGroup.SupplierGroupName




            });


            this.form.SupplierId.changeSelect2(e => {

                // this.form.CurrencyID.clearItems();



                var SupplierId = Q.toId(this.form.SupplierId.value);

                var Supplier = Point.BusinessPartners.SuppliersRow.getLookup().itemById[SupplierId];


                this.form.SupplierName.value = Supplier.SupplierName




            });


        }
    }
}