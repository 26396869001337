
namespace Point.Default {
    import Jdw = J.DashboardWidget;

    @Serenity.Decorators.registerClass()
    export class PendingOrderListSettingDialog extends Serenity.PropertyDialog<any, any> {
        protected getFormKey() { return PendingOrderListSettingForm.formKey; }

        private form: PendingOrderListSettingForm;
        public saveContentSettings: (gsiMetadata: Jdw.GridStackItemMetadata, widgetContentParams: any) => void;
        private _gsiMetadata: Jdw.GridStackItemMetadata;

        constructor(gsiMetadata: Jdw.GridStackItemMetadata, initWidgetParamData?: any, opt?: any) {
            super(opt);

            this.form = new PendingOrderListSettingForm(this.idPrefix); 

            this._gsiMetadata = gsiMetadata;           
            this.propertyGrid.load(initWidgetParamData || {});



            this.form.RefreshIntervalMiliseconds.change(e => {

              


            });
        }

            

        protected getDialogButtons() {
            return [
                {
                    text: Q.text("Dialogs.OkButton"),
                    click: () => {
                        if (!this.validateForm())
                            return;

                        this.saveContentSettings(
                            this._gsiMetadata,                            
                            this.getSaveEntity()
                        );

                        this.dialogClose();
                    },
                },
                {
                    text: Q.text("Dialogs.CancelButton"),
                    click: () => this.dialogClose()
                }
            ]
        }

        getPropertyGridOptions() {
            let opt = super.getPropertyGridOptions();
            opt.useCategories = true;
            return opt;
        }

        getDialogTitle() {
            return "Settings";
        }
        
    }
}