namespace Point.Purchases {
    export namespace PurchaseReturnDetailService {
        export const baseUrl = '~/Purchases/PurchaseReturns/PurchaseReturnDetail';

        export declare function Retrieve(request: Serenity.RetrieveRequest, onSuccess?: (response: Serenity.RetrieveResponse<PurchaseReturnDetailsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;
        export declare function List(request: Serenity.ListRequest, onSuccess?: (response: Serenity.ListResponse<PurchaseReturnDetailsRow>) => void, opt?: Q.ServiceOptions<any>): JQueryXHR;

        export declare const enum Methods {
            Retrieve = "~/Purchases/PurchaseReturns/PurchaseReturnDetail/Retrieve",
            List = "~/Purchases/PurchaseReturns/PurchaseReturnDetail/List"
        }

        [
            'Retrieve', 
            'List'
        ].forEach(x => {
            (<any>PurchaseReturnDetailService)[x] = function (r, s, o) {
                return Q.serviceRequest(baseUrl + '/' + x, r, s, o);
            };
        });
    }
}
