
namespace Point.HRM {

    @Serenity.Decorators.registerClass()
    export class SalarySlipsGrid extends Serenity.EntityGrid<SalarySlipsRow, any> {
        protected getColumnsKey() { return 'HRM.SalarySlips'; }
        protected getDialogType() { return SalarySlipsDialog; }
        protected getIdProperty() { return SalarySlipsRow.idProperty; }
        protected getInsertPermission() { return SalarySlipsRow.insertPermission; }
        protected getLocalTextPrefix() { return SalarySlipsRow.localTextPrefix; }
        protected getService() { return SalarySlipsService.baseUrl; }

        constructor(container: JQuery) {
            super(container);
        }


        protected getColumns() {
            var columns = super.getColumns();


            columns.splice(0, 0, {
                field: 'View Voucher',
                name: '',
                format: ctx => '<a class="inline-action preview-invoice" title="preview">' +
                    '<i class="fa fa-eye text-red"></i></a>',
                width: 24,
                minWidth: 24,
                maxWidth: 24,
                headerFilterType: Serenity.HeaderFilterType.disabled
            });



            //columns.splice(1, 0, {
            //    field: 'Print Voucher',
            //    name: '',
            //    format: ctx => '<a class="inline-action print-invoice" title="invoice">' +
            //        '<i class="fa fa-file-pdf-o text-red"></i></a>',
            //    width: 24,
            //    minWidth: 24,
            //    maxWidth: 24,
            //    headerFilterType: Serenity.HeaderFilterType.disabled
            //});


            return columns;
        }


        protected onClick(e: JQueryEventObject, row: number, cell: number) {
            super.onClick(e, row, cell);

            if (e.isDefaultPrevented())
                return;

            var item = this.itemAt(row);
            var target = $(e.target);

            // if user clicks "i" element, e.g. icon
            if (target.parent().hasClass('inline-action'))
                target = target.parent();

            if (target.hasClass('inline-action')) {
                e.preventDefault();

                if (target.hasClass('print-invoice')) {
                    Point.Common.ReportHelper.execute({
                        reportKey: 'HRM.SalarySlip',
                        //reportKey: 'Reports.TestReportIndex',
                        download: false,
                        extension: 'pdf',
                        params: {
                            SalarySlipId: item.SalarySlipId
                        }
                    });
                }

                if (target.hasClass('preview-invoice')) {
                    Point.Common.ReportHelper.execute({
                        reportKey: 'HRM.SalarySlip',
                        //reportKey: 'Reports.TestReportIndex',
                        download: false,
                        extension: 'html',
                        params: {
                            SalarySlipId: item.SalarySlipId
                        }
                    });
                }
            }
        }
    }
}