namespace Point.Purchases {
    export interface PurchaseInvoicesRow {
        PurchaseInvoiceId?: number;
        InvoiceNo?: string;
        SupplierId?: number;
        InvoiceDate?: string;
        Discount?: number;
        Tax?: number;
        SubTotal?: number;
        Total?: number;
        Paid?: number;
        Balance?: number;
        Notes?: string;
        PaymentAccount?: number;
        Status?: number;
        Attachment?: string;
        DueDate?: string;
        Verified?: boolean;
        VerifiedBy?: number;
        VerifiedOn?: string;
        Locked?: boolean;
        CurrencyID?: number;
        ExchangeRate?: number;
        ContactName?: string;
        CurrencyName?: string;
        RefNo?: string;
        SupplierSupplierCode?: string;
        SupplierName?: string;
        SupplierSupplierGroupId?: number;
        SupplierTitleId?: number;
        SupplierContactName?: string;
        SupplierBranchId?: number;
        SupplierAddress?: string;
        SupplierCountryId?: number;
        SupplierStateId?: number;
        SupplierCityId?: number;
        SupplierCurrencyId?: number;
        SupplierRegionId?: number;
        SupplierTerritoryId?: number;
        SupplierCreditLimit?: number;
        SupplierPostalCode?: string;
        SupplierCountry?: string;
        SupplierPhone?: string;
        SupplierLogo?: string;
        SupplierCreatedBy?: number;
        SupplierCreatedOn?: string;
        SupplierModifiedBy?: number;
        SupplierModifiedOn?: string;
        SupplierActive?: boolean;
        DetailList?: PurchaseInvoiceDetailsRow[];
        InsertUserId?: number;
        InsertDate?: string;
        UpdateUserId?: number;
        UpdateDate?: string;
        InsertName?: string;
        UpdateName?: string;
    }

    export namespace PurchaseInvoicesRow {
        export const idProperty = 'PurchaseInvoiceId';
        export const nameProperty = 'Notes';
        export const localTextPrefix = 'Purchases.PurchaseInvoices';
        export const deletePermission = 'Purchases:Purchase Invoices';
        export const insertPermission = 'Purchases:Purchase Invoices';
        export const readPermission = 'Purchases:Purchase Invoices';
        export const updatePermission = 'Purchases:Purchase Invoices';

        export declare const enum Fields {
            PurchaseInvoiceId = "PurchaseInvoiceId",
            InvoiceNo = "InvoiceNo",
            SupplierId = "SupplierId",
            InvoiceDate = "InvoiceDate",
            Discount = "Discount",
            Tax = "Tax",
            SubTotal = "SubTotal",
            Total = "Total",
            Paid = "Paid",
            Balance = "Balance",
            Notes = "Notes",
            PaymentAccount = "PaymentAccount",
            Status = "Status",
            Attachment = "Attachment",
            DueDate = "DueDate",
            Verified = "Verified",
            VerifiedBy = "VerifiedBy",
            VerifiedOn = "VerifiedOn",
            Locked = "Locked",
            CurrencyID = "CurrencyID",
            ExchangeRate = "ExchangeRate",
            ContactName = "ContactName",
            CurrencyName = "CurrencyName",
            RefNo = "RefNo",
            SupplierSupplierCode = "SupplierSupplierCode",
            SupplierName = "SupplierName",
            SupplierSupplierGroupId = "SupplierSupplierGroupId",
            SupplierTitleId = "SupplierTitleId",
            SupplierContactName = "SupplierContactName",
            SupplierBranchId = "SupplierBranchId",
            SupplierAddress = "SupplierAddress",
            SupplierCountryId = "SupplierCountryId",
            SupplierStateId = "SupplierStateId",
            SupplierCityId = "SupplierCityId",
            SupplierCurrencyId = "SupplierCurrencyId",
            SupplierRegionId = "SupplierRegionId",
            SupplierTerritoryId = "SupplierTerritoryId",
            SupplierCreditLimit = "SupplierCreditLimit",
            SupplierPostalCode = "SupplierPostalCode",
            SupplierCountry = "SupplierCountry",
            SupplierPhone = "SupplierPhone",
            SupplierLogo = "SupplierLogo",
            SupplierCreatedBy = "SupplierCreatedBy",
            SupplierCreatedOn = "SupplierCreatedOn",
            SupplierModifiedBy = "SupplierModifiedBy",
            SupplierModifiedOn = "SupplierModifiedOn",
            SupplierActive = "SupplierActive",
            DetailList = "DetailList",
            InsertUserId = "InsertUserId",
            InsertDate = "InsertDate",
            UpdateUserId = "UpdateUserId",
            UpdateDate = "UpdateDate",
            InsertName = "InsertName",
            UpdateName = "UpdateName"
        }
    }
}
